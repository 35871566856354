import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import { format } from "timeago.js";
import { TrashIcon } from "@primer/octicons-react";

const Container = styled.div`
  display: flex;
  gap: 10px;
  margin: 30px 0px;
`;
const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPrimary};
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const CommentContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;
const Name = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Date = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-left: 5px;
`;
const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colorOnBackground};
`;

const Button = styled.button`
  display: flex;
  gap: 20px;
  gap: 5px;
  cursor: pointer;
  border-radius: 8px;
  height: max-content;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colorSurface};
  border: none;
  &:hover {
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;

const Comment = ({ comment }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [user, setUser] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/find/${comment.userId}`
      );
      setUser(res.data);
    };
    fetchUser();
  }, [comment]);

  const handleDeleteComment = async () => {
    if (comment.userId === currentUser._id) {
      await axios.delete(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/comments/${comment._id}`,
        {
          withCredentials: true,
        }
      );
    } else {
      console.log("No comment delete nono");
    }
  };
  return (
    <Container>
      <Avatar src={user && user.img} />
      <CommentContent>
        <Details>
          <Name>
            {user?.name}
            <Date>{format(comment.createdAt)}</Date>
          </Name>
          <Text>{comment.desc}</Text>
        </Details>{" "}
        {currentUser?._id === comment.userId && (
          <Button onClick={handleDeleteComment}>
            <TrashIcon />
          </Button>
        )}
      </CommentContent>
    </Container>
  );
};
export default Comment;
