import React from "react";
import { styled } from "styled-components";
import FeedbackForm from "../components/FeedbackForm";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`;

const Feedback = () => {
  return (
    <Container>
      <FeedbackForm />
    </Container>
  );
};

export default Feedback;
