import { React, useEffect, useState } from "react";
import { styled } from "styled-components";
import RecipeCard from "../components/Recipecard";
import Loader from "../components/Loader";
import axios from "axios";

const Container = styled.div`
  display: flex;
  padding: 0px 20px 40px 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex: 1 auto;
  align-self: stretch;
`;

const RecipeGrid = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const LoadMoreSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: space-evenly;
  padding: 40px 0px 40px 0px;
`;

const LoadMoreButton = styled.button`
  font-size: 18px;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  background-color: ${({ theme }) => theme.colorPrimary};
  color: ${({ theme }) => theme.colorOnBackground};

  border-radius: 8px;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colorPrimary};
  }
`;

const Home = ({ type }) => {
  const [videos, setVideos] = useState([]);
  const [index, setIndex] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchVideos = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/${type}?offset=0&limit=12`,
        { withCredentials: true }
      );
      setVideos(res.data);
      setIsLoading(false);
    };
    fetchVideos();
  }, [type]);

  const fetchMoreData = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/${type}?offset=${index}0&limit=12`
      )
      .then((res) => {
        setVideos((prevVideos) => [...prevVideos, ...res.data]);

        res.data.length > 0 ? setHasMore(true) : setHasMore(false);
      })
      .catch((err) => console.log(err));
    setIsLoading(false);
    setIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <Container>
      <RecipeGrid>
        {videos.map((video, index) => (
          <RecipeCard key={video._id + index} video={video} />
        ))}
      </RecipeGrid>
      <LoadMoreSection>
        {isLoading && <Loader />}
        {hasMore && !isLoading ? (
          <LoadMoreButton onClick={fetchMoreData}>Load More</LoadMoreButton>
        ) : (
          <p>You've reached the end. Wow!</p>
        )}
      </LoadMoreSection>
    </Container>
  );
};
export default Home;
