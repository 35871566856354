import { XIcon } from "@primer/octicons-react";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { closeModal } from "../../redux/modalSlice";
const Container = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
const Wrapper = styled.div`
  //Layout
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  //Appearance
  min-width: 600px;
  max-width: 80vw;
  min-height: 400px;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 12px;
`;
const BackDrop = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0;
  margin: 0px;
  padding: 0px;
  z-index: 0;
  position: fixed;
  //Appearance
  background-color: #000000a7;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const ModalHead = styled.div`
  //Layout
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
  cursor: default;
`;
const ModalBody = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 600px;
  overflow: auto;
`;
const CloseButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  order: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const FormContainer = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 284px;
  min-width 240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  //Flex
  order: 1;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Form = styled.form`
  //Layout
  align.items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  //Flex
  order: 1;
  gap: 12px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-radius: 8px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  font-size: 17px;
  font-weight: 400;
`;
const Button = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: green;
  padding: 8px 12px;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid green;
  &:hover {
    color: green;
    background-color: lightgreen;
  }
`;
const ButtonDisabled = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: darkseagreen;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: not-allowed;
  border: 1px solid green;
  &:hover {
    background-color: lightgreen;
  }
`;
const BodyContainer = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};
  border: 2px solid background-color: ${({ theme }) => theme.colorBackground};
  border-radius: 12px;
  cursor: default;
`;
const BodyWrapper = styled.div`
  //Layout
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 24px;
  padding-bottom: 48px;
  gap: 18px;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
`;
const H3 = styled.h3`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const CreateCollectionModal = () => {
  const [collectionTitle, setCollectionTitle] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeCreateCollectionModal = (collectionId) => {
    dispatch(closeModal());
  };
  const handleCreateCollection = async (e) => {
    e.preventDefault();
    console.log("handle Create Collection");
    try {
      const collectionRes = await axios.post(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/collections`,
        { title: collectionTitle },
        {
          withCredentials: true,
        }
      );
      navigate(`/collection/${collectionRes.data._id}`);
      dispatch(closeModal());
    } catch (err) {}
  };
  return (
    <Container>
      <BackDrop onClick={closeCreateCollectionModal} />
      <Wrapper>
        <ModalHead>
          <CloseButton onClick={closeCreateCollectionModal}>
            <XIcon size={20} />
          </CloseButton>
          <H3>Create collection</H3>
        </ModalHead>
        <ModalBody>
          <BodyContainer>
            <BodyWrapper>
              <FormContainer>
                <Form>
                  <Input
                    placeholder="Collection title"
                    onClick={(e) => setCollectionTitle(e.target.value)}
                  ></Input>
                  {collectionTitle ? (
                    <Button type="submit" onClick={handleCreateCollection}>
                      Create
                    </Button>
                  ) : (
                    <ButtonDisabled>Create</ButtonDisabled>
                  )}
                </Form>
              </FormContainer>
            </BodyWrapper>
          </BodyContainer>
        </ModalBody>
      </Wrapper>
    </Container>
  );
};
export default CreateCollectionModal;
