import React from "react";
import { styled } from "styled-components";
const Button = styled.button`
  //Layout
  margin-top: 8px;
  padding: 10px 12px;
  border-radius: 8px;
  //Typography
  font-size: 15px;
  font-weight: 600;
  //Appearance
  background-color: ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnSurface};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorOnSurface};
  &:hover {
    background-color: ${({ theme }) => theme.colorInverseSurface};
    color: ${({ theme }) => theme.colorInverseOnSurface};
  }
`;
const AuthButton = ({ text, click }) => {
  var buttonText = text;
  var clickAction = (e) => {
    e.preventDefault();
    click();
  };
  return <Button onClick={clickAction}>{buttonText}</Button>;
};
export default AuthButton;
