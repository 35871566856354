import React, { useState } from "react";
import { styled } from "styled-components";
import { createIssue } from "../utils/githubAPI";
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  gap: 20px;
  flex-wrap: wrap;
  padding: 0px 20px 40px 20px;
  width: 100%;
`;
const Title = styled.h2`
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  gap: 12px;
  width: 70%;
`;
const Textarea = styled.textarea`
  border: 2px solid ${({ theme }) => theme.colorOutline};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  resize: vertical;
  min-height: 100px;
  background-color: ${({ theme }) => theme.colorBackground};
  &:focus {
    border: 2px solid #6094e2;
    background: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;
const Button = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: green;
  padding: 8px 12px;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid green;
  &:hover {
    color: green;
    background-color: lightgreen;
  }
`;
const ButtonDisabled = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: darkseagreen;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: not-allowed;
  border: 1px solid green;
  &:hover {
    background-color: lightgreen;
  }
`;
const FeedbackForm = () => {
  const [description, setDescription] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const feedbackLabels = ["Feedback", "Feedback Form"];
    const feedback = description;
    //const feedbackComments = screenshot ? `${description} ![Screenshot](${screenshot})` : description;
    await createIssue(feedback, feedbackLabels);
    setDescription("");
    const githubToken = process.env.REACT_APP_GITHUB_TOKEN; // Get the token from environment variables
    console.log("githubToken:", githubToken);
  };
  return (
    <Container>
      <Title>Share your Feedback!</Title>
      <Form onSubmit={handleSubmit}>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          maxLength="500"
          placeholder="Please describe your feedback. max. 500 characters."
        />
        {/*<input
        type="file"
        accept="image/*"
        onChange={(e) => setScreenshot(URL.createObjectURL(e.target.files[0]))}
  />*/}
        <Buttons>
          {description ? (
            <Button type="submit">Submit</Button>
          ) : (
            <ButtonDisabled>Submit</ButtonDisabled>
          )}
        </Buttons>
      </Form>
    </Container>
  );
};
export default FeedbackForm;
