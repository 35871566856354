import React from "react";
import { styled } from "styled-components";
import useRandomInterval from "../../utils/hooks/useRandomInterval";
import SparkleInstance from "./SparkleInstance";
const ChildWrapper = styled.strong`
  position: relative;
  z-index: 1;
  font-weight: bold;
`;
const Wrapper = styled.span`
  position: relative;
  display: inline-block;
`;
const DEFAULT_COLOR = "#FFC700";
const Sparkles = ({ color = DEFAULT_COLOR, children, ...delegated }) => {
  const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
  const range = (start, end, step = 1) => {
    let output = [];
    if (typeof end === "undefined") {
      end = start;
      start = 0;
    }
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  };
  const generateSparkle = (color) => {
    const sparkle = {
      id: String(random(10000, 99999)),
      createdAt: Date.now(),
      color,
      size: random(10, 20),
      style: {
        top: random(0, 100) + "%",
        left: random(0, 100) + "%",
      },
    };
    return sparkle;
  };
  const [sparkles, setSparkles] = React.useState(() => {
    return range(3).map(() => generateSparkle(color));
  });
  useRandomInterval(() => {
    const sparkle = generateSparkle(color);
    const now = Date.now();
    const nextSparkles = sparkles.filter((sp) => {
      const delta = now - sp.createdAt;
      return delta < 750;
    });
    nextSparkles.push(sparkle);
    setSparkles(nextSparkles);
  });
  return (
    <Wrapper {...delegated}>
      <SparkleInstance
        key={1}
        color={DEFAULT_COLOR}
        size={12}
        style={{ top: "50%", left: "50%" }}
      />
      {sparkles.map((sparkle) => (
        <SparkleInstance
          key={sparkle.id}
          color={sparkle.color}
          size={sparkle.size}
          style={sparkle.style}
        />
      ))}
      <ChildWrapper>{children}</ChildWrapper>
    </Wrapper>
  );
};
export default Sparkles;
