import { CheckCircleFillIcon, CircleIcon } from "@primer/octicons-react";
import axios from "axios";
import { React, useEffect, useState } from "react";
import { styled } from "styled-components";
const Container = styled.div`
  width: 340px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colorSurface};
  &:hover {
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const Thumbnail = styled.div`
  position: relative;
  width: 82px;
  height: 48px;
`;
const ThumbnailContainer = styled.div`
  position: relative;
  width: 82px;
  height: 48px;
`;
const ThumbnailImage = styled.img`
  width: 82px;
  height: 48px;
  background-color: ${({ theme }) => theme.colorPrimary};
  border-radius: 4px;
  border: none;
  flex: 1;
  z-index: 0;
`;
const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 1;
`;
const Texts = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
  flex: 1 1;
`;
const Headline = styled.div`
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Subline = styled.p`
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin: 0px;
`;
const Title = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colorOnSurface};
  margin: 0px;
`;
const AddToCollectionListItem = ({ type, collection, recipe }) => {
  const [recipeInCollection, setRecipeInCollection] = useState(false);
  useEffect(() => {
    const assessRecipeInCollection = () => {
      setRecipeInCollection(collection.recipes.includes(recipe._id));
    };
    assessRecipeInCollection();
  }, [collection, recipe]);
  const handleClick = (e) => {
    recipeInCollection ? removeRecipe(recipe._id) : addRecipe(recipe._id);
  };
  const removeRecipe = async (recipe) => {
    try {
      await axios
        .delete(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/collections/recipe/${collection._id}`,
          { data: { recipe: recipe }, withCredentials: true }
        )
        .then(setRecipeInCollection(false));
    } catch (err) {}
  };
  const addRecipe = async (recipe) => {
    try {
      await axios
        .put(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/collections/recipe/${collection._id}`,
          { recipe },
          { withCredentials: true }
        )
        .then(setRecipeInCollection(true));
    } catch (err) {}
  };
  return (
    <Container onClick={handleClick}>
      <Thumbnail>
        <ThumbnailContainer>
          <ThumbnailImage src={collection.imgURL} />
        </ThumbnailContainer>
      </Thumbnail>
      <Details>
        <Texts>
          <Headline>
            <Title>{collection.title}</Title>
          </Headline>
          {recipeInCollection && (
            <Subline>Recipe already in this collection</Subline>
          )}
        </Texts>
        {recipeInCollection ? <CheckCircleFillIcon /> : <CircleIcon />}
      </Details>
    </Container>
  );
};
export default AddToCollectionListItem;
