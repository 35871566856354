// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  CustomProvider,
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
} from "firebase/app-check";
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";
import { deleteObject, getStorage, ref } from "firebase/storage";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
console.log("NODE_ENV:", process.env.NODE_ENV);
console.log(
  "Is development environment:",
  process.env.NODE_ENV === "development"
);
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider(app);
// Initialize Firebase App Check with a debug token in development
export const appCheck = initializeAppCheck(app, {
  provider:
    process.env.NODE_ENV === "development"
      ? new CustomProvider({
          getToken: () => {
            // Debugging: Output the App Check debug token
            console.log(
              "Using App Check Debug Token:",
              process.env.REACT_APP_APPCHECK_DEBUG_TOKEN
            );
            return Promise.resolve({
              token: process.env.REACT_APP_APPCHECK_DEBUG_TOKEN,
              expireTimeMillis: Date.now() + 60 * 60 * 1000, // Token expires in 1 hour
            });
          },
        })
      : new ReCaptchaEnterpriseProvider(
          "6LcVELQoAAAAAHAu1mMYhyAvoonJaa9iHK-cSEDO"
        ),
  isTokenAutoRefreshEnabled: true,
});
//Reauthenticate user
export const reauthenticateUser = async (prov, password) => {
  let authProvider;
  let currentPassword;
  let credential;
  const auth = getAuth();
  const user = auth.currentUser;
  if (
    prov === "google.com" ||
    prov === "microsoft.com" ||
    prov === "yahoo.com" ||
    prov === "twitter.com" ||
    prov === "facebook.com" ||
    prov === "github.com" ||
    prov === "apple.com"
  ) {
    switch (prov) {
      case "google.com":
        authProvider = new GoogleAuthProvider();
        break;
      case "facebook.com":
        authProvider = new FacebookAuthProvider();
        break;
      case "twitter.com":
        authProvider = new TwitterAuthProvider();
        break;
      // Add other providers as needed
      default:
        console.error("Unsupported provider: ", prov);
        return;
    }
    await reauthenticateWithPopup(user, authProvider)
      .then((result) => {
        // User is re-authenticated with fresh tokens minted and
        // should be able to perform sensitive operations like account
        // deletion and email or password update.
        // IdP data available in result.additionalUserInfo.profile.
        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        console.log("OAuth credential: ", credential);
        return credential;
      })
      .catch((error) => {
        // Handle error.
        console.error("Failed to reauthenticate with OAuth provider: ", error);
      });
  } else {
    console.log("Reauthenticating with email and password...");
    if (!password) {
      currentPassword = window.prompt("Please enter your current password:");
      credential = EmailAuthProvider.credential(user.email, currentPassword);
      console.log("Credential: ", credential);
    } else if (password) {
      currentPassword = password;
      credential = EmailAuthProvider.credential(user.email, currentPassword);
      console.log("Credential: ", credential);
    }
    await reauthenticateWithCredential(user, credential).then(() => {
      console.log("Reauthenticated successfully!");
    });
  }
};
//Check if email is already in use
export const checkEmailInUse = async (email) => {
  try {
    const signInMethods = await getAuth().fetchSignInMethodsForEmail(email);
    // User with email already exists
    return signInMethods.length > 0;
  } catch (error) {
    if (error.code === "auth/user-not-found") {
      console.log("User with email does not exist");
      // User with email does not exist
      return false;
    } else {
      console.error("Error checking user existence:", error);
      // Error occurred while checking user existence
      return false;
    }
  }
};
//Update Password
export const changePassword = async (currentPassword, newPassword) => {
  const auth = getAuth();
  const user = auth.currentUser;
  try {
    await reauthenticateUser(user.providerData[0].providerId, currentPassword);
    console.log("Reauthenticated successfully!");
    try {
      await updatePassword(user, newPassword);
      console.log("Password updated successfully!");
    } catch (error) {
      console.error("Failed to update password: ", error);
    }
  } catch (error) {
    console.error("Failed to reauthenticate: ", error);
  }
};
//Reset Password
export const resetPassword = async (email) => {
  const auth = getAuth();
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      // ..
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(
        "Failed to send password reset email: ",
        errorCode,
        errorMessage
      );
    });
};
//Delete Account
export const deleteAccount = async (password) => {
  const auth = getAuth();
  const user = auth.currentUser;
  try {
    await reauthenticateUser(user.providerData[0].provider, password);
    console.log("Reauthenticated successfully!");
    try {
      await user.delete();
      console.log("Account deleted successfully!");
      //Delete User Data on Mongo
      //Recipes
      //Collections
      //Comments
      //Likes
      //Follows
      //Notifications
      //Avatar
      //Views
      //Cover Photo
    } catch (error) {
      console.error("Failed to delete account: ", error);
    }
  } catch (error) {
    console.error("Failed to reauthenticate : ", error);
  }
};
//Delete File
export const deleteFile = async (file) => {
  const storage = getStorage();
  const storageRef = ref(storage, file);
  try {
    await deleteObject(storageRef);
    console.log("File deleted successfully!");
  } catch (error) {
    console.error("Failed to delete file: ", error);
  }
};
export default app;
