//External Imports
import { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import Loader from "../components/Loader";
import {
  fetchFailure,
  fetchStart,
  fetchSuccess,
  resetCurrentVideo,
} from "../redux/videoSlice";
//Internal Imports
import axios from "axios";
import Comments from "../components/Comments";
import RecipeChannelBar from "../components/RecipeChannelBar";
import RecipeDetails from "../components/RecipeDetails";
import RecipeInfos from "../components/RecipeInfos";
import RecipeInstructions from "../components/RecipeInstructions";
import RecipeRecommendations from "../components/RecipeRecommendations";
import SocialShare from "../components/SocialShare";
const Container = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 24px 0px 24px;
  flex-grow: inherit;
  align-self: stretch;
  overflow: hidden;
`;
const Content = styled.div`
  overflow: auto;
  flex: 3;
  min-height: 0px;
  padding-bottom: 24px;
`;
const VideoWrapper = styled.div`
  border-radius: 12px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Hr = styled.hr`
  margin: 15px 0px;
  border: 0.5px solid ${({ theme }) => theme.colorSurface};
`;

const Secondary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow: auto;
  width: 402px;
  //flex: 2;
  padding-bottom: 24px;
`;
const Recipe = () => {
  const currentVideo = useSelector((state) => state.video.currentVideo);
  const dispatch = useDispatch();
  const path = useLocation().pathname.split("/")[2];
  console.log("LOAD");
  const fetchVideo = useCallback(() => {
    console.log("fetching video");
    dispatch(fetchStart());
    axios
      .get(`${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/find/${path}`, {
        withCredentials: true,
      })
      .then((response) => {
        dispatch(fetchSuccess(response.data));
      })
      .catch((error) => {
        console.error("Error fetching video:", error);
        dispatch(fetchFailure());
      });
    return () => {
      dispatch(resetCurrentVideo());
    };
  }, [path, dispatch]);

  useEffect(() => {
    // This function runs when the component mounts
    fetchVideo();

    // This function runs when the component unmounts
    return () => {
      dispatch(resetCurrentVideo());
    };
  }, [dispatch, fetchVideo, path]);
  //SCHEMA A
  /*const recipeStructuredData = {
    "@context": "https://schema.org/",
    "@type": "Recipe",
    name: currentVideo?.title || "Loading...",
    image: [
      //Add more images for resolutions
      currentVideo?.imgURL || "Loading...",
    ],
    author: {
      "@type": "Person",
      name: `Chef ${channel.name}`,
    },
    datePublished: currentVideo?.createdAt || "Loading...",
    description: currentVideo?.desc || "Loading...",
    prepTime: `PT${currentVideo?.preptime || "Loading..."}M`,
    cookTime: `CT${currentVideo?.cooktime || "Loading..."}M`,
    totalTime: `TT${currentVideo?.totaltime || "Loading..."}M`,
    keywords: currentVideo?.tags || "Loading...",
    recipeYield: currentVideo?.yielding || "Loading...",
    cookingMethod: currentVideo?.method || "Loading...",
    recipeCategory: "Recipe Category", //Add to Recipe Mongoose Schema
    recipeCuisine: currentVideo?.cuisine || "Loading...",
    nutrition: {
      "@type": "NutritionInformation",
      calories: `${currentVideo?.calories || "Loading..."} calories`,
    },
    recipeIngredient: ingredients.map(
      (ingredient) =>
        `${ingredient.quantity} ${ingredient.unit} ${ingredient.title}`
    ),
    recipeInstructions: steps.map((step) => ({
      "@type": "HowToStep",
      name: step.title,
      text: step.desc,
      image: step.img,
      timestamp: step.time,
    })),
    aggregateRating: {
      // Add Aggregated Ratings to Mongoose Schema
      "@type": "AggregateRating",
      ratingValue: "5",
      ratingCount: "18",
    },
    difficulty: currentVideo?.difficulty || "Loading...",
    video: {
      //Map instructions for Video Snippets tbd
      "@type": "VideoObject",
      name: currentVideo?.title || "Loading...",
      description: currentVideo?.desc || "Loading...",
      thumbnailUrl: [
        // Add Thumbnail Resolutions src-set
        currentVideo?.imgURL || "Loading...",
      ],
      contentUrl: currentVideo?.videoURL || "Loading...",
      //embedUrl: "https://www.example.com/videoplayer?video=123",
      uploadDate: currentVideo?.createdAt || "Loading...",
      //duration: "PT1M33S",
      interactionStatistic: {
        "@type": "InteractionCounter",
        interactionType: { "@type": "WatchAction" },
        userInteractionCount: views,
      },
      expires: "2030-02-05T08:00:00+08:00", //How to handle ?
    },
  };*/
  return (
    <Container>
      {currentVideo ? (
        <>
          <Helmet>
            <title>{currentVideo?.title || "Loading..."}</title>
            <meta
              name="description"
              content={currentVideo?.desc || "Loading..."}
            />
            <meta
              name="keywords"
              content={currentVideo?.tags || "Loading..."}
            />
            <meta name="robots" content="" />
            <link rel="canonical" href="http://localhost:3000/recipe" />
            {/*<script type="application/ld+json">
              {JSON.stringify(recipeStructuredData)}
      </script>*/}
          </Helmet>
          <Content>
            <VideoWrapper>
              <iframe
                id="recipe-video"
                width="100%"
                height="520"
                src={currentVideo?.videoURL || "Loading..."}
                title="CookCook video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </VideoWrapper>
            {currentVideo && <RecipeInfos currentVideo={currentVideo} />}
            {currentVideo && <RecipeChannelBar currentVideo={currentVideo} />}
            {currentVideo && <RecipeDetails currentVideo={currentVideo} />}
            <Hr />
            {currentVideo && <SocialShare currentVideo={currentVideo} />}
            <Hr />
            {currentVideo && <Comments currentVideo={currentVideo} />}
          </Content>
        </>
      ) : (
        <>
          <Loader />
          <p>{currentVideo}</p>
        </>
      )}
      <Secondary>
        {currentVideo && <RecipeInstructions currentVideo={currentVideo} />}

        <RecipeRecommendations />
      </Secondary>
    </Container>
  );
};
export default Recipe;
