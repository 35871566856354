import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import SettingsMenu from "../components/settings/SettingsMenu";
import { openModal } from "../redux/modalSlice";
import { loginSuccess } from "../redux/userSlice";
import {
  getAuth,
  onAuthStateChanged,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { changePassword, reauthenticateUser } from "../utils/firebase.js";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 20px 40px 20px;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 20px 40px 20px;
  width: 100%;
`;
const Body = styled.div`
  display: flex;
  gap: 10px;
  padding: 0px 20px 40px 0px;
  flex: 1;
`;
const Form = styled.form`
  //Layout
  align.items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  //Flex
  order: 1;
  gap: 12px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Overline = styled.p`
  margin: 0px;
  padding: 0px;
  flex: 0;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const LabelInputGroup = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
`;
const Label = styled.label`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-left: 9px;
`;
const Input = styled.input`
  height: max-content;
  border: ${({ theme, error }) => (error ? "2px" : "1px")} solid
    ${({ theme, error }) => (error ? theme.colorError : theme.colorOutline)};
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};
  border-radius: 8px;
  max-width: 440px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const HelperText = styled.p`
  margin: 0px;
  margin-left: 9px;
  padding: 0px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme, state }) =>
    state === "error" ? theme.colorError : theme.colorOnBackground};
`;
const Button = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colorTertiaryContainer};
  padding: 8px 12px;
  color: ${({ theme }) => theme.colorTertiary};
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorTertiary};
  width: fit-content;
  &:hover {
    color: ${({ theme }) => theme.colorOnTertiary};
    background-color: ${({ theme }) => theme.colorTertiary};
  }
  &:disabled {
    color: black;
    background-color: grey;
    border: 1px solid grey;
    cursor: not-allowed;
  }
`;
const DeleteButton = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colorErrorContainer};
  padding: 8px 12px;
  color: ${({ theme }) => theme.colorError};
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorError};
  width: fit-content;
  &:hover {
    color: ${({ theme }) => theme.colorOnError};
    background-color: ${({ theme }) => theme.colorError};
  }
  &:disabled {
    background-color: grey;
    border: 1px solid grey;
    cursor: not-allowed;
  }
`;
const Settings = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [desc, setDesc] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] =
    useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const dispatch = useDispatch();
  const auth = getAuth();
  const user = auth.currentUser;
  useEffect(() => {
    if (currentUser) {
      setName(currentUser.name);
      setUsername(currentUser.nickname);
      setDesc(currentUser.desc);
      setEmail(currentUser.email);
    }
  }, [currentUser]);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user?.emailVerified) {
        console.log("User has verified their email address!");
        // Update the user in your database here
        console.log("currentUser.email: " + currentUser.email);
        console.log("user.email: " + user.email);
        if (currentUser.email !== user.email) {
          console.log("User has updated their email address!");
          const updateUser = async () => {
            const res = await axios.put(
              `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/${currentUser._id}`,
              {
                email: email,
                emailVerified: true,
              }
            );
            console.log(res);
            dispatch(loginSuccess(res.data));
          };
          updateUser();
        }
        // Reload the user's information
        await user.reload();
        // Now the user's email should be updated
        console.log("Updated email: ", user.email);
        setEmail(user.email); // Update the email state
      } else {
        console.log("User has not verified their email address!");
      }
    });
    // Cleanup function to unsubscribe from the listener when the component is unmounted
    return () => unsubscribe();
  }, [auth, currentUser._id, currentUser.email, dispatch, email]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "username":
        setUsername(value);
        break;
      case "desc":
        setDesc(value);
        break;
      case "current_password":
        setCurrentPassword(value);
        if (value === "") {
          setCurrentPasswordError(true);
          setCurrentPasswordErrorMessage("Please enter your current password.");
        }
        break;
      case "new_password":
        setNewPassword(value);
        if (value.length < 6) {
          setNewPasswordError(true);
          setNewPasswordErrorMessage(
            "Password must be at least 6 characters long."
          );
        } else {
          setNewPasswordError(false);
          setNewPasswordErrorMessage("");
        }
        break;
      case "email":
        setEmail(value);
        if (!/\S+@\S+\.\S+/.test(value)) {
          console.log("Invalid email");
          //set emailError to true and provide error message
          setEmailError(true);
          setEmailErrorMessage("Invalid email address format.");
        } else {
          e.target.error = false;
          setEmailError(false);
          setEmailErrorMessage("");
        }
        break;
      default:
        break;
    }
  };
  const handleSubmitAccount = (e) => {
    e.preventDefault();
    const updateUser = async () => {
      const res = await axios.put(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/${currentUser._id}`,
        {
          name: name,
          nickname: username,
          desc: desc,
        }
      );
      console.log(res);
      dispatch(loginSuccess(res.data));
    };
    updateUser();
  };
  const handleSubmitPassword = (e) => {
    changePassword(newPassword);
  };
  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    const newEmail = email; // replace this with the actual new email
    console.log(user.providerData[0].providerId);
    // Call the reauthenticateUser function here
    try {
      await reauthenticateUser(user.providerData[0].providerId);
      verifyBeforeUpdateEmail(auth.currentUser, newEmail);
      //sendEmailVerification(auth.currentUser);
      console.log(
        "Email updated and verification email sent to: " +
          newEmail +
          "! Old Email was: " +
          currentUser.email
      );
    } catch (error) {
      console.error("Failed to update email: ", error);
    }
  };
  const handleDeleteUser = async (e) => {
    e.preventDefault();
    dispatch(openModal("DeleteUser"));
  };
  return (
    <Container>
      <h1>Settings</h1>
      <Body>
        <SettingsMenu />
        <Content>
          <h2>Account</h2>
          <section>
            <Form>
              <Overline>Name</Overline>
              <Input
                name="name"
                placeholder="Name"
                onChange={handleInputChange}
                defaultValue={name}
              />
              <Overline>Username</Overline>
              <Input
                name="username"
                placeholder="Username"
                onChange={handleInputChange}
                defaultValue={username}
              />
              <Overline>Description</Overline>
              <Input
                name="desc"
                placeholder="Description"
                onChange={handleInputChange}
                defaultValue={desc}
              />
              <Button type="submit" onClick={handleSubmitAccount}>
                Save changes
              </Button>
            </Form>
          </section>
          <h2>Email</h2>
          <section>
            <LabelInputGroup>
              <Label>Email address</Label>
              <Input
                name="email"
                error={emailError}
                placeholder="New email"
                onChange={handleInputChange}
                defaultValue={email}
              ></Input>
              <HelperText state={emailError ? "error" : "default"}>
                {emailErrorMessage}
              </HelperText>
            </LabelInputGroup>
            <Button
              disabled={emailError}
              type="submit"
              onClick={handleUpdateEmail}
            >
              Change Email Address
            </Button>
          </section>
          <h2>Password</h2>
          <section>
            <Form>
              <LabelInputGroup>
                <Label>Current Password</Label>
                <Input
                  name="current_password"
                  type="password"
                  placeholder="Current password"
                  onChange={handleInputChange}
                ></Input>
                <HelperText state={currentPasswordError ? "error" : "default"}>
                  {currentPasswordErrorMessage}
                </HelperText>
              </LabelInputGroup>
              <LabelInputGroup>
                <Label>New Password</Label>
                <Input
                  name="new_password"
                  type="password"
                  placeholder="New password"
                  onChange={handleInputChange}
                ></Input>
                <HelperText state={newPasswordError ? "error" : "default"}>
                  {newPasswordErrorMessage}
                </HelperText>
              </LabelInputGroup>
              <Button
                disabled={
                  currentPassword === "" ||
                  newPasswordError ||
                  currentPasswordError
                }
                type="submit"
                onClick={handleSubmitPassword}
              >
                Change Password
              </Button>
            </Form>
          </section>
          <h2>Danger Zone</h2>
          <section>
            <DeleteButton type="submit" onClick={handleDeleteUser}>
              Delete Account
            </DeleteButton>
          </section>
        </Content>
      </Body>
    </Container>
  );
};
export default Settings;
