import React, { memo, useRef, useState } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { styled } from "styled-components";
const Container = styled.div`
  display: block flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  flex: 1;
`;
const StepImages = styled.div`
  display: flex;
  justify-content: start;
  alignt-items: center;
  gap: 10px;
  flex: 1;
  padding: 0px 12px;
  overflow: hidden;
  /* each slide snaps into place */
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    scroll-margin: 10px;
    scroll-snap-align: center;
    scroll-snap-stop: normal;
  }
`;
const StepImage = styled.img`
  width: 200px;
  height: 120px;
  background-color: #999;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 4px 4px silver;
  }
`;
const ArrowButton = styled.button`
  background-color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  position: absolute;
  height: 120px;
  opacity: 80%;
  color: rgba(0, 0, 0, 0.64);
  &:hover {
    color: rgba(0, 0, 0, 1);
    opacity: 100%;
  }
`;
const StepperButtons = styled.div`
  display: grid;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
`;
const LeftArrow = styled(ArrowButton)`
  justify-self: flex-start;
`;
const RightArrow = styled(ArrowButton)`
  justify-self: flex-end;
`;
const RecipeStepImages = memo(function ({ images }) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const scrollRef = useRef();
  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
    console.log(scrollRef.current.scrollLeft);
  };
  return (
    <Container>
      {images.length > 3 && (
        <StepperButtons>
          <LeftArrow onClick={() => scroll(-200)}>&lt;</LeftArrow>
          <RightArrow onClick={() => scroll(200)}>&gt;</RightArrow>
        </StepperButtons>
      )}
      <StepImages ref={scrollRef}>
        {images.map((image, index) => (
          <StepImage
            key={index}
            src={image}
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(index);
            }}
          />
        ))}
      </StepImages>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Container>
  );
});
export default RecipeStepImages;
