import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { cuisines } from "../utils/lists/cuisines.ts";
const DropdownButton = styled.button`
  display: block;
  cursor: pointer;
  height: 100%;
  padding: 4px 16px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const DropdownMenu = styled.div`
  position: absolute;
  top: 72px;
  width: 616px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colorBackground};
  border-radius: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);
  &:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  z-index: 1 !important;
`;
const DropdownMenuItem = styled.fieldset`
  border: none;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-weight: 600;
  padding: 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colorOnSurface};
  &:hover {
    color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const SearchDropdown = ({ setItems }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectionItems, setSelectionItems] = useState(
    cuisines.reduce((obj, cuisine) => ({ ...obj, [cuisine.name]: false }), {})
  );
  const numberOfItemsSelected =
    Object.values(selectionItems).filter(Boolean).length;
  const dropdownRef = useRef();

  useEffect(() => {
    var keys = Object.keys(selectionItems);
    var selectedItems = keys.filter(function (key) {
      return selectionItems[key];
    });
    setItems(selectedItems);
  }, [selectionItems, setItems]);

  useEffect(() => {
    const outsideClick = (e) => {
      if (e.target !== dropdownRef.current) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("click", outsideClick);
    return () => {
      document.removeEventListener("click", outsideClick);
    };
  });
  return (
    <>
      <DropdownButton
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen((prevState) => !prevState);
        }}
      >
        {numberOfItemsSelected > 0
          ? numberOfItemsSelected + " Cuisines"
          : "Any Cuisine"}
      </DropdownButton>
      {dropdownOpen && (
        <DropdownMenu
          ref={dropdownRef}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {cuisines?.map((cuisine) => (
            <DropdownMenuItem key={`${cuisine.label}`}>
              {selectionItems[cuisine.label]}
              <input
                id={`${cuisine.label}`}
                type="checkbox"
                onChange={(e) =>
                  setSelectionItems({
                    ...selectionItems,
                    [cuisine.label]: e.target.checked,
                  })
                }
                checked={selectionItems[cuisine.label]}
              />
              {cuisine.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </>
  );
};
export default SearchDropdown;
