import { XIcon } from "@primer/octicons-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { styled } from "styled-components";
import { openModal } from "../../redux/modalSlice";
import { loginStart, loginSuccess } from "../../redux/userSlice";
import AuthButton from "../../components/auth/AuthButton";
import { closeModal } from "../../redux/modalSlice";
import LogoPNG from "../../utils/img/logo.png";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import { auth, provider } from "../../utils/firebase";
import axios from "axios";
const Container = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
const Wrapper = styled.div`
  //Layout
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  //Appearance
  min-width: 600px;
  max-width: 80vw;
  min-height: 400px;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 12px;
`;
const BackDrop = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0;
  margin: 0px;
  padding: 0px;
  z-index: 0;
  position: fixed;
  //Appearance
  background-color: #000000a7;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const ModalHead = styled.div`
  //Layout
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
  cursor: default;
`;
const ModalBody = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 600px;
  overflow: auto;
`;

const CloseButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  order: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const Hr = styled.hr`
  margin: 15px 0;
  border: 0.5px solid ${({ theme }) => theme.colorSurfaceVariant};
  flex: 1;
`;
const Or = styled.p`
  margin: 0px;
  padding: 0px;
  flex: 0;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
`;
const Divider = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  flex-basis: auto;
  flex-shrink: 0;
  width: 100%;
  max-width: 284px;
  min-width 240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  //Flex
  order: 1;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const FormContainer = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 284px;
  min-width 240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  //Flex
  order: 1;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const ButtonContainer = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 284px;
  min-width 240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  //Flex
  order: 1;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const ConsentContainer = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 284px;
  min-width 240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  //Flex
  order: 1;
  //Typography
  font-size: 13px;
  line-height: 16px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const ConsentText = styled.p`
  //Layout
  margin: 0px;
  //Flex
  order: 1;
  //Typography
  font-size: 13px;
  line-height: 16px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const ConsentLink = styled.a`
  //Layout
  //Flex
  order: 1;
  //Typography
  font-size: 13px;
  line-height: 16px;
  //Appearance
  color: ${({ theme }) => theme.colorSecondary};
  cursor: pointer;
`;
const SignInContainer = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 284px;
  min-width 240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 24px;
  //Flex
  order: 1;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const SignInText = styled.p`
  //Layout
  margin: 0px;
  //Flex
  order: 1;
  //Typography
  font-size: 16px;
  line-height: 18px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const SignInLink = styled.a`
  //Layout
  //Flex
  order: 1;
  //Typography
  font-size: 16px;
  line-height: 18px;
  //Appearance
  color: ${({ theme }) => theme.colorSecondary};
  cursor: pointer;
`;
const Form = styled.form`
  //Layout
  align.items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  //Flex
  order: 1;
  gap: 12px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-radius: 8px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  font-size: 17px;
  font-weight: 400;
`;
const Button = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: green;
  padding: 8px 12px;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid green;
  &:hover {
    color: green;
    background-color: lightgreen;
  }
`;
const ButtonDisabled = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: darkseagreen;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: not-allowed;
  border: 1px solid green;
  &:hover {
    background-color: lightgreen;
  }
`;
const BodyContainer = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};
  border: 2px solid background-color: ${({ theme }) => theme.colorBackground};
  border-radius: 12px;
  cursor: default;
`;
const BodyWrapper = styled.div`
  //Layout
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 24px;
  padding-bottom: 48px;
  gap: 18px;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Img = styled.img``;

const H2 = styled.h2`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 31px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 36px;
  text-align: center;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  order: 2;
  width: 100%;
  justify-content: space-evenly;
  padding-right: 52px;
`;
const SignUpModal = () => {
  const [signUpName, setSignUpName] = useState("");
  const [signUpNickName, setSignUpNickName] = useState("");
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const dispatch = useDispatch();
  const verificationEmail = (user) => {
    sendEmailVerification(user).then(() => {
      console.log("Verification email sent!");
    });
  };
  const handleSignup = async (e) => {
    e.preventDefault();
    const email = signUpEmail;
    const password = signUpPassword;
    const name = signUpName;
    const nickname = signUpNickName;
    console.log("-> " + email + " " + password);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        dispatch(loginStart());
        console.log("signed up");
        const user = userCredential.user;
        verificationEmail(user);
        axios
          .post(
            `${process.env.REACT_APP_RECIPE_API_BASE_URL}/auth/signup`,
            {
              nickname,
              name,
              email,
              password,
            },
            { withCredentials: true }
          )
          .then((res) => {
            dispatch(loginSuccess(res.data));
            dispatch(closeModal());
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("error up");
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };
  const signUpWithGoogle = async () => {
    console.log("signUpWithGoogle");
    signInWithPopup(auth, provider)
      .then((result) => {
        axios
          .post(`${process.env.REACT_APP_RECIPE_API_BASE_URL}/auth/google`, {
            name: result.user.displayName,
            email: result.user.email,
            nickname: result.user.email,
            img: result.user.photoURL,
          })
          .then((res) => {
            dispatch(loginSuccess(res.data));
            dispatch(closeModal());
          });
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  const closeSignUpModal = (e) => {
    e.preventDefault();
    dispatch(closeModal());
  };
  const handleOpenSignInModal = (e) => {
    e.preventDefault();
    dispatch(openModal("SignIn"));
  };
  return (
    <Container>
      <BackDrop onClick={closeSignUpModal} />
      <Wrapper>
        <ModalHead>
          <CloseButton onClick={closeSignUpModal}>
            <XIcon size={20} />
          </CloseButton>
          <Logo>
            <Img src={LogoPNG} />
          </Logo>
        </ModalHead>
        <ModalBody>
          <BodyContainer>
            <BodyWrapper>
              <H2>Sign up today!</H2>
              <ButtonContainer>
                <AuthButton
                  click={signUpWithGoogle}
                  text="Sign up with Google"
                />
              </ButtonContainer>
              <Divider>
                <Hr />
                <Or>or</Or>
                <Hr />
              </Divider>
              <FormContainer>
                <Form>
                  <Input
                    placeholder="Name"
                    onClick={(e) => setSignUpName(e.target.value)}
                  ></Input>
                  <Input
                    placeholder="Username"
                    onClick={(e) => setSignUpNickName(e.target.value)}
                  ></Input>
                  <Input
                    placeholder="Email"
                    onClick={(e) => setSignUpEmail(e.target.value)}
                  ></Input>
                  <Input
                    type="password"
                    placeholder="Password"
                    onClick={(e) => setSignUpPassword(e.target.value)}
                  ></Input>
                  {signUpName &&
                  signUpNickName &&
                  signUpEmail &&
                  signUpPassword ? (
                    <Button type="submit" onClick={handleSignup}>
                      Register
                    </Button>
                  ) : (
                    <ButtonDisabled>Create Account</ButtonDisabled>
                  )}
                </Form>
              </FormContainer>
              <ConsentContainer>
                <ConsentText>
                  Indem du dich registrierst, stimmst du den{" "}
                  <ConsentLink>Allgemeinen Geschäftsbedingungen</ConsentLink>{" "}
                  und <ConsentLink>Datenschutzrichtlinien</ConsentLink> sowie
                  der <ConsentLink>Nutzung von Cookies</ConsentLink> zu.
                </ConsentText>
              </ConsentContainer>
              <SignInContainer>
                <SignInText>
                  Already have an account?{" "}
                  <SignInLink onClick={handleOpenSignInModal}>
                    Sign In
                  </SignInLink>
                </SignInText>
              </SignInContainer>
            </BodyWrapper>
          </BodyContainer>
        </ModalBody>
      </Wrapper>
    </Container>
  );
};
export default SignUpModal;
