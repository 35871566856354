// RecipeDetailsForm.js
import React from "react";
//import { Input, Textarea, Select } from "./CommonComponents"; // Import common input components
import { styled } from "styled-components";
import cuisines from "../utils/lists/cuisines.ts";
import difficulties from "../utils/lists/difficulties.ts";
import methods from "../utils/lists/methods.ts";
const Input = styled.input`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const Select = styled.select`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const Textarea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  resize: none;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const Wrapper = styled.div`
  flex-grow: 4;
  display: flex;
  flex-flow: column;
  gap: 20px;
`;
const InputRowWrap = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  align-content: flex-start;
`;
const RecipeDetailsForm = ({ inputs, handleInputChange }) => {
  const handleChange = (e) => {
    console.log("onChange at " + e.target.name);
    handleInputChange(e);
  };
  return (
    <Wrapper>
      <h3>Recipe Details</h3>
      <Input
        type="text"
        placeholder="Recipe Title"
        name="title"
        onChange={handleChange}
        value={inputs.title}
      />
      <Textarea
        placeholder="Description"
        rows="8"
        name="desc"
        onChange={handleInputChange}
        value={inputs.desc}
      />
      <Input
        type="text"
        name="tags"
        placeholder="Separate Tags with comma"
        onChange={handleInputChange}
        value={inputs.tags}
      />
      <InputRowWrap>
        <Select
          name="cuisine"
          id="cuisine"
          onChange={handleInputChange}
          value={inputs.cuisine}
        >
          <option value="" disabled>
            Cuisine
          </option>
          {cuisines.map((cuisine) => (
            <option key={cuisine.label} value={cuisine.value}>
              {cuisine.label}
            </option>
          ))}
        </Select>
        <Select
          name="method"
          id="method"
          onChange={handleInputChange}
          value={inputs.method}
        >
          <option value="" disabled>
            Cooking Method
          </option>
          {methods.map((method) => (
            <option key={method.label} value={method.value}>
              {method.label}
            </option>
          ))}
        </Select>
      </InputRowWrap>
      <InputRowWrap>
        <Select
          name="difficulty"
          id="difficulty"
          onChange={handleInputChange}
          value={inputs.difficulty}
        >
          <option value="" disabled>
            Difficulty
          </option>
          {difficulties.map((difficulty) => (
            <option key={difficulty.label} value={difficulty.value}>
              {difficulty.label}
            </option>
          ))}
        </Select>
        <Input
          type="number"
          min="0"
          placeholder="Yield"
          name="yielding"
          onChange={handleInputChange}
          value={inputs.yielding}
        />
      </InputRowWrap>
      <InputRowWrap>
        <Input
          type="number"
          min="0"
          placeholder="Preptime (Minutes)"
          name="preptime"
          onChange={handleInputChange}
          value={inputs.preptime}
        />
        <Input
          type="number"
          min="0"
          placeholder="Cooktime (Minutes)"
          name="cooktime"
          onChange={handleInputChange}
          value={inputs.cooktime}
        />
        <Input
          type="number"
          min="0"
          placeholder="Resttime (Minutes)"
          name="resttime"
          onChange={handleInputChange}
          value={inputs.resttime}
        />
      </InputRowWrap>
      <InputRowWrap>
        <Input
          type="number"
          min="0"
          placeholder="Calories / 100g"
          name="calories"
          onChange={handleInputChange}
          value={inputs.calories}
        />
        <Input
          type="number"
          min="0"
          placeholder="Fat / 100g"
          name="fat"
          onChange={handleInputChange}
          value={inputs.fat}
        />
        <Input
          type="number"
          min="0"
          placeholder="Carbohydrates / 100g"
          name="carbohydrates"
          onChange={handleInputChange}
          value={inputs.carbohydrates}
        />
        <Input
          type="number"
          min="0"
          placeholder="Proteins / 100g"
          name="proteins"
          onChange={handleInputChange}
          value={inputs.proteins}
        />
        <Input
          type="number"
          min="0"
          placeholder="Salt / 100g"
          name="salt"
          onChange={handleInputChange}
          value={inputs.salt}
        />
      </InputRowWrap>
    </Wrapper>
  );
};
export default RecipeDetailsForm;
