import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RecipeCard from "../components/Recipecard";
import axios from "axios";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0px 20px 40px 20px;
`;

const Search = () => {
  const [videos, setVideos] = useState([]);
  const query = useLocation().search;

  console.log("query: " + query);

  useEffect(() => {
    const fetchVideos = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/search${query}`
      );
      setVideos(res.data);
    };
    fetchVideos();
  }, [query]);
  return (
    <Container>
      {videos.map((video) => (
        <RecipeCard key={video._id} video={video} />
      ))}
    </Container>
  );
};

export default Search;
