import { MailIcon, PersonIcon, ShieldLockIcon } from "@primer/octicons-react";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { styled, useTheme } from "styled-components";
import { openModal } from "../../redux/modalSlice";
const Container = styled.div`
  align-self: stretch;
  color: ${(props) => props.theme.colorOnBackground};
  font-size: 14px;
  width: 224px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colorBackground};
  overflow-y: auto;
`;
const Wrapper = styled.div`
  padding: 18px 0px 20px 0px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  gap: 12px;
  cursor: pointer;
  font-weight: 600;
  padding: 8px;
  border-radius: 8px;
  &:hover {
    background-color: ${(props) => props.theme.colorSurfaceVariant};
  }
`;

const SettingsMenu = ({ darkMode, setDarkMode, menuOpen }) => {
  const location = useLocation(); // '/about'
  const path = location.pathname;
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleOpenSignUpModal = (e) => {
    e.preventDefault();
    dispatch(openModal("SignUp"));
  };
  return (
    <Container>
      <Wrapper>
        <Link
          to="/settings/account"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Item
            style={{
              backgroundColor:
                path === "/settings/profile" ||
                ("/settings" && theme.colorSurfaceVariant),
            }}
          >
            <PersonIcon />
            Account & Security
          </Item>
        </Link>
        <Link
          to="/setting/recipes"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Item
            style={{
              backgroundColor:
                path === "/settings/email" && theme.colorSurfaceVariant,
            }}
          >
            <MailIcon /> Recipes
          </Item>
        </Link>
        <Link
          to="/setting/collections"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Item
            onClick={handleOpenSignUpModal}
            style={{
              backgroundColor:
                path === "/settings/security" && theme.colorSurfaceVariant,
            }}
          >
            <ShieldLockIcon /> Collections
          </Item>
        </Link>
      </Wrapper>
    </Container>
  );
};
export default SettingsMenu;
//TODO
//MUI Icons integration, seams to require the MUI Theme?
