import {
  DeviceCameraVideoIcon,
  DotFillIcon,
  GearIcon,
  HomeIcon,
  QuestionIcon,
  RepoIcon,
  ReportIcon,
  TelescopeIcon,
} from "@primer/octicons-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { styled, useTheme } from "styled-components";
import SignInButton from "../components/auth/SignInButton";
import SignUpButton from "../components/auth/SignUpButton";
import { openModal } from "../redux/modalSlice";
const Container = styled.div`
  align-self: stretch;
  color: ${(props) => props.theme.background}
  font-size: 14px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colorBackground};
  overflow-y: auto;
`;
const Wrapper = styled.div`
  padding: 18px 0px 20px 20px;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  margin-top: 8px;
`;
const Or = styled.p`
  margin: 0px;
  padding: 0px;
  flex: 0;
  text-align: center;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  gap: 12px;
  cursor: pointer;
  font-weight: 600;
  padding: 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  &:hover {
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const Hr = styled.hr`
  margin: 15px 0;
  border: 0.5px solid ${({ theme }) => theme.colorSurfaceVariant};
`;
const Login = styled.div`
  padding: 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colorSurface};
`;
const Menu = ({ darkMode, setDarkMode, menuOpen }) => {
  const theme = useTheme();
  const { currentUser } = useSelector((state) => state.user);
  const location = useLocation(); // '/about'
  const path = location.pathname;
  const dispatch = useDispatch();
  const handleOpenSignUpModal = (e) => {
    e.preventDefault();
    dispatch(openModal("SignUp"));
  };
  const handleUploadModal = (e) => {
    e.preventDefault();
    dispatch(openModal("Upload"));
  };
  return (
    <Container style={{ width: menuOpen ? "224px" : "52px" }}>
      <Wrapper>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Item
            style={{
              backgroundColor: path === "/" && theme.colorSurfaceVariant,
            }}
          >
            <HomeIcon /> {menuOpen && "Home"}
          </Item>
        </Link>
        <Link
          to="/trends"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Item
            style={{
              backgroundColor: path === "/trends" && theme.colorSurfaceVariant,
            }}
          >
            <TelescopeIcon /> {menuOpen && "Explore"}
          </Item>
        </Link>
        {/*<Link
          to={currentUser ? "/subscriptions" : "/signin"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Item
            style={{ backgroundColor: path === "/subscriptions" && theme.colorSurfaceVariant }}
          >
            <BroadcastIcon />
            {menuOpen && "Subscriptions"}
          </Item>
  </Link>*/}
        <Hr />
        {currentUser ? (
          <Link
            to={"/library"}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Item
              style={{
                backgroundColor:
                  path === "/library" && theme.colorSurfaceVariant,
              }}
            >
              <RepoIcon /> {menuOpen && "Library"}
            </Item>
          </Link>
        ) : (
          <Item
            onClick={handleOpenSignUpModal}
            style={{
              backgroundColor: path === "/library" && theme.colorSurfaceVariant,
            }}
          >
            <RepoIcon /> {menuOpen && "Library"}
          </Item>
        )}
        {/*<Link
          to="/history"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Item>
            <VersionsIcon fill={path === "/history" && theme.colorPrimary}/> History
          </Item>
  </Link>*/}
        {currentUser ? (
          <Hr />
        ) : (
          menuOpen && (
            <>
              <Hr />
              <Login>
                Sign up to save, upload, and comment on recipes
                <Buttons>
                  <SignUpButton />
                  <Or>or</Or>
                  <SignInButton />
                </Buttons>
              </Login>
              <Hr />
            </>
          )
        )}
        {currentUser && (
          <Link
            to="/settings"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Item
              style={{
                backgroundColor:
                  path === "/settings" && theme.colorSurfaceVariant,
              }}
            >
              <GearIcon />
              {menuOpen && "Settings"}
            </Item>
          </Link>
        )}
        {currentUser ? (
          <Item
            style={{
              backgroundColor: path === "/upload" && theme.colorSurfaceVariant,
            }}
            onClick={handleUploadModal}
          >
            <DeviceCameraVideoIcon /> {menuOpen && "Upload"}
          </Item>
        ) : (
          <Item
            onClick={handleOpenSignUpModal}
            style={{
              backgroundColor: path === "/upload" && theme.colorSurfaceVariant,
            }}
          >
            <DeviceCameraVideoIcon /> {menuOpen && "Upload"}
          </Item>
        )}
        <Link
          to="/feedback"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Item
            style={{
              backgroundColor:
                path === "/feedback" && theme.colorSurfaceVariant,
            }}
          >
            <ReportIcon /> {menuOpen && "Feedback"}
          </Item>
        </Link>
        <Link to="/about" style={{ textDecoration: "none", color: "inherit" }}>
          <Item
            style={{
              backgroundColor: path === "/about" && theme.colorSurfaceVariant,
            }}
          >
            <QuestionIcon />
            {menuOpen && "About CookCook"}
          </Item>
        </Link>
        <Item onClick={() => setDarkMode(!darkMode)}>
          <DotFillIcon />
          {menuOpen && (darkMode ? "Light" : "Dark")}
        </Item>
      </Wrapper>
    </Container>
  );
};
export default Menu;
//TODO
//MUI Icons integration, seams to require the MUI Theme?
