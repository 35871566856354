import { XIcon } from "@primer/octicons-react";
import React, { useState } from "react";
import { styled } from "styled-components";
import { closeModal } from "../../redux/modalSlice";

import { useDispatch } from "react-redux";

import { resetPassword } from "../../utils/firebase.js";

const Container = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
const Wrapper = styled.div`
  //Layout
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  //Appearance
  min-width: 600px;
  max-width: 80vw;
  min-height: 400px;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 12px;
`;
const BackDrop = styled.div`
  //Layout
  align-items: stretch;

  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0;
  margin: 0px;
  padding: 0px;
  z-index: 0;
  position: fixed;
  //Appearance
  background-color: #000000a7;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const ModalHead = styled.div`
  //Layout
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
  cursor: default;
`;

const ModalBody = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 600px;
  overflow: auto;
`;

const CloseButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  order: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;

const ButtonContainer = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  flex-basis: auto;
  flex-direction: row;
  flex-shrink: 0;

  width: 100%;

  min-width 240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  //Flex
  order: 1;
  justify-content: center;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;

const Button = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colorTertiaryContainer};
  padding: 8px 12px;
  color: ${({ theme }) => theme.colorTertiary};
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorTertiary};
  width: fit-content;
  &:hover {
    color: ${({ theme }) => theme.colorOnTertiary};
    background-color: ${({ theme }) => theme.colorTertiary};
  }
  &:disabled {
    color: black;
    background-color: grey;
    border: 1px solid grey;
    cursor: not-allowed;
  }
`;

const LabelInputGroup = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
`;

const Label = styled.label`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-left: 9px;
`;
const Input = styled.input`
  height: max-content;
  border: ${({ theme, error }) => (error ? "2px" : "1px")} solid
    ${({ theme, error }) => (error ? theme.colorError : theme.colorOutline)};
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};

  border-radius: 8px;
  max-width: 440px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;

const BodyContainer = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};
  border: 2px solid background-color: ${({ theme }) => theme.colorBackground};
  border-radius: 12px;
  cursor: default;
`;

const BodyWrapper = styled.div`
  //Layout
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 24px;
  padding-bottom: 48px;
  gap: 18px;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
`;

const H3 = styled.h2`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 36px;
  text-align: center;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;

const ResetPasswordModal = () => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const closeResetPasswordModal = (e) => {
    e.preventDefault();
    dispatch(closeModal());
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    console.log(email);
    try {
      await resetPassword(email).then(() => {
        dispatch(closeModal());
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <BackDrop onClick={closeResetPasswordModal} />
      <Wrapper>
        <ModalHead>
          <CloseButton onClick={closeResetPasswordModal}>
            <XIcon size={20} />
          </CloseButton>
          <H3>Reset Password</H3>
        </ModalHead>
        <ModalBody>
          <BodyContainer>
            <BodyWrapper>
              <LabelInputGroup>
                <Label>Email</Label>
                <Input
                  type="text"
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </LabelInputGroup>
              <ButtonContainer>
                <Button onClick={handleResetPassword}>Reset Password</Button>
              </ButtonContainer>
            </BodyWrapper>
          </BodyContainer>
        </ModalBody>
      </Wrapper>
    </Container>
  );
};

export default ResetPasswordModal;
