import axios from "axios";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { format } from "timeago.js";

import { StarFillIcon } from "@primer/octicons-react";

const Container = styled.div`
  width: ${(props) => props.type !== "sm" && "351px"};
  cursor: pointer;
  display: ${(props) => props.type === "sm" && "flex"};
  gap: 8px;
`;

const Thumbnail = styled.div`
  position: relative;
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "204px")};
`;
const ThumbnailContainer = styled.div`
  position: relative;
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "196px")};
`;
const ThumbnailImage = styled.img`
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "196px")};
  background-color: ${({ theme }) => theme.colorPrimaryContainer};
  border-radius: 8px;
  margin-top: 4px;
  flex: 1;
  z-index: 0;
`;
const ThumbnailStack = styled.div`
  position: absolute;
  top: -4px;
  width: 90%;
  margin-left: 5%;
  height: ${(props) => (props.type === "sm" ? "94px" : "182px")};
  background-color: ${({ theme }) => theme.colorSurfaceVariant};
  border-radius: 8px;
  flex: 1;
  z-index: 0;
`;

const ThumbnailOverlay = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  font-size: 10px;
  margin: 12px;
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

const TimeStatus = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  padding: 3px 4px 3px 4px;
  font-weight: 500;
`;

const Details = styled.div`
  display: flex;
  margin-top: ${(props) => props.type !== "sm" && "8px"};
  gap: 12px;
  flex: 1 1;
`;

const ChannelImage = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPrimary};
  display: ${(props) => props.type === "sm" && "none"};
  flex: none;
`;

const Texts = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
  flex: 1 1;
`;
const Headline = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 1;
`;
const Title = styled.h3`
  font-size: ${(props) => (props.type === "sm" ? "14px" : "16px")};
  font-weight: 600;
  color: ${({ theme }) => theme.colorOnBackground};
  margin: 0px;
  flex: 1 1;
`;
const Ratings = styled.div`
  font-size: 14px;
  display: ${(props) => (props.type === "sm" ? "none" : "flex")};
  gap: 4px;
  flex-direction: row;
  color: ${({ theme }) => theme.colorOnBackground};
  align-items: center;
`;
const ChannelName = styled.h4`
  font-size: 14px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin: 0px;
  font-weight: 400;
`;
const Info = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin: 0px;
`;

const CollectionCard = ({ type, collection }) => {
  const [channel, setChannel] = useState([]);

  useEffect(() => {
    const fetchChannel = async () => {
      const res = await axios
        .get(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/find/${collection.userId}`
        )
        .then(console.log("collectionUserId: " + collection.userId));

      setChannel(res.data);
    };
    fetchChannel();
  }, [collection.userId]);

  return (
    <Link
      to={`/collection/${collection._id}`}
      style={{ textDecoration: "none" }}
    >
      <Container type={type}>
        <Thumbnail>
          <ThumbnailStack />
          <ThumbnailContainer type={type}>
            <ThumbnailImage type={type} src={collection.imgURL} />
            <ThumbnailOverlay>
              <TimeStatus>Popular</TimeStatus>
            </ThumbnailOverlay>
          </ThumbnailContainer>
        </Thumbnail>
        <Details type={type}>
          <ChannelImage type={type} src={channel.img} />
          <Texts>
            <Headline>
              <Title type={type}>{collection.title}</Title>
              {collection.rating && (
                <Ratings>
                  <StarFillIcon size={14} />
                  4,92
                </Ratings>
              )}
            </Headline>
            <ChannelName>{channel.name}</ChannelName>

            <Info>{format(collection.createdAt)}</Info>
          </Texts>
        </Details>
      </Container>
    </Link>
  );
};
export default CollectionCard;
