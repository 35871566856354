import axios from "axios";
import { React, useEffect, useState, memo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { format } from "timeago.js";
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Info = styled.span`
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
`;

const RecipeInfos = memo(function ({ currentVideo }) {
  const path = useLocation().pathname.split("/")[2];

  const [views, setViews] = useState([]);
  const loadRecipeInfos = useCallback(async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/views/video/${
            currentVideo?._id || path
          }`,
          {
            withCredentials: true,
          }
        )
        .then((viewsRes) => {
          if (viewsRes.status === 200) {
            setViews(viewsRes.data.length);
          }
        });
      /*await axios.post(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/views/`,
        { videoId: path },
        {
          withCredentials: true,
        }
      );*/
    } catch (err) {
      console.log("Error fetching views: ", err);
    }
  }, [path, currentVideo]);

  useEffect(() => {
    loadRecipeInfos();
  }, [path, loadRecipeInfos]);
  return (
    <Container>
      <Title>{currentVideo?.title || "Loading..."}</Title>
      <Info>
        {views ? views : "Loading "} views •{" "}
        {format(currentVideo?.createdAt || "...")}
      </Info>
    </Container>
  );
});
export default RecipeInfos;
