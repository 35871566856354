import { PlusIcon, TrashIcon } from "@primer/octicons-react";
import React, { useState } from "react";
import { styled } from "styled-components";
//Hooks
import useDidMount from "../utils/hooks/useDidMount";
const Container = styled.ul`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  gap: 20px;
  margin: 0px;
  padding-inline-start: 0px;
`;
const Button = styled.button`
  padding: 5px;
  background-color: ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  &:hover {
    background-color: ${({ theme }) => theme.colorSurface};
  }
`;
const LabelInputGroup = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
`;
const Label = styled.label`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-left: 9px;
  flex-grow: 1;
`;
const Input = styled.input`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const Textarea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  resize: none;
  flex-grow: 1;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const InstructionListItem = styled.li`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  align-content: flex-start;
`;
const InstructionList = ({ setInstructions, instructions, unitSystem }) => {
  //LOCAL STATES
  const [instructionId, setInstructionId] = useState(0);
  //HOOKS
  useDidMount(() => {
    //if instructions is empty, execute setInstructions
    if (instructions !== undefined && instructions.length > 0) {
      handleLoadInstructionsList(instructions);
    } else {
      console.log("no instructions");
      setInstructions([{ id: instructionId, title: "", instruction: "" }]);
      setInstructionId(instructions.length);
    }
  });
  //HANDLES
  const handleLoadInstructionsList = (ins) => {
    const transferInstructions = ins.map(({ title, instruction }, index) => ({
      id: index,
      title,
      instruction,
    }));
    setInstructions(transferInstructions);
    setInstructionId(transferInstructions.length);
  };
  const handleAddInstruction = () => {
    setInstructions((prev) => {
      return [...prev, { id: instructionId, title: "", instruction: "" }];
    });
    setInstructionId(instructionId + 1);
  };
  const handleDeleteInstruction = (index) => {
    const newInstruction = instructions.filter(
      (instruction) => instruction.id !== index
    );
    setInstructions(newInstruction);
  };
  const handleChangeInstruction = (e, id) => {
    const updatedInstructions = instructions.map((item) => {
      if (item.id === id) {
        const updatedItem = { ...item, [e.target.name]: e.target.value };
        return updatedItem;
      } else {
        return item;
      }
    });
    setInstructions(updatedInstructions);
  };
  return (
    <Container>
      {instructions.map((instruction) => (
        <InstructionListItem key={instruction.id}>
          <LabelInputGroup>
            {instruction.id === 0 && <Label>Title</Label>}
            <Input
              key={`title-${instruction.id}`}
              type="text"
              placeholder="Title"
              name={`title`}
              id={`title-${instruction.id}`}
              onChange={(e) => handleChangeInstruction(e, instruction.id)}
              defaultValue={instruction.title}
            />
          </LabelInputGroup>
          <LabelInputGroup style={{ flexShrink: "0", flexGrow: "1" }}>
            {instruction.id === 0 && <Label>Instruction</Label>}
            <Textarea
              key={`instruction-${instruction.id}`}
              type="text"
              rows="8"
              placeholder="Instruction"
              name={`instruction`}
              id={`instruction-${instruction.id}`}
              onChange={(e) => handleChangeInstruction(e, instruction.id)}
              defaultValue={instruction.instruction}
              value={instruction.instruction}
            />
          </LabelInputGroup>
          <Button
            onClick={
              instruction.id > 0
                ? () => handleDeleteInstruction(instruction.id)
                : null
            }
            style={{ opacity: instruction.id > 0 ? 1 : 0 }}
          >
            <TrashIcon />
          </Button>
        </InstructionListItem>
      ))}
      <Button onClick={handleAddInstruction}>
        <PlusIcon />
      </Button>
    </Container>
  );
};
export default InstructionList;
