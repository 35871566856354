//External Imports
import { PencilIcon, PlusIcon, TrashIcon } from "@primer/octicons-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchFailure } from "../redux/videoSlice";
import Loader from "../components/Loader";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Helmet } from "react-helmet";
//Internal Imports
import RecipeCard from "../components/Recipecard";
import axios from "axios";
import DeleteCollectionModal from "../components/collections/DeleteCollectionModal";
const Container = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 24px 0px 24px;
  flex-grow: inherit;
  align-self: stretch;
  overflow: hidden;
`;
const Content = styled.div`
  overflow: auto;
  min-height: 0px;
  width: 360px;
  padding-bottom: 24px;
  background-color: ${({ theme }) => theme.colorSurface};
  border-radius: 12px;
  padding: 18px;
  margin-bottom: 24px;
`;
const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  margin: 11px 0px 0px 0px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Desc = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Details = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 12px;
`;
const Head = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;
const Info = styled.span`
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Buttons = styled.div`
  display: flex;
  gap: 20px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Button = styled.button`
  display: flex;
  gap: 20px;
  gap: 5px;
  cursor: pointer;
  border-radius: 8px;
  height: max-content;
  padding: 4px 8px;
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorSurface};
  border: none;
  &:hover {
    color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const CircleIconButton = styled.button`
  flex: none;
  height: 40px;
  width: 40px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 8px;
  order: 1;
  &:hover {
    color: ${({ theme }) => theme.colorOnBackground};
    background-color: ${({ theme }) => theme.colorBackground};
  }
  order: 1;
`;
const Hr = styled.hr`
  margin: 15px 0px;
  border: 0.5px solid ${({ theme }) => theme.colorOutlineVariant};
`;
const Channel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;
const ChannelInfo = styled.span`
  display: flex;
  gap: 20px;
`;
const ChannelAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
const ChannelDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const ChannelName = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const ChannelCounter = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-top: 4px;
`;
const ChannelDescription = styled.p`
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 12px;
`;
const SocialSharing = styled.div`
  display: flex;
  justify-content: start;
  alignt-items: center;
  gap: 12px;
  flex: 1;
  padding: 0px;
  overflow: hidden;
  /* each slide snaps into place */
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    scroll-margin: 10px;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
  }
`;
const RecipeGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  gap: 4px;
  overflow-y: scroll;
  padding-bottom: 24px;
`;
const CollectionRecipeCardContainer = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
  border-radius: 12px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const Thumbnail = styled.div`
  position: relative;
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "204px")};
`;
const ThumbnailContainer = styled.div`
  position: relative;
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "196px")};
`;
const ThumbnailImage = styled.img`
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "196px")};
  background-color: ${({ theme }) => theme.colorPrimaryContainer};
  border-radius: 8px;
  flex: 1;
  z-index: 0;
`;
const ThumbnailOverlay = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  font-size: 10px;
  margin: 12px;
  position: absolute;
  right: 0px;
  bottom: 0px;
`;
const CollectionEdit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;
const TitleInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-radius: 8px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  font-size: 28px;
  font-weight: 700;
  margin: 0px;
`;
const DescInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-radius: 8px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
`;
const TimeStatus = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  padding: 3px 4px 3px 4px;
  font-weight: 500;
`;
const Collection = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [currentCollection, setCurrentCollection] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useLocation().pathname.split("/")[2];
  const shareUrl =
    process.env.REACT_APP_RECIPE_CLIENT_BASE_URL + useLocation().pathname; //String(window.location.href);
  const [channel, setChannel] = useState([]);
  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState("");
  const [changedTitle, setChangedTitle] = useState("");
  const [editDesc, setEditDesc] = useState(false);
  const [changedDesc, setChangedDesc] = useState("");
  const [desccription, setDescription] = useState("");
  //GET DATA
  //Get Collection from URL
  useEffect(() => {
    const fetchCollection = async () => {
      try {
        setLoading(true);
        const collectionRes = await axios.get(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/collections/find/${path}`,
          {
            withCredentials: true,
          }
        );
        if (collectionRes.status === 200) {
          getVideosByCollection(collectionRes.data._id);
          setCurrentCollection(collectionRes.data);
          setTitle(collectionRes.data.title);
          setChangedTitle(collectionRes.data.title);
          setDescription(collectionRes.data.desc);
          setChangedDesc(collectionRes.data.desc);
          const channelRes = await axios.get(
            `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/find/${collectionRes.data.userId}`,
            { withCredentials: true }
          );
          channelRes.status === 200 &&
            setChannel(channelRes.data).then(setLoading(false));
        } else {
          console.log("collectionRes.status");
          console.log(collectionRes.status);
        }
      } catch (err) {
        dispatch(fetchFailure());
        setLoading(false);
      }
    };
    fetchCollection();
  }, [path, dispatch]);
  //HANDLES
  //Title
  const handleEditTitle = (e) => {
    e.preventDefault();
    setEditTitle(true);
  };
  const handleCancelEditTitle = (e) => {
    e.preventDefault();
    setEditTitle(false);
  };
  const handleChangeTitle = (e) => {
    setChangedTitle(e.target.value);
  };
  const handleSaveTitle = async (e) => {
    await axios
      .put(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/collections/${currentCollection._id}`,
        { title: changedTitle },
        {
          withCredentials: true,
        }
      )
      .then(setTitle(changedTitle))
      .then(setEditTitle(false)); //close edit Title
  };
  //Description
  const handleEditDesc = (e) => {
    console.log(shareUrl);
    e.preventDefault();
    setEditDesc(true);
  };
  const handleCancelEditDesc = (e) => {
    e.preventDefault();
    setEditDesc(false);
  };
  const handleChangeDesc = (e) => {
    setChangedDesc(e.target.value);
  };
  const handleSaveDesc = async (e) => {
    await axios
      .put(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/collections/${currentCollection._id}`,
        { desc: changedDesc },
        {
          withCredentials: true,
        }
      )
      .then(setDescription(changedDesc))
      .then(setEditDesc(false)); //close edit Title
  };
  //Collection
  const handleDelete = (e) => {
    e.preventDefault();
    console.log("handleDelete: " + showDeleteModal);
    setShowDeleteModal(true);
  };
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  };
  const deleteCollection = async (e) => {
    document.cookie = "access_token2=deleteCollection; path=/";
    const token = getCookie("access_token");
    console.log(token);
    console.log("Delete me");
    e.preventDefault();
    await axios
      .delete(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/collections/${currentCollection._id}`,
        {
          withCredentials: true,
        }
      )
      .then(console.log("Collection deleted"))
      .then(handleCloseDeleteModal())
      .then(navigate(`/library`));
  };
  const handleCloseDeleteModal = (e) => {
    setShowDeleteModal(false);
  };
  //SCHEMA A
  const collectionStructuredData = {
    "@context": "https://schema.org/",
    "@type": "Collection",
    name: "Loading...",
    author: {
      "@type": "Person",
      name: "Loading...",
    },
    description: "Loading...",
  };
  const getVideosByCollection = async (collectionId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/collection/${collectionId}`,
      { withCredentials: true }
    );
    setVideos(res.data);
  };
  const removeVideoFromCollection = async (videoId) => {
    document.cookie = "access_token3=removeVideoFromCollection; path=/";
    const token = getCookie("access_token3");
    console.log(token);
    await axios.delete(
      `${process.env.REACT_APP_RECIPE_API_BASE_URL}/collections/recipe/${currentCollection._id}`,
      { data: { recipe: videoId }, withCredentials: true }
    );
    setVideos(videos.filter((video) => video._id !== videoId));
  };
  return (
    <Container>
      {!loading ? (
        <>
          <Helmet>
            <title>{currentCollection?.title || "Loading..."}</title>
            <meta
              name="description"
              content={currentCollection?.desc || "Loading..."}
            />
            <meta
              name="keywords"
              content={currentCollection?.tags || "Loading..."}
            />
            <meta name="robots" content="" />
            <link rel="canonical" href="http://localhost:3000/recipe" />
            <script type="application/ld+json">
              {JSON.stringify(collectionStructuredData)}
            </script>
          </Helmet>
          <Content>
            <Thumbnail>
              <ThumbnailContainer>
                <ThumbnailImage src={currentCollection?.imgURL} />
                <ThumbnailOverlay>
                  <TimeStatus>Popular</TimeStatus>
                </ThumbnailOverlay>
              </ThumbnailContainer>
            </Thumbnail>
            <Details>
              <Head>
                {!editTitle && <Title>{title}</Title>}
                {!editTitle && currentUser._id === currentCollection.userId && (
                  <CircleIconButton onClick={handleEditTitle}>
                    <PencilIcon size="24" />
                  </CircleIconButton>
                )}
                {editTitle && (
                  <CollectionEdit>
                    <TitleInput
                      defaultValue={changedTitle}
                      onChange={handleChangeTitle}
                    />
                    <Buttons>
                      <Button onClick={handleCancelEditTitle}>Cancel</Button>
                      <Button onClick={handleSaveTitle}>Save</Button>
                    </Buttons>
                  </CollectionEdit>
                )}
              </Head>
              <Info>
                {!editDesc && (
                  <Desc>{desccription ? desccription : "No description"}</Desc>
                )}
                {!editDesc && currentUser._id === currentCollection.userId && (
                  <CircleIconButton onClick={handleEditDesc}>
                    <PencilIcon size="24" />
                  </CircleIconButton>
                )}
                {editDesc && (
                  <CollectionEdit>
                    <DescInput
                      defaultValue={changedDesc}
                      onChange={handleChangeDesc}
                    />
                    <Buttons>
                      <Button onClick={handleCancelEditDesc}>Cancel</Button>
                      <Button onClick={handleSaveDesc}>Save</Button>
                    </Buttons>
                  </CollectionEdit>
                )}
              </Info>
              {currentUser._id === currentCollection.userId && (
                <Buttons>
                  <CircleIconButton onClick={handleDelete}>
                    <TrashIcon size="24" />
                  </CircleIconButton>
                  <CircleIconButton>
                    <PlusIcon size="24" />
                  </CircleIconButton>
                </Buttons>
              )}
            </Details>
            <Hr />
            <Channel>
              <Link
                to={`/profile/${channel._id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ChannelInfo>
                  <ChannelAvatar src={channel.img} />
                  <ChannelDetails>
                    <ChannelName>{channel?.name || "Loading Name"}</ChannelName>
                    <ChannelCounter>
                      {channel?.subscribers || "Loading #"} Subscribers
                    </ChannelCounter>
                    <ChannelDescription>
                      {channel?.desc || "Loading Description"}
                    </ChannelDescription>
                  </ChannelDetails>
                  {/*currentUser ? (
                  currentUser?._id === channel._id ? (
                    <Link
                      to={`/edit/${currentVideo?._id || "Loading...")}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button>
                        <PencilIcon />
                        Edit
                      </Button>
                    </Link>
                  ) : (
                    <Subscribe
                      onClick={handleSub}
                      style={
                        currentUser.subscribedUsers !== null && {
                          backgroundColor:
                            currentUser.subscribedUsers?.includes(channel._id)
                              ? "black"
                              : "red",
                        }
                      }
                    >
                      {currentUser.subscribedUsers?.includes(channel._id)
                        ? "Subscribed"
                        : "Subscribe"}
                    </Subscribe>
                  )
                ) : (
                  <Link
                    to={`/signin`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Subscribe onClick={handleSignup}>Subscribe</Subscribe>
                  </Link>
                )*/}
                </ChannelInfo>
              </Link>
            </Channel>
            <SocialSharing>
              <EmailShareButton
                url={shareUrl}
                subject={
                  "Take a look at this great recipe collection: " +
                  (currentCollection?.title || "Loading...") +
                  ", from " +
                  channel.name
                }
                body={currentCollection?.desc || "Loading..."}
              >
                <EmailIcon size={32} round={true} />
              </EmailShareButton>{" "}
              <PinterestShareButton
                url={shareUrl}
                description={currentCollection?.desc || "Loading..."}
                media={currentCollection?.imgURL || "Loading..."}
              >
                <PinterestIcon size={32} round={true} />
              </PinterestShareButton>{" "}
              <FacebookShareButton
                url={shareUrl}
                quote={
                  "Take a look at this great recipe collection: " +
                  (currentCollection?.title || "Loading...") +
                  ", from " +
                  channel.name
                }
                hashtag={
                  (currentCollection?.tags || "Loading...") +
                  "," +
                  (currentCollection?.cuisine || "Loading...") +
                  "" +
                  (currentCollection?.method || "Loading...")
                }
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>{" "}
              <TwitterShareButton
                url={shareUrl}
                title={
                  "Take a look at this great recipe collection: " +
                  (currentCollection?.title || "Loading...") +
                  ", from " +
                  channel.name
                }
                via={channel.name + " @ CookCook.com"}
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <WhatsappShareButton
                url={shareUrl}
                title={
                  "Take a look at this great recipe collection: " +
                  (currentCollection?.title || "Loading...") +
                  ", from " +
                  channel.name
                }
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
              <TelegramShareButton
                url={shareUrl}
                title={
                  "Take a look at this great recipe collection: " +
                  (currentCollection?.title || "Loading...") +
                  ", from " +
                  channel.name
                }
              >
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>{" "}
              <RedditShareButton
                url={shareUrl}
                title={
                  "Take a look at this great recipe collection: " +
                  (currentCollection?.title || "Loading...") +
                  ", from " +
                  channel.name
                }
              >
                <RedditIcon size={32} round={true} />
              </RedditShareButton>
              <PocketShareButton
                url={shareUrl}
                title={
                  "Take a look at this great recipe collection: " +
                  (currentCollection?.title || "Loading...") +
                  ", from " +
                  channel.name
                }
              >
                <PocketIcon size={32} round={true} />
              </PocketShareButton>{" "}
            </SocialSharing>
          </Content>
          {videos ? (
            <RecipeGrid>
              {videos.map((video, index) => (
                <CollectionRecipeCardContainer key={video._id + index}>
                  <RecipeCard video={video} type="sm" />
                  {currentUser._id === currentCollection.userId && (
                    <Buttons>
                      <CircleIconButton
                        onClick={removeVideoFromCollection.bind(
                          this,
                          video._id
                        )}
                      >
                        <TrashIcon size="24" />
                      </CircleIconButton>
                    </Buttons>
                  )}
                </CollectionRecipeCardContainer>
              ))}
            </RecipeGrid>
          ) : (
            "No videos in this collection"
          )}
          {showDeleteModal && (
            <DeleteCollectionModal
              closeModal={handleCloseDeleteModal}
              action={deleteCollection}
              callToAction="Delete"
              headline="Delete Collection"
              body="Are you sure you want to delete this collection? This action is
              irreversible."
            />
          )}
        </>
      ) : (
        <>
          <Loader onClick={setLoading(false)} />
        </>
      )}
    </Container>
  );
};
export default Collection;
