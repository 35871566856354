import octokit from "./octokit";

export const createIssue = async (feedback, feedbackLabels) => {
  octokit.request("GET /").then(console.log, console.log);
  try {
    const response = await octokit.request(
      "POST /repos/{owner}/{repo}/issues",
      {
        owner: "alexandrosshomper",
        repo: "CookBook",
        title: feedback,
        labels: feedbackLabels,
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error(error);
  }
};
