import axios from "axios";
import { React, memo, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import RecipeStep from "./RecipeStep";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  color: ${({ theme }) => theme.colorOnSurface};
  background-color: ${({ theme }) => theme.colorSurface};
  border-radius: 8px;
  padding: 12px 0px;
  width: 400px;
  margin-bottom: 12px;
`;
const Head = styled.h3`
  padding-top: 0px;
  margin-top: 0px;
  padding: 0px 12px;
  flex: 1;
`;
const RecipeInstructions = memo(function ({ currentVideo }) {
  const path = useLocation().pathname.split("/")[2];
  const [instructions, setInstructions] = useState([]);
  const loadRecipeInstructions = useCallback(async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/recipeSteps/${
            currentVideo?._id || path
          }`,
          {
            withCredentials: true,
          }
        )
        .then((instructionsRes) => {
          if (instructionsRes.status === 200) {
            setInstructions(instructionsRes.data);
          }
        });
    } catch (err) {
      console.log("Error fetching instructions: ", err);
    }
  }, [path, currentVideo]);
  useEffect(() => {
    loadRecipeInstructions();
  }, [path, loadRecipeInstructions]);
  return (
    <Container>
      <Head>Instructions</Head>
      {instructions?.map((instruction) => (
        <RecipeStep
          key={instruction._id}
          title={instruction.title}
          time={instruction.time}
          desc={instruction.desc}
          images={instruction.imgURL}
        />
      ))}
    </Container>
  );
});
export default RecipeInstructions;
