import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, styled } from "styled-components";
import Menu from "./components/Menu";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import { darkTheme, lightTheme } from "./utils/Theme";
//Auth
import VerifyEmailHat from "./components/auth/VerifyEmailHat";
//Collections
import AddToCollectionModal from "./components/collections/AddToCollectionModal";
import CreateCollectionModal from "./components/collections/CreateCollectionModal";
import Collection from "./pages/Collection";
import Collections from "./pages/Collections";
//Recipe
import Recipe from "./pages/Recipe";
import RecipeEdit from "./pages/RecipeEdit";
import RecipeUpload from "./pages/RecipeUpload";
import Search from "./pages/Search";
//Profile
import EditProfile from "./pages/EditProfile";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
//Modals
import DeleteUserModal from "./components/DeleteUserModal";
import UploadModal from "./components/UploadModal";
import SignInModal from "./components/auth/SignInModal";
import SignUpModal from "./components/auth/SignUpModal";
import ResetPasswordModal from "./components/modals/ResetPasswordModal";
//Other
import { useSelector } from "react-redux";
import About from "./pages/About";
import Feedback from "./pages/Feedback";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  height: 100vh;
`;
const Main = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  min-height: 0px;
  background-color: ${({ theme }) => theme.colorBackground};
`;
const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: scroll;
`;
function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const { currentModal } = useSelector((state) => state.modal);

  console.log("App.js");
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Container>
        <BrowserRouter>
          {currentModal === "SignUp" && <SignUpModal />}
          {currentModal === "SignIn" && <SignInModal />}
          {currentModal === "CreateCollection" && <CreateCollectionModal />}
          {currentModal === "AddToCollection" && <AddToCollectionModal />}
          {currentModal === "Upload" && <UploadModal />}
          {currentModal === "DeleteUser" && <DeleteUserModal />}
          {currentModal === "ResetPassword" && <ResetPasswordModal />}
          <VerifyEmailHat></VerifyEmailHat>
          <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <Main>
            <Menu
              darkMode={darkMode}
              setDarkMode={setDarkMode}
              menuOpen={menuOpen}
            />
            <Wrapper>
              <Routes>
                <Route path="/">
                  <Route index element={<Home type="random" />} />
                  <Route path="trends" element={<Home type="trend" />} />
                  <Route path="subscriptions" element={<Home type="sub" />} />
                  <Route path="search" element={<Search />} />
                  <Route path="library" element={<Collections />} />
                  <Route path="history" element={<Home type="history" />} />
                  <Route path="feedback" element={<Feedback />} />
                  <Route path="upload" element={<RecipeUpload />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="about" element={<About />} />
                  <Route path="edit">
                    <Route path=":id" element={<RecipeEdit />} />
                  </Route>
                  <Route path="collection">
                    <Route path=":id" element={<Collection />} />
                  </Route>
                  <Route path="recipe">
                    <Route path=":id" element={<Recipe />} />
                  </Route>
                  <Route path="profile">
                    <Route path=":id" element={<Profile />} />
                    <Route path="edit">
                      <Route path=":id" element={<EditProfile />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </Wrapper>
          </Main>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}
export default App;
