export const darkTheme = {
  colorPrimary: "var(--md-sys-color-primary-dark)",
  colorOnPrimary: "var(--md-sys-color-on-primary-dark)",
  colorPrimaryContainer: "var(--md-sys-color-primary-container-dark)",
  colorOnPrimaryContainer: "var(--md-sys-color-on-primary-container-dark)",
  colorSecondary: "var(--md-sys-color-secondary-dark)",
  colorOnSecondary: "var(--md-sys-color-on-secondary-dark)",
  colorSecondaryContainer: "var(--md-sys-color-secondary-container-dark)",
  colorOnSecondaryContainer: "var(--md-sys-color-on-secondary-container-dark)",
  colorTertiary: "var(--md-sys-color-tertiary-dark)",
  colorOnTertiary: "var(--md-sys-color-on-tertiary-dark)",
  colorTertiaryContainer: "var(--md-sys-color-tertiary-container-dark)",
  colorOnTertiaryContainer: "var(--md-sys-color-on-tertiary-container-dark)",
  colorError: "var(--md-sys-color-error-dark)",
  colorErrorContainer: "var(--md-sys-color-error-container-dark)",
  colorOnError: "var(--md-sys-color-on-error-dark)",
  colorOnErrorContainer: "var(--md-sys-color-on-error-container-dark)",
  colorBackground: "var(--md-sys-color-background-dark)",
  colorOnBackground: "var(--md-sys-color-on-background-dark)",
  colorSurface: "var(--md-sys-color-surface-dark)",
  colorOnSurface: "var(--md-sys-color-on-surface-dark)",
  colorSurfaceVariant: "var(--md-sys-color-surface-variant-dark)",
  colorOnSurfaceVariant: "var(--md-sys-color-on-surface-variant-dark)",
  colorOutline: "var(--md-sys-color-outline-dark)",
  colorInverseOnSurface: "var(--md-sys-color-inverse-on-surface-dark)",
  colorInverseSurface: "var(--md-sys-color-inverse-surface-dark)",
  colorInversePrimary: "var(--md-sys-color-inverse-primary-dark)",
  colorShadow: "var(--md-sys-color-shadow-dark)",
  colorSurfaceTint: "var(--md-sys-color-surface-tint-dark)",
  colorOutlineVariant: "var(--md-sys-color-outline-variant-dark)",
  colorScrim: "var(--md-sys-color-scrim-dark)",
  colorContrastPurple1: "var(--cc-a11y-contrast-purple1)",
  colorContrastPurple2: "var(--cc-a11y-contrast-purple2)",
  colorContrastPurple3: "var(--cc-a11y-contrast-purple3)",
  colorContrastPurple4: "var(--cc-a11y-contrast-purple4)",
  colorContrastPurple5: "var(--cc-a11y-contrast-purple5)",
  colorContrastPurple6: "var(--cc-a11y-contrast-purple6)",
  colorContrastPurple7: "var(--cc-a11y-contrast-purple7)",
  colorContrastPurple8: "var(--cc-a11y-contrast-purple8)",
  colorContrastPurple9: "var(--cc-a11y-contrast-purple9)",
  colorContrastPurple10: "var(--cc-a11y-contrast-purple10)",
  colorContrastGreen1: "var(--cc-a11y-contrast-green1)",
  colorContrastGreen2: "var(--cc-a11y-contrast-green2)",
  colorContrastGreen3: "var(--cc-a11y-contrast-green3)",
  colorContrastGreen4: "var(--cc-a11y-contrast-green4)",
  colorContrastGreen5: "var(--cc-a11y-contrast-green5)",
  colorContrastGreen6: "var(--cc-a11y-contrast-green6)",
  colorContrastGreen7: "var(--cc-a11y-contrast-green7)",
  colorContrastGreen8: "var(--cc-a11y-contrast-green8)",
  colorContrastGreen9: "var(--cc-a11y-contrast-green9)",
  colorContrastGreen10: "var(--cc-a11y-contrast-green10)",
  colorContrastRed1: "var(--cc-a11y-contrast-red1)",
  colorContrastRed2: "var(--cc-a11y-contrast-red2)",
  colorContrastRed3: "var(--cc-a11y-contrast-red3)",
  colorContrastRed4: "var(--cc-a11y-contrast-red4)",
  colorContrastRed5: "var(--cc-a11y-contrast-red5)",
  colorContrastRed6: "var(--cc-a11y-contrast-red6)",
  colorContrastRed7: "var(--cc-a11y-contrast-red7)",
  colorContrastRed8: "var(--cc-a11y-contrast-red8)",
  colorContrastRed9: "var(--cc-a11y-contrast-red9)",
  colorContrastRed10: "var(--cc-a11y-contrast-red10)",
  colorContrastBlue1: "var(--cc-a11y-contrast-blue1)",
  colorContrastBlue2: "var(--cc-a11y-contrast-blue2)",
  colorContrastBlue3: "var(--cc-a11y-contrast-blue3)",
  colorContrastBlue4: "var(--cc-a11y-contrast-blue4)",
  colorContrastBlue5: "var(--cc-a11y-contrast-blue5)",
  colorContrastBlue6: "var(--cc-a11y-contrast-blue6)",
  colorContrastBlue7: "var(--cc-a11y-contrast-blue7)",
  colorContrastBlue8: "var(--cc-a11y-contrast-blue8)",
  colorContrastBlue9: "var(--cc-a11y-contrast-blue9)",
  colorContrastBlue10: "var(--cc-a11y-contrast-blue10)",
};

export const lightTheme = {
  colorPrimary: "var(--md-sys-color-primary-dark)", //switched to dark for yellow instead of brown
  colorOnPrimary: "var(--md-sys-color-on-primary-dark)",
  colorPrimaryContainer: "var(--md-sys-color-primary-container-light)",
  colorOnPrimaryContainer: "var(--md-sys-color-on-primary-container-light)",
  colorSecondary: "var(--md-sys-color-secondary-light)",
  colorOnSecondary: "var(--md-sys-color-on-secondary-light)",
  colorSecondaryContainer: "var(--md-sys-color-secondary-container-light)",
  colorOnSecondaryContainer: "var(--md-sys-color-on-secondary-container-light)",
  colorTertiary: "var(--md-sys-color-tertiary-light)",
  colorOnTertiary: "var(--md-sys-color-on-tertiary-light)",
  colorTertiaryContainer: "var(--md-sys-color-tertiary-container-light)",
  colorOnTertiaryContainer: "var(--md-sys-color-on-tertiary-container-light)",
  colorError: "var(--md-sys-color-error-light)",
  colorErrorContainer: "var(--md-sys-color-error-container-light)",
  colorOnError: "var(--md-sys-color-on-error-light)",
  colorOnErrorContainer: "var(--md-sys-color-on-error-container-light)",
  colorBackground: "var(--md-sys-color-background-light)",
  colorOnBackground: "var(--md-sys-color-on-background-light)",
  colorSurface: "var(--md-sys-color-surface-light)",
  colorOnSurface: "var(--md-sys-color-on-surface-light)",
  colorSurfaceVariant: "var(--md-sys-color-surface-variant-light)",
  colorOnSurfaceVariant: "var(--md-sys-color-on-surface-variant-light)",
  colorOutline: "var(--md-sys-color-outline-light)",
  colorInverseOnSurface: "var(--md-sys-color-inverse-on-surface-light)",
  colorInverseSurface: "var(--md-sys-color-inverse-surface-light)",
  colorInversePrimary: "var(--md-sys-color-inverse-primary-light)",
  colorShadow: "var(--md-sys-color-shadow-light)",
  colorSurfaceTint: "var(--md-sys-color-surface-tint-light)",
  colorOutlineVariant: "var(--md-sys-color-outline-variant-light)",
  colorScrim: "var(--md-sys-color-scrim-light)",
  colorContrastPurple1: "var(--cc-a11y-contrast-purple1)",
  colorContrastPurple2: "var(--cc-a11y-contrast-purple2)",
  colorContrastPurple3: "var(--cc-a11y-contrast-purple3)",
  colorContrastPurple4: "var(--cc-a11y-contrast-purple4)",
  colorContrastPurple5: "var(--cc-a11y-contrast-purple5)",
  colorContrastPurple6: "var(--cc-a11y-contrast-purple6)",
  colorContrastPurple7: "var(--cc-a11y-contrast-purple7)",
  colorContrastPurple8: "var(--cc-a11y-contrast-purple8)",
  colorContrastPurple9: "var(--cc-a11y-contrast-purple9)",
  colorContrastPurple10: "var(--cc-a11y-contrast-purple10)",
  colorContrastGreen1: "var(--cc-a11y-contrast-green1)",
  colorContrastGreen2: "var(--cc-a11y-contrast-green2)",
  colorContrastGreen3: "var(--cc-a11y-contrast-green3)",
  colorContrastGreen4: "var(--cc-a11y-contrast-green4)",
  colorContrastGreen5: "var(--cc-a11y-contrast-green5)",
  colorContrastGreen6: "var(--cc-a11y-contrast-green6)",
  colorContrastGreen7: "var(--cc-a11y-contrast-green7)",
  colorContrastGreen8: "var(--cc-a11y-contrast-green8)",
  colorContrastGreen9: "var(--cc-a11y-contrast-green9)",
  colorContrastGreen10: "var(--cc-a11y-contrast-green10)",
  colorContrastRed1: "var(--cc-a11y-contrast-red1)",
  colorContrastRed2: "var(--cc-a11y-contrast-red2)",
  colorContrastRed3: "var(--cc-a11y-contrast-red3)",
  colorContrastRed4: "var(--cc-a11y-contrast-red4)",
  colorContrastRed5: "var(--cc-a11y-contrast-red5)",
  colorContrastRed6: "var(--cc-a11y-contrast-red6)",
  colorContrastRed7: "var(--cc-a11y-contrast-red7)",
  colorContrastRed8: "var(--cc-a11y-contrast-red8)",
  colorContrastRed9: "var(--cc-a11y-contrast-red9)",
  colorContrastRed10: "var(--cc-a11y-contrast-red10)",
  colorContrastBlue1: "var(--cc-a11y-contrast-blue1)",
  colorContrastBlue2: "var(--cc-a11y-contrast-blue2)",
  colorContrastBlue3: "var(--cc-a11y-contrast-blue3)",
  colorContrastBlue4: "var(--cc-a11y-contrast-blue4)",
  colorContrastBlue5: "var(--cc-a11y-contrast-blue5)",
  colorContrastBlue6: "var(--cc-a11y-contrast-blue6)",
  colorContrastBlue7: "var(--cc-a11y-contrast-blue7)",
  colorContrastBlue8: "var(--cc-a11y-contrast-blue8)",
  colorContrastBlue9: "var(--cc-a11y-contrast-blue9)",
  colorContrastBlue10: "var(--cc-a11y-contrast-blue10)",
};

export const systemTheme = {
  colorPrimary: "var(--md-sys-color-primary)",
  colorOnPrimary: "var(--md-sys-color-on-primary)",
  colorPrimaryContainer: "var(--md-sys-color-primary-container)",
  colorOnPrimaryContainer: "var(--md-sys-color-on-primary-container)",
  colorSecondary: "var(--md-sys-color-secondary)",
  colorOnSecondary: "var(--md-sys-color-on-secondary)",
  colorSecondaryContainer: "var(--md-sys-color-secondary-container)",
  colorOnSecondaryContainer: "var(--md-sys-color-on-secondary-container)",
  colorTertiary: "var(--md-sys-color-tertiary)",
  colorOnTertiary: "var(--md-sys-color-on-tertiary)",
  colorTertiaryContainer: "var(--md-sys-color-tertiary-container)",
  colorOnTertiaryContainer: "var(--md-sys-color-on-tertiary-container)",
  colorError: "var(--md-sys-color-error)",
  colorErrorContainer: "var(--md-sys-color-error-container)",
  colorOnError: "var(--md-sys-color-on-error)",
  colorOnErrorContainer: "var(--md-sys-color-on-error-container)",
  colorBackground: "var(--md-sys-color-background)",
  colorOnBackground: "var(--md-sys-color-on-background)",
  colorSurface: "var(--md-sys-color-surface)",
  colorOnSurface: "var(--md-sys-color-on-surface)",
  colorSurfaceVariant: "var(--md-sys-color-surface-variant)",
  colorOnSurfaceVariant: "var(--md-sys-color-on-surface-variant)",
  colorOutline: "var(--md-sys-color-outline)",
  colorInverseOnSurface: "var(--md-sys-color-inverse-on-surface)",
  colorInverseSurface: "var(--md-sys-color-inverse-surface)",
  colorInversePrimary: "var(--md-sys-color-inverse-primary)",
  colorShadow: "var(--md-sys-color-shadow)",
  colorSurfaceTint: "var(--md-sys-color-surface-tint)",
  colorOutlineVariant: "var(--md-sys-color-outline-variant)",
  colorScrim: "var(--md-sys-color-scrim)",
  colorContrastPurple1: "var(--cc-a11y-contrast-purple1)",
  colorContrastPurple2: "var(--cc-a11y-contrast-purple2)",
  colorContrastPurple3: "var(--cc-a11y-contrast-purple3)",
  colorContrastPurple4: "var(--cc-a11y-contrast-purple4)",
  colorContrastPurple5: "var(--cc-a11y-contrast-purple5)",
  colorContrastPurple6: "var(--cc-a11y-contrast-purple6)",
  colorContrastPurple7: "var(--cc-a11y-contrast-purple7)",
  colorContrastPurple8: "var(--cc-a11y-contrast-purple8)",
  colorContrastPurple9: "var(--cc-a11y-contrast-purple9)",
  colorContrastPurple10: "var(--cc-a11y-contrast-purple10)",
  colorContrastGreen1: "var(--cc-a11y-contrast-green1)",
  colorContrastGreen2: "var(--cc-a11y-contrast-green2)",
  colorContrastGreen3: "var(--cc-a11y-contrast-green3)",
  colorContrastGreen4: "var(--cc-a11y-contrast-green4)",
  colorContrastGreen5: "var(--cc-a11y-contrast-green5)",
  colorContrastGreen6: "var(--cc-a11y-contrast-green6)",
  colorContrastGreen7: "var(--cc-a11y-contrast-green7)",
  colorContrastGreen8: "var(--cc-a11y-contrast-green8)",
  colorContrastGreen9: "var(--cc-a11y-contrast-green9)",
  colorContrastGreen10: "var(--cc-a11y-contrast-green10)",
  colorContrastRed1: "var(--cc-a11y-contrast-red1)",
  colorContrastRed2: "var(--cc-a11y-contrast-red2)",
  colorContrastRed3: "var(--cc-a11y-contrast-red3)",
  colorContrastRed4: "var(--cc-a11y-contrast-red4)",
  colorContrastRed5: "var(--cc-a11y-contrast-red5)",
  colorContrastRed6: "var(--cc-a11y-contrast-red6)",
  colorContrastRed7: "var(--cc-a11y-contrast-red7)",
  colorContrastRed8: "var(--cc-a11y-contrast-red8)",
  colorContrastRed9: "var(--cc-a11y-contrast-red9)",
  colorContrastRed10: "var(--cc-a11y-contrast-red10)",
  colorContrastBlue1: "var(--cc-a11y-contrast-blue1)",
  colorContrastBlue2: "var(--cc-a11y-contrast-blue2)",
  colorContrastBlue3: "var(--cc-a11y-contrast-blue3)",
  colorContrastBlue4: "var(--cc-a11y-contrast-blue4)",
  colorContrastBlue5: "var(--cc-a11y-contrast-blue5)",
  colorContrastBlue6: "var(--cc-a11y-contrast-blue6)",
  colorContrastBlue7: "var(--cc-a11y-contrast-blue7)",
  colorContrastBlue8: "var(--cc-a11y-contrast-blue8)",
  colorContrastBlue9: "var(--cc-a11y-contrast-blue9)",
  colorContrastBlue10: "var(--cc-a11y-contrast-blue10)",
};
