import { PencilIcon, ShareIcon } from "@primer/octicons-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import RecipeCard from "../components/Recipecard";
import { subscription } from "../redux/userSlice";
const Button = styled.button`
  display: flex;
  gap: 20px;
  gap: 5px;
  cursor: pointer;
  border-radius: 8px;
  height: max-content;
  padding: 4px 8px;
  color: ${({ theme }) => theme.colorOnSurface};
  background-color: ${({ theme }) => theme.colorBackground};
  border: none;
`;
const Tab = styled.button`
  display: flex;
  gap: 5px;
  cursor: pointer;
  height: max-content;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  background-color: ${({ theme }) => theme.colorBackground};
  border: none;
  &:hover {
    color: ${({ theme }) => theme.colorOnBackground};
    border-bottom: 2px solid ${({ theme }) => theme.colorOnBackground};
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${({ theme }) => theme.colorOnBackground};
  padding: 0px 24px 24px 24px;
`;
const ChannelHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-bottom: 44px;
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;
const Cover = styled.div`
  //make img
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(16.1290322581vw - 1px);
  background-color: ${({ theme }) => theme.colorPrimary};
  border-radius: 12px;
`;
const Avatar = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPrimary};
`;
const Title = styled.h3`
  font-size: 32px;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Nickname = styled.h4`
  font-size: 16px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
`;
const Desc = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-top: 4px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
`;
const Subs = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 300;
  margin-top: 4px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 4px;
  gap: 12px;
`;
const Subscribe = styled.button`
  background-color: red;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  height: max-content;
  padding: 4px 8px;
  cursor: pointer;
`;
const Share = styled.button`
  font-weight: 500;
  border: none;
  border-radius: 8px;
  height: max-content;
  padding: 4px 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};
  &:hover {
    color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const Tabs = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`;
const Profile = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const userID = useLocation().pathname.split("/")[2];
  // Get User Data
  const [user, setUser] = useState([]);
  useEffect(() => {
    const fetchUser = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/find/${userID}`
      );
      setUser(res.data);
    };
    fetchUser();
  }, [userID]);
  // Get Users Videos
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    const fetchVideos = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/user/${userID}`
      );
      setVideos(res.data);
    };
    fetchVideos();
  }, [userID]);
  const handleSub = async () => {
    console.log(
      currentUser.subscribedUsers.includes("64e5c59805dc90fa7426eb61")
    );
    currentUser.subscribedUsers.includes(user._id)
      ? await axios.delete(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/sub/${user._id}`,
          {
            withCredentials: true,
          }
        )
      : await axios.post(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/sub/${user._id}`,
          { withCredentials: true }
        );
    dispatch(subscription(user._id));
  };
  return (
    <Container>
      <ChannelHeader>
        <Cover
          //style background to be currentUser.contrastColor
          style={{ backgroundColor: currentUser.contrastColor }}
        />
        <Head>
          <Avatar src={user.img} />
          <HeaderContent>
            <Title>{user.name}</Title>
            <Nickname>
              <i>@</i>
              {user.nickname} • {user.origin}
            </Nickname>
            <Desc>{user.desc}</Desc>
            <Subs>
              <b>{user.subscribers}</b> Subscribers •{" "}
              <b>{user.subscribedUsers?.length}</b> Subscribed
            </Subs>
            <Buttons>
              <Share>
                <ShareIcon />
                Share
              </Share>
              {currentUser ? (
                currentUser?._id === user._id ? (
                  <Link
                    to={`/profile/edit/${user?._id || "Loading..."}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button>
                      <PencilIcon />
                      Edit
                    </Button>
                  </Link>
                ) : (
                  <Subscribe
                    onClick={handleSub}
                    style={
                      currentUser.subscribedUsers !== null && {
                        backgroundColor: currentUser.subscribedUsers?.includes(
                          user._id
                        )
                          ? "black"
                          : "red",
                      }
                    }
                  >
                    {currentUser.subscribedUsers?.includes(user._id)
                      ? "Subscribed"
                      : "Subscribe"}
                  </Subscribe>
                )
              ) : (
                <Link
                  to={`/signin`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Subscribe>Subscribe</Subscribe>
                </Link>
              )}
            </Buttons>
          </HeaderContent>
        </Head>
      </ChannelHeader>
      <Tabs>
        <Tab>Recipes</Tab>
        <Tab>Collections</Tab>
        <Tab>Links</Tab>
      </Tabs>
      <Wrapper>
        {videos.map((video) => (
          <RecipeCard key={video._id} video={video} />
        ))}
      </Wrapper>
    </Container>
  );
};
export default Profile;
