import axios from "axios";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import IngredientList from "../components/IngredientList";
import InstructionList from "../components/InstructionList";
import app from "../utils/firebase";
import cuisines from "../utils/lists/cuisines.ts";
import difficulties from "../utils/lists/difficulties.ts";
import methods from "../utils/lists/methods.ts";
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  padding: 0px 20px 40px 20px;
`;
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 24px;
  position: relative;
  overflow: overlay;
`;
const Head = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
`;
const Foot = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 20px 0px 20px 0px;
  border-top: 1px solid ${({ theme }) => theme.colorSurface};
`;
const LeftColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  gap: 20px;
`;
const RightColumn = styled.div`
  flex-grow: 4;
  display: flex;
  flex-flow: column;
  gap: 20px;
`;
const InputRowWrap = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  align-content: flex-start;
`;
const Title = styled.h3`
  flex-grow: 1;
  margin: 0;
`;
const Label = styled.label`
  margin: 0;
`;
const Input = styled.input`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const Select = styled.select`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const Textarea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  resize: none;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const CTA = styled.button`
  padding: 5px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  color: ${({ theme }) => theme.colorPrimary};
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colorPrimary};
    color: ${({ theme }) => theme.colorOnBackground};
  }
`;
const RecipeUpload = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [thumbnail, setThumbnail] = useState(undefined);
  const navigate = useNavigate();
  const [thumbnailUploadProgress, setThumbnailUploadProgress] = useState(0);
  const [video, setVideo] = useState(undefined);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [inputs, setInputs] = useState({});
  const [desc, setDesc] = useState({});
  const [tags, setTags] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const handleInputChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleDesc = (e) => {
    setDesc(e.target.value);
  };
  const handleTags = (e) => {
    setTags(e.target.value.split(","));
  };
  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = () => {
        window.URL.revokeObjectURL(videoElement.src);
        resolve(videoElement.duration);
      };
      videoElement.onerror = () => {
        reject("Error loading video file");
      };
      videoElement.src = URL.createObjectURL(file);
    });
  };
  const uploadFile = useCallback((file, urlType) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        urlType === "imgURL"
          ? setThumbnailUploadProgress(progress)
          : setVideoUploadProgress(progress);
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("error: " + error);
      },
      () => {
        // Handle successful uploads on complete
        getVideoDuration(file)
          .then((duration) => {
            console.log(`Duration: ${duration} seconds`);
            setInputs((prev) => {
              return { ...prev, videoDuration: duration };
            });
          })
          .catch((error) => console.error(error));
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setInputs((prev) => {
            return { ...prev, [urlType]: downloadURL };
          });
        });
      }
    );
  }, []);
  useEffect(() => {
    video && uploadFile(video, "videoURL");
    video && console.log(video);
  }, [video, uploadFile]);
  useEffect(() => {
    thumbnail && uploadFile(thumbnail, "imgURL");
  }, [thumbnail, uploadFile]);
  const handleUpload = async (e) => {
    e.preventDefault();
    const recipeRes = await axios.post(
      `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos`,
      {
        ...inputs,
        desc,
        tags,
        origin: currentUser.origin,
      },
      { withCredentials: true }
    );
    if (recipeRes.status === 200) {
      const ingredientRequests = ingredients.map((ingredient) =>
        axios.post(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/ingredients`,
          {
            videoId: recipeRes.data._id,
            order: ingredient.id,
            title: ingredient.ingredient,
            quantity: ingredient.quantity, //divide by yield
            unit: ingredient.unit,
          },
          { withCredentials: true }
        )
      );
      const instructionRequests = instructions.map((instruction) =>
        axios.post(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/recipeSteps`,
          {
            videoId: recipeRes.data._id,
            order: instruction.id,
            title: instruction.title,
            desc: instruction.desc,
            time: instruction.time,
            imgURL: instruction.imgURL,
          },
          { withCredentials: true }
        )
      );
      const allRequests = [...ingredientRequests, ...instructionRequests];
      await axios
        .all(allRequests)
        .then((responses) => {
          const requestStatuses = responses.map((resp) => resp.status);
          // Check if all requests were successful (status code 200)
          const allSuccessful = requestStatuses.every(
            (status) => status === 200
          );
          if (allSuccessful) {
            console.log("All requests were successful.");
            navigate(
              `${process.env.REACT_APP_RECIPE_API_BASE_URL}/recipe/${recipeRes.data._id}`
            );
          } else {
            console.error("Some requests failed.");
          }
          responses.forEach((resp, index) => {
            let msg = {
              server: resp.headers.server,
              status: resp.status,
              fields: Object.keys(resp.data).toString(),
            };
            console.info(resp.config.url);
            console.table(msg);
          });
        })
        .catch((err) => console.log(err));
    }
  };
  const getIngredients = (data) => {
    var keys = Object.keys(data);
    var addedIngredients = keys.filter(function (key) {
      return data[key];
    });
    console.log("Ingredients: " + addedIngredients);
    setIngredients(data);
  };
  const getInstructions = (data) => {
    var keys = Object.keys(data);
    var addedInstructions = keys.filter(function (key) {
      return data[key];
    });
    console.log("Instructions: " + addedInstructions);
    setInstructions(data);
  };
  return (
    <Container>
      <Wrapper>
        <Head>
          <Title>Upload a new Recipe</Title>
        </Head>
        <LeftColumn>
          <Label>Video</Label>
          {videoUploadProgress > 0 ? (
            "Uploading: " + videoUploadProgress + "%"
          ) : (
            <Input
              type="file"
              accept="video/*"
              onChange={(e) => setVideo(e.target.files[0])}
            />
          )}
          <Label>Thumbnail</Label>
          {thumbnailUploadProgress > 0 ? (
            "Uploading: " + thumbnailUploadProgress + "%"
          ) : (
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => setThumbnail(e.target.files[0])}
            />
          )}
          <Input
            type="text"
            placeholder="Recipe Title"
            name="title"
            onChange={handleInputChange}
          />
          <Textarea
            placeholder="Description"
            rows="8"
            name="desc"
            onChange={handleDesc}
          />
          <Input
            type="text"
            placeholder="Separate Tags with comma"
            onChange={handleTags}
          />
        </LeftColumn>
        <RightColumn>
          <InputRowWrap>
            <Select name="cuisine" id="cuisine" onChange={handleInputChange}>
              <option value="" disabled selected>
                Cuisine
              </option>
              {cuisines.map((cuisine) => (
                <option key={cuisine.label} value={cuisine.value}>
                  {cuisine.label}
                </option>
              ))}
            </Select>
            <Select name="method" id="method" onChange={handleInputChange}>
              <option value="" disabled selected>
                Cooking Method
              </option>
              {methods.map((method) => (
                <option key={method.label} value={method.value}>
                  {method.label}
                </option>
              ))}
            </Select>
          </InputRowWrap>
          <InputRowWrap>
            <Select
              name="difficulty"
              id="difficulty"
              onChange={handleInputChange}
            >
              <option value="" disabled selected>
                Difficulty
              </option>
              {difficulties.map((difficulty) => (
                <option key={difficulty.label} value={difficulty.value}>
                  {difficulty.label}
                </option>
              ))}
            </Select>
            <Input
              type="number"
              min="0"
              placeholder="Yield"
              name="yielding"
              onChange={handleInputChange}
            />
          </InputRowWrap>
          <InputRowWrap>
            <Input
              type="number"
              min="0"
              placeholder="Preptime (Minutes)"
              name="preptime"
              onChange={handleInputChange}
            />
            <Input
              type="number"
              min="0"
              placeholder="Cooktime (Minutes)"
              name="cooktime"
              onChange={handleInputChange}
            />
            <Input
              type="number"
              min="0"
              placeholder="Resttime (Minutes)"
              name="resttime"
              onChange={handleInputChange}
            />
          </InputRowWrap>
          <InputRowWrap>
            <Input
              type="number"
              min="0"
              placeholder="Calories / 100g"
              name="calories"
              onChange={handleInputChange}
            />
            <Input
              type="number"
              min="0"
              placeholder="Fat / 100g"
              name="fat"
              onChange={handleInputChange}
            />
            <Input
              type="number"
              min="0"
              placeholder="Carbohydrates / 100g"
              name="carbohydrates"
              onChange={handleInputChange}
            />
            <Input
              type="number"
              min="0"
              placeholder="Proteins / 100g"
              name="proteins"
              onChange={handleInputChange}
            />
            <Input
              type="number"
              min="0"
              placeholder="Salt / 100g"
              name="salt"
              onChange={handleInputChange}
            />
          </InputRowWrap>
        </RightColumn>
        <h3>Ingredients</h3>
        <Select name="unitSystem" id="unitSystem" onChange={handleInputChange}>
          <option key="imperial" value="imperial" selected>
            Imperial Units (US)
          </option>
          <option key="metric" value="metric">
            Metric Units
          </option>
        </Select>
        <IngredientList
          getIngredientsList={getIngredients}
          unitSystem={inputs.unitSystem}
        />
        <h3>Instructions</h3>
        <InstructionList getRecipeStepsList={getInstructions} />
        <Foot>
          <CTA onClick={handleUpload}>Upload Recipe</CTA>
        </Foot>
      </Wrapper>
    </Container>
  );
};
export default RecipeUpload;
