import axios from "axios";
import { React, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { styled } from "styled-components";
import useDidMount from "../utils/hooks/useDidMount";

const Container = styled.div`
  width: 80%;
  display: flex;
  justify-content: start;
  alignt-items: center;
  gap: 12px;
  flex: 1;
  padding: 0px 12px;
  overflow: hidden;
  /* each slide snaps into place */
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    scroll-margin: 10px;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
  }
`;
const SocialShare = ({ currentVideo }) => {
  const [channel, setChannel] = useState({});
  const shareUrl =
    process.env.REACT_APP_RECIPE_CLIENT_BASE_URL + useLocation().pathname;
  // GET CHANNEL
  useDidMount(() => {
    try {
      const channelRes = axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/find/${currentVideo.userId}`,
        { withCredentials: true }
      );
      if (channelRes.status === 200) {
        setChannel(channelRes.data);
      }
    } catch (err) {
      console.log("Error fetching channel: ", err);
    }
  }, [currentVideo]);

  return (
    <Container>
      <EmailShareButton
        url={shareUrl}
        subject={
          "You should try this recipe: " +
          (currentVideo.title || "Loading...") +
          ", from " +
          channel.name
        }
        body={currentVideo.desc || "Loading..."}
      >
        <EmailIcon size={32} round={true} />
      </EmailShareButton>{" "}
      <PinterestShareButton
        url={shareUrl}
        description={currentVideo.desc || "Loading..."}
        media={currentVideo.imgURL || "Loading..."}
      >
        <PinterestIcon size={32} round={true} />
      </PinterestShareButton>{" "}
      <FacebookShareButton
        url={shareUrl}
        quote={
          "You should try this recipe: " +
          (currentVideo.title || "Loading...") +
          ", from " +
          channel.name
        }
        hashtag={currentVideo.tags || "Loading..."}
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>{" "}
      <TwitterShareButton
        url={shareUrl}
        title={
          "You should try this recipe: " +
          (currentVideo.title || "Loading...") +
          ", from " +
          channel.name
        }
        via={channel.name + " @ CookCook.com"}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton
        url={shareUrl}
        title={
          "You should try this recipe: " +
          (currentVideo.title || "Loading...") +
          ", from " +
          channel.name
        }
      >
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <TelegramShareButton
        url={shareUrl}
        title={
          "You should try this recipe: " +
          (currentVideo.title || "Loading...") +
          ", from " +
          channel.name
        }
      >
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>{" "}
      <RedditShareButton
        url={shareUrl}
        title={
          "You should try this recipe: " +
          (currentVideo.title || "Loading...") +
          ", from " +
          channel.name
        }
      >
        <RedditIcon size={32} round={true} />
      </RedditShareButton>
      <PocketShareButton
        url={shareUrl}
        title={
          "You should try this recipe: " +
          (currentVideo.title || "Loading...") +
          ", from " +
          channel.name
        }
      >
        <PocketIcon size={32} round={true} />
      </PocketShareButton>{" "}
    </Container>
  );
};
export default SocialShare;
