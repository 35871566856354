import React, { useState } from "react";
import { XIcon } from "@primer/octicons-react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { closeModal } from "../redux/modalSlice";
import { logout } from "../redux/userSlice";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

import { getAuth, deleteUser } from "firebase/auth";
import { reauthenticateUser } from "../utils/firebase.js";

const Container = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
const Wrapper = styled.div`
  //Layout
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  //Appearance
  min-width: 600px;
  max-width: 80vw;
  min-height: 400px;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 12px;
`;
const BackDrop = styled.div`
  //Layout
  align-items: stretch;

  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0;
  margin: 0px;
  padding: 0px;
  z-index: 0;
  position: fixed;
  //Appearance
  background-color: #000000a7;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const ModalHead = styled.div`
  //Layout
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
  cursor: default;
`;

const ModalBody = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 600px;
  overflow: auto;
`;

const CloseButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  order: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;

const ButtonContainer = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  flex-basis: auto;
  flex-direction: row;
  flex-shrink: 0;

  width: 100%;

  min-width 240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  //Flex
  order: 1;
  justify-content: center;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;

const Button = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colorTertiaryContainer};
  padding: 8px 12px;
  color: ${({ theme }) => theme.colorTertiary};
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorTertiary};
  width: fit-content;
  &:hover {
    color: ${({ theme }) => theme.colorOnTertiary};
    background-color: ${({ theme }) => theme.colorTertiary};
  }
  &:disabled {
    color: black;
    background-color: grey;
    border: 1px solid grey;
    cursor: not-allowed;
  }
`;

const DeleteButton = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colorErrorContainer};
  padding: 8px 12px;
  color: ${({ theme }) => theme.colorError};
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorError};
  width: fit-content;
  &:hover {
    color: ${({ theme }) => theme.colorOnError};
    background-color: ${({ theme }) => theme.colorError};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colorOutlineVariant};
    border: 1px solid ${({ theme }) => theme.colorOutline};
    color: ${({ theme }) => theme.colorOutline};
    cursor: not-allowed;
  }
`;

const LabelInputGroup = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
`;

const Label = styled.label`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-left: 9px;
`;
const Input = styled.input`
  height: max-content;
  border: ${({ theme, error }) => (error ? "2px" : "1px")} solid
    ${({ theme, error }) => (error ? theme.colorError : theme.colorOutline)};
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};

  border-radius: 8px;
  max-width: 440px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;

const BodyContainer = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};
  border: 2px solid background-color: ${({ theme }) => theme.colorBackground};
  border-radius: 12px;
  cursor: default;
`;

const BodyWrapper = styled.div`
  //Layout
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 24px;
  padding-bottom: 48px;
  gap: 18px;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
`;

const H3 = styled.h2`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 36px;
  text-align: center;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;

const H4 = styled.h2`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 36px;
  text-align: center;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;

const Copy = styled.p`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  padding: 0px 20px;
  width: 70%;
  //Typography
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.7;
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;

const DeleteUserModal = () => {
  const [password, setPassword] = useState("");
  const currentUser = useSelector((state) => state.user.currentUser);

  const dispatch = useDispatch();

  const auth = getAuth();
  const user = auth.currentUser;

  console.log(user);

  const closeDeleteUserModal = (e) => {
    e.preventDefault();
    dispatch(closeModal());
  };

  //handle Delete
  //reautzhenticate
  //Call Delete User Axios
  //Logout

  const handleDelete = async (e) => {
    e.preventDefault();
    //if auth provider is email, ask for password then reauthenticate with password. If auth provider is google, reauthenticate with google; then contoiinue
    try {
      console.log("Reauthenticated successfully!");
    } catch (error) {
      console.error("Failed to update email: ", error);
    }

    try {
      await reauthenticateUser(
        user.providerData[0].providerId,
        password && password
      ).then(() =>
        deleteUser(user)
          .then(() => {
            axios.delete("/api/users/" + currentUser._id, {
              params: {
                firebaseUserId: user.uid,
              },
            });
          })
          .catch((error) => {
            console.error("Error deleting user account on firebase:", error);
          })
      );
      dispatch(logout());
      dispatch(closeModal());
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  return (
    <Container>
      <BackDrop onClick={closeDeleteUserModal} />
      <Wrapper>
        <ModalHead>
          <CloseButton onClick={closeDeleteUserModal}>
            <XIcon size={20} />
          </CloseButton>
          <H3>Delete your CookCook Account</H3>
        </ModalHead>
        <ModalBody>
          <BodyContainer>
            <BodyWrapper>
              <H4>{"Hey " + currentUser.name + "!"}</H4>
              <Copy>
                {
                  " We're sad to see you go. If you delete your account, you will lose all your data and settings. Are you sure you want to delete your account?"
                }
              </Copy>
              {user && user.providerData[0].providerId === "password" && (
                <LabelInputGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </LabelInputGroup>
              )}
              <ButtonContainer>
                <DeleteButton
                  onClick={handleDelete}
                  disabled={
                    user &&
                    user.providerData[0].providerId === "password" &&
                    password.length < 1
                  }
                >
                  Delete Account forever
                </DeleteButton>
                <Button onClick={closeDeleteUserModal}>Kepp Account</Button>
              </ButtonContainer>
            </BodyWrapper>
          </BodyContainer>
        </ModalBody>
      </Wrapper>
    </Container>
  );
};

export default DeleteUserModal;
