import {
  DeviceCameraVideoIcon,
  GearIcon,
  PersonFillIcon,
  SignOutIcon,
} from "@primer/octicons-react";
import axios from "axios";
import { getAuth, signOut } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import SignInButton from "../components/auth/SignInButton";
import SignUpButton from "../components/auth/SignUpButton";
import { openModal } from "../redux/modalSlice";
import { logout } from "../redux/userSlice";
const Container = styled.div`
  order: 2;
`;
const Wrapper = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  gap: 24px;
  position: relative;
`;
const UploadButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${(props) => props.theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 8px;
  order: 1;
  &:hover {
    color: ${(props) => props.theme.colorOnSurfaceVariant};
    background-color: ${(props) => props.theme.colorSurfaceVariant};
  }
`;
const User = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  gap: 10;
  font-weight: 500;
  color: ${(props) => props.theme.colorOnBackground};
  order: 2;
`;
const Buttons = styled.div`
  display: flex;
  gap: 12px;
  order: 2;
`;
const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colorPrimary};
  cursor: pointer;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-weight: 600;
  padding: 8px;
  border-radius: 8px;
  color: ${(props) => props.theme.colorOnBackground};
  &:hover {
    color: ${(props) => props.theme.colorOnSurfaceVariant};
    background-color: ${(props) => props.theme.colorSurfaceVariant};
  }
`;
const Hr = styled.hr`
  margin: 15px 0;
  border: 0.5px solid ${(props) => props.theme.colorSurface};
`;
const ProfileCard = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
const ProfileNames = styled.div`
  display: flex;
  flex-direction: column;
`;
const Name = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  color: ${(props) => props.theme.colorOnBackground};
`;
const Nickname = styled.h4`
  font-size: 20px;
  font-weight: 300;
  margin: 0px;
  color: ${(props) => props.theme.colorOnSurfaceVariant};
`;
const Dropdown = styled.div`
  width: 300px;
  background-color: grey;
  position: absolute;
  top: 48px;
  right: 0px;
  padding: 12px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colorBackground};
  box-shadow: 0px 4px 32px 0px ${(props) => props.theme.colorSurfaceVariant};
  z-index: 1;
`;
const ProfileMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleOpenSignUpModal = (e) => {
    e.preventDefault();
    dispatch(openModal("SignUp"));
  };
  const handleUploadModal = (e) => {
    e.preventDefault();
    dispatch(openModal("Upload"));
  };
  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      axios
        .post(`${process.env.REACT_APP_RECIPE_API_BASE_URL}/auth/signout`, {
          withCredentials: true,
        })
        .then((res) => {
          dispatch(logout());
          navigate(`/`);
        });
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Der Benutzer wurde erfolgreich ausgeloggt
          console.log("Der Benutzer wurde erfolgreich ausgeloggt");
        })
        .catch((error) => {
          // Ein Fehler ist aufgetreten
          console.error("Fehler beim Ausloggen: ", error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const outsideClick = (e) => {
      if (e.target !== dropdownRef.current) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("click", outsideClick);
    return () => {
      document.removeEventListener("click", outsideClick);
    };
  }, []);
  return (
    <Container>
      <Wrapper>
        {currentUser ? (
          <UploadButton style={{ order: "1" }} onClick={handleUploadModal}>
            <DeviceCameraVideoIcon />
          </UploadButton>
        ) : (
          <UploadButton onClick={handleOpenSignUpModal}>
            <DeviceCameraVideoIcon />
          </UploadButton>
        )}
        {currentUser ? (
          <User>
            <Avatar
              src={currentUser.img}
              onClick={(e) => {
                e.stopPropagation();
                setDropdownOpen((prevState) => !prevState);
              }}
            />
            <Dropdown
              ref={dropdownRef}
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ visibility: dropdownOpen ? "visible" : "hidden" }}
            >
              <ProfileCard>
                <Avatar src={currentUser.img} />
                <ProfileNames>
                  <Name>{currentUser.name}</Name>
                  <Nickname>@{currentUser.nickname}</Nickname>
                </ProfileNames>
              </ProfileCard>
              <Hr></Hr>
              <Link
                to={`/profile/${currentUser._id}`}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => setDropdownOpen(false)}
              >
                <Item>
                  <PersonFillIcon />
                  Visit Profile
                </Item>
              </Link>
              <Link
                to={`/settings`}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => setDropdownOpen(false)}
              >
                <Item>
                  <GearIcon />
                  Settings
                </Item>
              </Link>
              <Hr></Hr>
              <Item onClick={handleLogout}>
                <SignOutIcon />
                Logout
              </Item>
            </Dropdown>
          </User>
        ) : (
          <Buttons>
            <SignInButton />
            <SignUpButton />
          </Buttons>
        )}
      </Wrapper>
    </Container>
  );
};
export default ProfileMenu;
