import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import app from "../utils/firebase";
import axios from "axios";
import IngredientList from "../components/IngredientList";
import InstructionList from "../components/InstructionList";
import RecipeDetailsForm from "../components/RecipeDetailsForm";
import { fetchFailure, fetchSuccess } from "../redux/videoSlice";
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  padding: 0px 20px 40px 20px;
`;
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 24px;
  position: relative;
  overflow: overlay;
`;
const Head = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
`;
const Foot = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 20px 0px 20px 0px;
  border-top: 1px solid ${({ theme }) => theme.colorSurface};
`;
const RecipeDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  gap: 20px;
`;
const Title = styled.h3`
  flex-grow: 1;
  margin: 0;
`;
const Label = styled.label`
  margin: 0;
`;
const Input = styled.input`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const CTA = styled.button`
  padding: 5px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  color: ${({ theme }) => theme.colorPrimary};
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colorPrimary};
    color: ${({ theme }) => theme.colorOnBackground};
  }
`;
const Button = styled.button`
  padding: 5px;
  background-color: ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  &:hover {
    background-color: ${({ theme }) => theme.colorSurface};
  }
`;
const UploadElement = styled.div`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const RecipeEdit = () => {
  const path = useLocation().pathname.split("/")[2];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // This will take you back to the previous page
  };
  //GLOBAL STATES
  const currentRecipe = useSelector((state) => state.video.currentVideo);
  //LOCAL STATES
  const [inputsLoaded, setInputsLoaded] = useState(false);
  const [ingredientsLoaded, setIngredientsLoaded] = useState(false);
  // Recipe Details
  const [thumbnail, setThumbnail] = useState(undefined);
  const [thumbnailUploadProgress, setThumbnailUploadProgress] = useState(0);
  const [video, setVideo] = useState(undefined);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [inputs, setInputs] = useState({});
  const [ingredients, setIngredients] = useState([]);
  const [instructions, setInstructions] = useState([]);
  //Get Ingredients
  const [currentIngredients, setCurrentIngredients] = useState([]);
  useEffect(() => {
    const fetchCurrentIngredients = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/ingredients/${currentRecipe._id}`,
        {
          withCredentials: true,
        }
      );
      setCurrentIngredients(res.data);
      if (res.status === 200) {
        setIngredientsLoaded(true);
      }
    };
    fetchCurrentIngredients();
  }, [currentRecipe._id]);
  //Get Recipe Instructions
  const [currentRecipeSteps, setCurrentRecipeSteps] = useState([]);
  useEffect(() => {
    const fetchcurrentRecipeSteps = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/recipeSteps/${currentRecipe._id}`,
        {
          withCredentials: true,
        }
      );
      setCurrentRecipeSteps(res.data);
      if (res.status === 200) {
        console.log(
          "fetchcurrentRecipeSteps -> setCurrentRecipeSteps -> currentRecipeSteps"
        );
      }
    };
    fetchcurrentRecipeSteps();
  }, [currentRecipe._id]);
  //HANDLE INTERACTIONS
  const handleLoadRecipe = useCallback(
    (recipe) => {
      const transferCurrentRecipeInputs = () => {
        setInputs({
          title: currentRecipe.title,
          desc: currentRecipe.desc,
          imgURL: currentRecipe.imgURL,
          videoURL: currentRecipe.videoURL,
          tags: currentRecipe.tags,
          cooktime: currentRecipe.cooktime,
          resttime: currentRecipe.resttime,
          cuisine: currentRecipe.cuisine,
          method: currentRecipe.method,
          calories: currentRecipe.calories,
          preptime: currentRecipe.preptime,
          fat: currentRecipe.fat,
          carbohydrates: currentRecipe.carbohydrates,
          proteins: currentRecipe.proteins,
          salt: currentRecipe.salt,
          yielding: currentRecipe.yielding,
          difficulty: currentRecipe.difficulty,
        });
        console.log("transferInputs");
        console.log(inputs);
        setInputsLoaded(true);
      };
      //MAndatory Properties can be put into the state directly
      //Optional Properties first have to be checked if they exist, and only then put into the state
      console.log("handleLoadRecipe");
      transferCurrentRecipeInputs();
    },
    [currentRecipe, inputs]
  );
  const handleInputChange = (e) => {
    setInputs((prev) => {
      if (e.target.name === "tags") {
        return { ...prev, [e.target.name]: e.target.value.split(",") };
      } else {
        return { ...prev, [e.target.name]: e.target.value };
      }
    });
  };
  //GET VIDEO INFORMATIONS TO BE EDITED
  //Get Video from URL
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const videoRes = await axios.get(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/find/${path}`,
          {
            withCredentials: true,
          }
        );
        dispatch(
          fetchSuccess(videoRes.data).then(handleLoadRecipe(videoRes.data))
        );
      } catch (err) {
        dispatch(fetchFailure());
      }
    };
    fetchVideo();
  }, [path, dispatch, handleLoadRecipe]);
  const removeVideo = (e) => {
    setVideoUploadProgress(0);
    setInputs((prev) => {
      return { ...prev, videoURL: "" };
    });
  };
  const removeImage = (e) => {
    setThumbnailUploadProgress(0);
  };
  const uploadFile = (file, urlType) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        urlType === "imgURL"
          ? setThumbnailUploadProgress(progress)
          : setVideoUploadProgress(progress);
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setInputs((prev) => {
            return { ...prev, [urlType]: downloadURL };
          });
        });
      }
    );
  };
  useEffect(() => {
    video && uploadFile(video, "videoURL");
  }, [video]);
  useEffect(() => {
    thumbnail && uploadFile(thumbnail, "imgURL");
  }, [thumbnail]);
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const updateRecipeRes = await axios.put(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/` +
          currentRecipe._id,
        {
          ...inputs,
        },
        { withCredentials: true }
      );
      if (updateRecipeRes.status === 200) {
        const deleteAllIngredientsRequest = await axios.delete(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/ingredients/delete/` +
            currentRecipe._id,
          { withCredentials: true }
        );
        if (deleteAllIngredientsRequest.status === 200) {
          ingredients.map((ingredient) =>
            axios.post(
              `${process.env.REACT_APP_RECIPE_API_BASE_URL}/ingredients`,
              {
                videoId: updateRecipeRes.data._id,
                order: ingredient.id,
                title: ingredient.ingredient,
                quantity: ingredient.quantity,
                unit: ingredient.unit,
              },
              { withCredentials: true }
            )
          );
        }
        const deleteAllRecipeSteps = await axios.delete(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/recipeSteps/delete/` +
            currentRecipe._id,
          { withCredentials: true }
        );
        if (deleteAllRecipeSteps.status === 200) {
          instructions.map((instruction) =>
            axios.post(
              `${process.env.REACT_APP_RECIPE_API_BASE_URL}/recipeSteps`,
              {
                videoId: updateRecipeRes.data._id,
                order: instruction.id,
                title: instruction.title,
                desc: instruction.desc,
                time: instruction.time,
                imgURL: instruction.imgURL,
              },
              { withCredentials: true }
            )
          );
        }
      }
    } catch (error) {
      console.error("Error updating recipe:", error);
      // Handle the error and display a user-friendly message
    }
  };
  const getIngredients = (data) => {
    var keys = Object.keys(data);
    var addedIngredients = keys.filter(function (key) {
      return data[key];
    });
    setIngredients(addedIngredients);
  };
  const getInstructions = (data) => {
    console.log("getInstructions");
    console.log(data);
    var keys = Object.keys(data);
    var addedInstructions = keys.filter(function (key) {
      return data[key];
    });
    setInstructions(addedInstructions);
    console.log("getInstructions -> setInstructions -> instructions");
    console.log(instructions);
  };
  return (
    <Container>
      <Wrapper>
        <Head>
          <Title>Update your Recipe</Title>
        </Head>
        {inputsLoaded ? (
          <RecipeDetails>
            {inputs.videoURL ? <p>videoURL: true</p> : <p>videoURL: false</p>}
            <Label>Video</Label>
            {videoUploadProgress === 100 ? (
              <UploadElement>
                Video
                <Button onClick={removeVideo}>Remove Video</Button>
              </UploadElement>
            ) : videoUploadProgress > 0 ? (
              <UploadElement>
                {"Uploading: " + videoUploadProgress + "%"}
              </UploadElement>
            ) : (
              <Input
                type="file"
                accept="video/*"
                onChange={(e) => setVideo(e.target.files[0])}
              />
            )}
            <Label>Thumbnail</Label>
            {thumbnailUploadProgress === 100 ? (
              <UploadElement>
                Image
                <Button onClick={removeImage}>Remove Video</Button>
              </UploadElement>
            ) : thumbnailUploadProgress > 0 ? (
              "Uploading: " + thumbnailUploadProgress + "%"
            ) : (
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => setThumbnail(e.target.files[0])}
              />
            )}
            <RecipeDetailsForm
              inputs={inputs}
              handleInputChange={handleInputChange}
              // Exclude video and thumbnail props here
            />
          </RecipeDetails>
        ) : (
          <p>loading video details</p>
        )}
        <h3>Ingredients</h3>
        {ingredientsLoaded ? (
          <IngredientList
            getIngredientsList={getIngredients}
            setIngredientsList={currentIngredients}
          />
        ) : (
          <p>loading ingredients</p>
        )}
        <h3>Instructions</h3>
        {ingredientsLoaded ? (
          <InstructionList
            getRecipeStepsList={getInstructions}
            setRecipeStepsList={currentRecipeSteps}
          />
        ) : (
          <p>loading instructions</p>
        )}
        <Foot>
          <Button onClick={handleGoBack}>Cancel</Button>
          <CTA onClick={handleUpdate}>Update Recipe</CTA>
        </Foot>
      </Wrapper>
    </Container>
  );
};
export default RecipeEdit;
