import {
  BeakerIcon,
  DashIcon,
  FlameIcon,
  GlobeIcon,
  MortarBoardIcon,
  PersonIcon,
  PlusIcon,
  SparkleFillIcon,
  StopwatchIcon,
} from "@primer/octicons-react";
import axios from "axios";
import classnames from "classnames";
import convert from "convert-units";
import * as lodash from "lodash";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import {
  unitSystemFailure,
  unitSystemStart,
  unitSystemSuccess,
} from "../redux/unitSystemSlice.js";
import { yieldFailure, yieldStart, yieldSuccess } from "../redux/yieldSlice";
import units from "../utils/lists/units.ts";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const RecipeInfo = styled.div`
  display: flex;
  align-items: top;
  gap: 10px;
`;
const SecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Tags = styled.div`
  display: flex;
  align-items: top;
  flex-wrap: wrap;
  gap: 10px;
`;
const Tag = styled.div`
  background-color: ${({ theme }) => theme.colorSurface};
  font-size: 12px;
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colorOnSurface};
  &:hover {
    color: ${({ theme }) => theme.colorOnBackground};
  }
`;
const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${({ theme }) => theme.colorOnSurface};
  background-color: ${({ theme }) => theme.colorSurface};
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  align-items: flex-start;
`;
const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colorOnSurface};
  margin: 0px;
  margin-bottom: 4px;
  width: 100%;
  height: 24px;
`;
const DetailTitle = styled.h5`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin: 0px;
  cursor: default;
`;
const DetailBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px;
  gap: 4px;
`;
const DetailItem = styled.div`
  display: flex;
  gap: 12px;
  font-size: 16px;
  margin: 0px;
  cursor: default;
`;
const DetailItemLeader = styled.p`
  font-size: 16px;
  margin: 0px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  cursor: default;
`;
const DetailHeaderSecondary = styled.div`
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.colorOnBackground};
  font-size: 14px;
  cursor: default;
`;
const IngredientButton = styled.button`
  font-size: 10px;
  font-weight: 600;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  height: max-content;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colorSurfaceLight};
  border: none;
`;
const YieldButtons = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const YieldButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colorOnSurface};
  &:hover {
    color: ${({ theme }) => theme.colorInverseOnSurface};
    background-color: ${({ theme }) => theme.colorInverseSurface};
  }
`;
const ClampButton = styled.button`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  height: max-content;
  border: none;
  background: none;
  padding: 4px 0px 0px 0px;
  color: ${({ theme }) => theme.colorOnSurface};
`;
const RecipeDetails = memo(function RecipeDetails({ currentVideo }) {
  const path = useLocation().pathname.split("/")[2];
  const [ingredients, setIngredients] = useState([]);
  const unitSystem = "metric";
  const dispatch = useDispatch();
  //YIELD LOGIC
  const { currentYield } = useSelector((state) => state.yield);

  const loadRecipeDetails = useCallback(async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/ingredients/${
            currentVideo?._id || path
          }`,
          {
            withCredentials: true,
          }
        )
        .then((ingredientsRes) => {
          if (ingredientsRes.status === 200) {
            setIngredients(ingredientsRes.data);
          }
        });
    } catch (err) {
      console.log("Error fetching ingredients: ", err);
    }
  }, [path, currentVideo]);
  useEffect(() => {
    loadRecipeDetails();
  }, [path, loadRecipeDetails]);

  useEffect(() => {
    if (!currentYield) {
      dispatch(yieldSuccess(currentVideo?.yielding));
    }
  }, [dispatch, currentYield, currentVideo]);

  // if yield state is not set, set it to recipe yielding

  const handleYieldMinus = async (e) => {
    e.preventDefault();
    dispatch(yieldStart());
    if (currentYield > 1) {
      dispatch(yieldSuccess(currentYield - 1));
    } else {
      dispatch(yieldFailure());
    }
  };
  const handleYieldPlus = async (e) => {
    e.preventDefault();
    dispatch(yieldStart());
    if (currentYield < 100) {
      dispatch(yieldSuccess(currentYield + 1));
    } else {
      dispatch(yieldFailure());
    }
  };
  //UNIT LOGIC
  const { currentUnitSystem } = useSelector((state) => state.unitSystem);
  console.log("unitSystem", unitSystem);
  console.log("currentUnitSystem", currentUnitSystem);
  // if unitSystem state is not set, set it to recipe unitSystem
  useEffect(() => {
    if (!currentUnitSystem && unitSystem) {
      dispatch(unitSystemSuccess(unitSystem));
    } else if (!currentUnitSystem && !unitSystem) {
      dispatch(unitSystemSuccess("metric"));
    }
  }, [dispatch, unitSystem, currentUnitSystem]);
  // switch unit system on button click
  const handleUnit = async (e) => {
    e.preventDefault();
    dispatch(unitSystemStart());
    if (currentUnitSystem === "metric") {
      dispatch(unitSystemSuccess("imperial"));
    } else if (currentUnitSystem === "imperial") {
      dispatch(unitSystemSuccess("metric"));
    } else {
      dispatch(unitSystemFailure());
    }
  };
  // Unit Label
  const getUnitLabel = (unitValue) => {
    const unit = units.find((u) => u.value === unitValue);
    return unit ? unit.label : unitValue;
  };
  //Unit Conversion
  const convertUnit = (quantity, unit, targetSystem) => {
    // Define the target units for each system
    const targetUnits = {
      metric: {
        //weight units
        lb: "kg",
        oz: "g",
        gal: "l",
        //volumetric units
        tsp: "ml",
        Tbs: "ml",
        fl_oz: "ml",
        cup: "ml",
        pnt: "ml",
        qt: "ml",
      },
      imperial: {
        //weight units
        g: "oz",
        kg: "lb",
        //volumetric units
        l: "gal",
        ml: "gal",
        dl: "gal",
        cl: "gal",
      },
    };
    // If the unit is not in the target units for the target system, return the original quantity and unit
    if (!targetUnits[targetSystem][unit]) {
      return { quantity, unit };
    }
    // Convert the quantity and unit
    const targetUnit = targetUnits[targetSystem][unit];
    const convertedQuantity = convert(quantity).from(unit).to(targetUnit);
    return { quantity: convertedQuantity, unit: targetUnit };
  };
  // CLAMP LOGIC
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const handleClick = () => setClamped(!clamped);
  //Show Clamp Button only when necessary
  const containerRef = useRef(null);
  useEffect(() => {
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };
    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass =
          containerRef.current.classList.contains("clamp-2");
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) containerRef.current.classList.add("clamp-2");
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove("clamp-2");
      }
    };
    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);
    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);
    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);
  return (
    <Container>
      <DetailsCard>
        <div
          className={classnames("long-text", clamped && "clamp-2")}
          ref={containerRef}
        >
          {currentVideo?.desc || "..."}
        </div>
        {showButton && (
          <ClampButton onClick={handleClick}>
            {clamped ? "Show more" : "Show less"}
          </ClampButton>
        )}
      </DetailsCard>
      <RecipeInfo>
        <DetailsCard style={{ flex: 3 }}>
          <DetailHeader>
            <DetailTitle>
              <SparkleFillIcon />
              Ingredients
            </DetailTitle>
            <DetailHeaderSecondary>
              <IngredientButton onClick={handleUnit}>
                {currentUnitSystem}
              </IngredientButton>
              <YieldButtons>
                <YieldButton onClick={handleYieldMinus}>
                  <DashIcon size="12" />
                </YieldButton>
                {currentYield} portions
                <YieldButton onClick={handleYieldPlus}>
                  <PlusIcon />
                </YieldButton>
              </YieldButtons>
            </DetailHeaderSecondary>
          </DetailHeader>
          <DetailBody>
            {ingredients.map((ingredient) => {
              // Convert the ingredient's quantity and unit if necessary
              const { quantity, unit } = convertUnit(
                ingredient.quantity,
                ingredient.unit,
                currentUnitSystem
              );
              return (
                <DetailItem key={ingredient._id}>
                  <DetailItemLeader>{ingredient.title}</DetailItemLeader>{" "}
                  {(quantity * (currentYield / currentVideo?.yielding)).toFixed(
                    2
                  )}{" "}
                  {getUnitLabel(unit)}
                </DetailItem>
              );
            })}
          </DetailBody>
        </DetailsCard>
        <SecondaryInfo style={{ flex: 2 }}>
          <DetailsCard>
            <DetailHeader>
              <DetailTitle>
                <StopwatchIcon />
                Total Time
              </DetailTitle>
              <DetailHeaderSecondary>
                {currentVideo?.totaltime || "..."} minutes
              </DetailHeaderSecondary>
            </DetailHeader>
            {currentVideo?.preptime !== "..." && (
              <DetailItem>
                <DetailItemLeader>Prep Time</DetailItemLeader>
                {currentVideo.preptime} m
              </DetailItem>
            )}
            {currentVideo?.cooktime !== "..." && (
              <DetailItem>
                <DetailItemLeader>Cook Time</DetailItemLeader>{" "}
                {currentVideo.cooktime} m
              </DetailItem>
            )}
            {currentVideo?.resttime !== "..." && (
              <DetailItem>
                <DetailItemLeader>Rest Time</DetailItemLeader>{" "}
                {currentVideo.resttime} m
              </DetailItem>
            )}
          </DetailsCard>
          <DetailsCard>
            <DetailHeader>
              <DetailTitle>
                <FlameIcon />
                Nutritions
              </DetailTitle>
              <DetailHeaderSecondary>
                {currentVideo?.calories || "..."}kcal / 100g
              </DetailHeaderSecondary>
            </DetailHeader>
            {currentVideo?.fat !== "..." && (
              <DetailItem>
                <DetailItemLeader>Fat</DetailItemLeader>
                {currentVideo.fat} g
              </DetailItem>
            )}
            {currentVideo?.carbohydrates !== "..." && (
              <DetailItem>
                <DetailItemLeader>Carbohydrates</DetailItemLeader>
                {currentVideo.carbohydrates} g
              </DetailItem>
            )}
            {currentVideo?.proteins !== "..." && (
              <DetailItem>
                <DetailItemLeader>Proteins</DetailItemLeader>
                {currentVideo.proteins} g
              </DetailItem>
            )}
            {currentVideo?.salt !== "..." && (
              <DetailItem>
                <DetailItemLeader>Salt</DetailItemLeader>
                {currentVideo.salt} g
              </DetailItem>
            )}
          </DetailsCard>
        </SecondaryInfo>
      </RecipeInfo>
      <Tags>
        <Tag>
          <MortarBoardIcon /> {currentVideo?.difficulty || "..."}
        </Tag>
        <Tag alt="Cuisine">
          <GlobeIcon /> {currentVideo?.cuisine || "..."}
        </Tag>
        <Tag>
          <PersonIcon /> {currentVideo?.origin || "..."}
        </Tag>
        <Tag>
          <BeakerIcon /> {currentVideo?.method || "..."}
        </Tag>
        {/*tags.map((tag) => (
          <Tag key={tag}>#{tag}</Tag>
        ))*/}
      </Tags>
    </Container>
  );
});
export default RecipeDetails;
