export const contrastColors = [
  { value: "var(--cc-a11y-contrast-purple1)", label: "Purple 1" },
  { value: "var(--cc-a11y-contrast-purple2)", label: "Purple 2" },
  { value: "var(--cc-a11y-contrast-purple3)", label: "Purple 3" },
  { value: "var(--cc-a11y-contrast-purple4)", label: "Purple 4" },
  { value: "var(--cc-a11y-contrast-purple5)", label: "Purple 5" },
  { value: "var(--cc-a11y-contrast-purple6)", label: "Purple 6" },
  { value: "var(--cc-a11y-contrast-purple7)", label: "Purple 7" },
  { value: "var(--cc-a11y-contrast-purple8)", label: "Purple 8" },
  { value: "var(--cc-a11y-contrast-purple9)", label: "Purple 9" },
  { value: "var(--cc-a11y-contrast-purple10)", label: "Purple 10" },
  { value: "var(--cc-a11y-contrast-red1)", label: "Red 1" },
  { value: "var(--cc-a11y-contrast-red2)", label: "Red 2" },
  { value: "var(--cc-a11y-contrast-red3)", label: "Red 3" },
  { value: "var(--cc-a11y-contrast-red4)", label: "Red 4" },
  { value: "var(--cc-a11y-contrast-red5)", label: "Red 5" },
  { value: "var(--cc-a11y-contrast-red6)", label: "Red 6" },
  { value: "var(--cc-a11y-contrast-red7)", label: "Red 7" },
  { value: "var(--cc-a11y-contrast-red8)", label: "Red 8" },
  { value: "var(--cc-a11y-contrast-red9)", label: "Red 9" },
  { value: "var(--cc-a11y-contrast-red10)", label: "Red 10" },
  { value: "var(--cc-a11y-contrast-green1)", label: "Green 1" },
  { value: "var(--cc-a11y-contrast-green2)", label: "Green 2" },
  { value: "var(--cc-a11y-contrast-green3)", label: "Green 3" },
  { value: "var(--cc-a11y-contrast-green4)", label: "Green 4" },
  { value: "var(--cc-a11y-contrast-green5)", label: "Green 5" },
  { value: "var(--cc-a11y-contrast-green6)", label: "Green 6" },
  { value: "var(--cc-a11y-contrast-green7)", label: "Green 7" },
  { value: "var(--cc-a11y-contrast-green8)", label: "Green 8" },
  { value: "var(--cc-a11y-contrast-green9)", label: "Green 9" },
  { value: "var(--cc-a11y-contrast-green10)", label: "Green 10" },
  { value: "var(--cc-a11y-contrast-blue1)", label: "Blue 1" },
  { value: "var(--cc-a11y-contrast-blue2)", label: "Blue 2" },
  { value: "var(--cc-a11y-contrast-blue3)", label: "Blue 3" },
  { value: "var(--cc-a11y-contrast-blue4)", label: "Blue 4" },
  { value: "var(--cc-a11y-contrast-blue5)", label: "Blue 5" },
  { value: "var(--cc-a11y-contrast-blue6)", label: "Blue 6" },
  { value: "var(--cc-a11y-contrast-blue7)", label: "Blue 7" },
  { value: "var(--cc-a11y-contrast-blue8)", label: "Blue 8" },
  { value: "var(--cc-a11y-contrast-blue9)", label: "Blue 9" },
  { value: "var(--cc-a11y-contrast-blue10)", label: "Blue 10" },
];

export default contrastColors;
