import { XIcon } from "@primer/octicons-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { closeModal } from "../redux/modalSlice";
///FIREBASE
import axios from "axios";
import { getAuth } from "firebase/auth";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import app from "../utils/firebase";
import cuisines from "../utils/lists/cuisines.ts";
import difficulties from "../utils/lists/difficulties.ts";
import methods from "../utils/lists/methods.ts";
import IngredientList from "./IngredientList";
import InstructionList from "./InstructionList";
const Container = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
const Wrapper = styled.div`
  //Layout
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  //Appearance
  min-width: 600px;
  width: 80%;
  min-height: 400px;
  height: 80%;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 12px;
  filter: drop-shadow(rgba(64, 45, 0, 0.5) 1px 2px 8px);
`;
const BackDrop = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0;
  margin: 0px;
  padding: 0px;
  z-index: 0;
  position: fixed;
  //Appearance
  background-color: ${({ theme }) => theme.colorOnBackground};
  color: ${({ theme }) => theme.colorBackground};
  opacity: 0.5;
`;
const ModalHead = styled.div`
  //Layout
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
  cursor: default;
`;
const ModalBody = styled.div`
  //Layout
  display: flex;
  flex-flow: columns;
  gap: 20px;
  margin: 0px 20px;
  flex-grow: 1;
  overflow-y: hidden;
`;
const ModalFoot = styled.div`
  //Layout
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 20px;
  //Appearance
  border-top: 1px solid ${({ theme }) => theme.colorSurface};
`;
const ProgressStepper = styled.div`
  //Layout
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 0px;
  padding: 16px;
  //Appearance
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
`;
const Step = styled.div`
  //Layout
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  //Appearance
  color: ${({ theme, active }) =>
    active ? theme.colorPrimary : theme.colorOnSurface};
  background-color: ${({ theme, active }) =>
    active ? theme.colorOnPrimary : theme.colorSurface};
  font-weight: 700;
  font-size: 12px;
  border-radius: 16px;
`;
const StepConnector = styled.div`
  //Layout
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  gap: 8px;
  height: 2px;
  //Appearance
  background-color: ${({ theme, active }) =>
    active ? theme.colorOnPrimary : theme.colorSurface};
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  border-radius: 8px;
`;
const LeftColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  gap: 20px;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 40px;
`;
const RightColumn = styled.div`
  flex-grow: 4;
  display: flex;
  flex-flow: column;
  gap: 20px;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 40px;
`;
const InputRowWrap = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  align-content: flex-start;
`;
const CloseButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 8px;
  order: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colorSurface};
  }
`;
const Title = styled.h3`
  flex-grow: 1;
  margin: 0;
`;
const LabelInputGroup = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
`;
const Label = styled.label`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-left: 9px;
`;
const Input = styled.input`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const Select = styled.select`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const Textarea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  resize: none;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const CTA = styled.button`
  padding: 8px 16px;
  background-color: transparent;
  background-color: ${({ theme }) => theme.colorPrimary};
  color: ${({ theme }) => theme.colorOnBackground};
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
`;
const Button = styled.button`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  &:hover {
    background-color: ${({ theme }) => theme.colorSurface};
  }
`;
const UploadModal = () => {
  const { currentUser } = useSelector((state) => state.user);
  const auth = getAuth(app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [desc, setDesc] = useState("");
  const [tags, setTags] = useState([]);
  const [step, setStep] = useState(0);
  const [ingredients, setIngredients] = useState([
    /*{ id: 0, quantity: "1", unit: "sli", ingredient: "Sugar" },
    { id: 1, quantity: "2", unit: "pie", ingredient: "Pepper" },*/
  ]);
  const [instructions, setInstructions] = useState([
    { id: 0, title: "Preheat", instruction: "Preheat Oven to 180C" },
    { id: 1, title: "Mix", instruction: "Mix Sugar and Pepper" },
  ]);
  const [thumbnail, setThumbnail] = useState(undefined);
  const [thumbnailUploadProgress, setThumbnailUploadProgress] = useState(0);
  const [video, setVideo] = useState(undefined);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const handleCloseModal = (e) => {
    //e.preventDefault();
    dispatch(closeModal());
  };
  const handleInputChange = (e) => {
    console.log("Input Change " + [e.target.name] + " : " + e.target.value);
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleDesc = (e) => {
    console.log("Description Change");
    setDesc(e.target.value);
  };
  const handleTags = (e) => {
    console.log("Tags Change");
    setTags(e.target.value.split(","));
  };
  const handleNext = (e) => {
    setStep(step + 1);
  };
  const handleBack = (e) => {
    setStep(step - 1);
  };
  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = () => {
        window.URL.revokeObjectURL(videoElement.src);
        resolve(videoElement.duration);
      };
      videoElement.onerror = () => {
        reject("Error loading video file");
      };
      videoElement.src = URL.createObjectURL(file);
    });
  };
  const uploadFile = useCallback(
    (file, urlType) => {
      if (!auth.currentUser) {
        console.log("No authenticated user available for upload.");
        return; // Stop the function if no user is authenticated
      } else {
        console.log("Authenticated user available for upload.");
        console.log("Firebase Auth: ", auth.currentUser);
      }
      // Refresh the token
      try {
        auth.currentUser.getIdToken(true); // Forces token refresh
        console.log("Token refreshed");
      } catch (error) {
        console.error("Error refreshing token:", error);
        return;
      }
      const storage = getStorage(app);
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);
      if (auth.currentUser) {
        auth.currentUser
          .getIdTokenResult()
          .then((idTokenResult) => {
            console.log(idTokenResult); // See detailed token information
          })
          .catch((error) => {
            console.error("Token retrieval error:", error);
          });
      }
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          urlType === "imgURL"
            ? setThumbnailUploadProgress(progress)
            : setVideoUploadProgress(progress);
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log("error: " + error);
        },
        () => {
          // Handle successful uploads on complete
          getVideoDuration(file)
            .then((duration) => {
              console.log(`Duration: ${duration} seconds`);
              setInputs((prev) => {
                return { ...prev, videoDuration: duration };
              });
            })
            .catch((error) => console.error(error));
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setInputs((prev) => {
              return { ...prev, [urlType]: downloadURL };
            });
          });
        }
      );
    },
    [auth.currentUser]
  );
  useEffect(() => {
    video && uploadFile(video, "videoURL");
    video && console.log(video);
  }, [video, uploadFile]);
  useEffect(() => {
    thumbnail && uploadFile(thumbnail, "imgURL");
  }, [thumbnail, uploadFile]);
  const handleUpload = async (e) => {
    e.preventDefault();
    const recipeRes = await axios.post(
      `${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos`,
      {
        ...inputs,
        desc,
        tags,
        origin: currentUser.origin,
      },
      { withCredentials: true }
    );
    if (recipeRes.status === 200) {
      const ingredientRequests = ingredients.map((ingredient) =>
        axios.post(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/ingredients`,
          {
            videoId: recipeRes.data._id,
            order: ingredient.id,
            title: ingredient.ingredient,
            quantity: ingredient.quantity, //divide by yield
            unit: ingredient.unit,
          },
          { withCredentials: true }
        )
      );
      const instructionRequests = instructions.map((instruction) =>
        axios.post(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/recipeSteps`,
          {
            videoId: recipeRes.data._id,
            order: instruction.id,
            title: instruction.title,
            desc: instruction.instruction,
            time: instruction.time,
            imgURL: instruction.imgURL,
          },
          { withCredentials: true }
        )
      );
      const allRequests = [...ingredientRequests, ...instructionRequests];
      await axios
        .all(allRequests)
        .then((responses) => {
          const requestStatuses = responses.map((resp) => resp.status);
          // Check if all requests were successful (status code 200)
          const allSuccessful = requestStatuses.every(
            (status) => status === 200
          );
          if (allSuccessful) {
            console.log("All requests were successful.");
            handleCloseModal().then(() => {
              navigate(
                `${process.env.REACT_APP_RECIPE_API_BASE_URL}/recipe/${recipeRes.data._id}`
              );
            });
          } else {
            console.error("Some requests failed.");
          }
          responses.forEach((resp, index) => {
            let msg = {
              server: resp.headers.server,
              status: resp.status,
              fields: Object.keys(resp.data).toString(),
            };
            console.info(resp.config.url);
            console.table(msg);
          });
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <Container>
      <BackDrop onClick={handleCloseModal} />
      <Wrapper>
        <ModalHead>
          <Title>Upload a new Recipe</Title>
          <CloseButton onClick={handleCloseModal}>
            <XIcon />
          </CloseButton>
        </ModalHead>
        <ProgressStepper>
          <Step active={step >= 0}>Recipe Details</Step>
          <StepConnector active={step >= 1} />
          <Step active={step >= 1}>Recipe Ingredients</Step>
          <StepConnector active={step >= 2} />
          <Step active={step >= 2}>Recipe Instructions</Step>
        </ProgressStepper>
        {step === 0 ? (
          <ModalBody>
            <LeftColumn>
              <LabelInputGroup>
                <Label>Video</Label>
                {videoUploadProgress > 0 ? (
                  "Uploading: " + videoUploadProgress + "%"
                ) : (
                  <Input
                    type="file"
                    accept="video/*"
                    onChange={(e) => setVideo(e.target.files[0])}
                    id="video"
                    name="video"
                    value={inputs.video}
                  />
                )}
              </LabelInputGroup>
              <LabelInputGroup>
                <Label>Thumbnail</Label>
                {thumbnailUploadProgress > 0 ? (
                  "Uploading: " + thumbnailUploadProgress + "%"
                ) : (
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setThumbnail(e.target.files[0])}
                    name="thumbnail"
                    id="thumbnail"
                    value={inputs.thumbnail}
                  />
                )}
              </LabelInputGroup>
              <LabelInputGroup>
                <Label>Title</Label>
                <Input
                  type="text"
                  placeholder="Recipe Title"
                  name="title"
                  onChange={handleInputChange}
                  id="title"
                  value={inputs.title}
                />
              </LabelInputGroup>
              <LabelInputGroup>
                <Label>Description</Label>
                <Textarea
                  placeholder="Description"
                  rows="8"
                  name="desc"
                  onChange={handleDesc}
                  id="desc"
                  value={desc}
                />
              </LabelInputGroup>
            </LeftColumn>
            <RightColumn>
              <InputRowWrap>
                <LabelInputGroup>
                  <Label>Cuisine</Label>
                  <Select
                    name="cuisine"
                    id="cuisine"
                    onChange={handleInputChange}
                    value={inputs.cuisine}
                  >
                    <option value="" disabled selected>
                      Cuisine
                    </option>
                    {cuisines.map((cuisine) => (
                      <option key={cuisine.label} value={cuisine.value}>
                        {cuisine.label}
                      </option>
                    ))}
                  </Select>
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Method</Label>
                  <Select
                    name="method"
                    id="method"
                    value={inputs.method}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>
                      Cooking Method
                    </option>
                    {methods.map((method) => (
                      <option key={method.label} value={method.value}>
                        {method.label}
                      </option>
                    ))}
                  </Select>
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Difficulty</Label>
                  <Select
                    name="difficulty"
                    id="difficulty"
                    onChange={handleInputChange}
                    value={inputs.difficulty}
                  >
                    <option value="" disabled selected>
                      Difficulty
                    </option>
                    {difficulties.map((difficulty) => (
                      <option key={difficulty.label} value={difficulty.value}>
                        {difficulty.label}
                      </option>
                    ))}
                  </Select>
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Yield</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Yield"
                    name="yielding"
                    onChange={handleInputChange}
                    value={inputs.yielding}
                  />
                </LabelInputGroup>
              </InputRowWrap>
              <InputRowWrap>
                <LabelInputGroup>
                  <Label>Preparation Time</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Preptime (Minutes)"
                    name="preptime"
                    onChange={handleInputChange}
                    id="preptime"
                    value={inputs.preptime}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Cook Time</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Cooktime (Minutes)"
                    name="cooktime"
                    id="cooktime"
                    onChange={handleInputChange}
                    value={inputs.cooktime}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Resting Time</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Resttime (Minutes)"
                    name="resttime"
                    id="resttime"
                    onChange={handleInputChange}
                    value={inputs.resttime}
                  />
                </LabelInputGroup>
              </InputRowWrap>
              <InputRowWrap>
                <LabelInputGroup>
                  <Label>Calories</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Calories / 100g"
                    name="calories"
                    id="calories"
                    onChange={handleInputChange}
                    value={inputs.calories}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Fat</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Fat / 100g"
                    name="fat"
                    id="fat"
                    onChange={handleInputChange}
                    value={inputs.fat}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Carbohydrates</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Carbohydrates / 100g"
                    name="carbohydrates"
                    id="carbohydrates"
                    onChange={handleInputChange}
                    value={inputs.carbohydrates}
                  />
                </LabelInputGroup>
              </InputRowWrap>
              <InputRowWrap>
                <LabelInputGroup>
                  <Label>Proteins</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Proteins / 100g"
                    name="proteins"
                    id="proteins"
                    onChange={handleInputChange}
                    value={inputs.proteins}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Salt</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Salt / 100g"
                    name="salt"
                    id="salt"
                    onChange={handleInputChange}
                    value={inputs.salt}
                  />
                </LabelInputGroup>
                <LabelInputGroup>
                  <Label>Sugar</Label>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Sugar / 100g"
                    name="sugar"
                    id="sugar"
                    onChange={handleInputChange}
                    value={inputs.sugar}
                  />
                </LabelInputGroup>
              </InputRowWrap>
              <InputRowWrap>
                <LabelInputGroup style={{ flexGrow: 1 }}>
                  <Label>Tags</Label>
                  <Input
                    type="text"
                    placeholder="Separate Tags with comma"
                    onChange={handleTags}
                    autoComplete="off"
                    name="tags"
                    id="tags"
                    value={tags.join(",")}
                    style={{ flexGrow: 1 }}
                  />
                </LabelInputGroup>
              </InputRowWrap>
            </RightColumn>
          </ModalBody>
        ) : step === 1 ? (
          <ModalBody>
            <LeftColumn>
              <LabelInputGroup>
                <Label>Unit System</Label>
                <Select
                  name="unitSystem"
                  id="unitSystem"
                  onChange={handleInputChange}
                >
                  <option key="imperial" value="imperial" selected>
                    Imperial Units (US)
                  </option>
                  <option key="metric" value="metric">
                    Metric Units
                  </option>
                </Select>
              </LabelInputGroup>
            </LeftColumn>
            <RightColumn>
              <IngredientList
                setIngredients={setIngredients}
                ingredients={ingredients}
                unitSystem={inputs.unitSystem}
              />
            </RightColumn>
          </ModalBody>
        ) : step === 2 ? (
          <ModalBody>
            <RightColumn>
              <InstructionList
                setInstructions={setInstructions}
                instructions={instructions}
              />
            </RightColumn>
          </ModalBody>
        ) : (
          <></>
        )}
        <ModalFoot>
          {step === 0 ? (
            <Button onClick={handleNext}>Next</Button>
          ) : step === 1 ? (
            <>
              <Button onClick={handleBack}>Back</Button>
              <Button onClick={handleNext}>Next</Button>
            </>
          ) : step === 2 ? (
            <>
              <Button onClick={handleBack}>Back</Button>
              <CTA onClick={handleUpload}>Upload Recipe</CTA>
            </>
          ) : (
            <></>
          )}
        </ModalFoot>
      </Wrapper>
    </Container>
  );
};
export default UploadModal;
