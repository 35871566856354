import axios from "axios";
import { React, memo, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import RecipeCard from "./Recipecard";
const Container = styled.div`
  display: flex;
  flex: 2;
  gap: 12px;
  flex-direction: column;
`;
const RecipeRecommendations = memo(function ({ currentVideo }) {
  const path = useLocation().pathname.split("/")[2];
  const [recommendations, setRecommendations] = useState([]);
  const loadRecipeRecommendations = useCallback(async () => {
    try {
      axios
        .get(`${process.env.REACT_APP_RECIPE_API_BASE_URL}/videos/recomm`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200) {
            setRecommendations(res.data);
          }
        });
    } catch (err) {
      console.log("Error fetching recommendations: ", err);
    }
  }, []);
  useEffect(() => {
    loadRecipeRecommendations();
  }, [path, loadRecipeRecommendations]);
  return (
    <Container>
      {recommendations.map((recommendation) => (
        <RecipeCard type="sm" key={recommendation._id} video={recommendation} />
      ))}
    </Container>
  );
});
export default RecipeRecommendations;
