import { React } from "react";
import { useDispatch } from "react-redux";
import { styled } from "styled-components";

import { PlusCircleIcon } from "@primer/octicons-react";
import { openModal } from "../../redux/modalSlice";

const Container = styled.div`
  width: ${(props) => props.type !== "sm" && "351px"};
  height: fit-content;
  cursor: pointer;
  display: ${(props) => props.type === "sm" && "flex"};
  gap: 8px;
`;

const Thumbnail = styled.button`
  background: none;
  border: none;
  margin: 0px;
  padding: 0px;
  position: relative;
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "204px")};
  cursor: pointer;
`;
const ThumbnailContainer = styled.div`
  position: relative;
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "196px")};
  //Appearance
`;
const ThumbnailImage = styled.div`
  //Layout
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: ${(props) => (props.type === "sm" ? "168px" : "351px")};
  height: ${(props) => (props.type === "sm" ? "94px" : "196px")};
  //Appearance
  background-color: ${({ theme }) => theme.colorSurfaceVariant};
  color: ${({ theme }) => theme.colorOutlineVariant};
  border-radius: 8px;
  border: 4px dashed ${({ theme }) => theme.colorOutlineVariant};
  margin-top: 4px;
  flex: 1;
  z-index: 0;
`;

const CreateCollectionCard = ({ type, action }) => {
  const dispatch = useDispatch();

  const handleCreateCollection = (e) => {
    e.preventDefault();
    dispatch(openModal("CreateCollection"));
  };
  return (
    <Container type={type}>
      <Thumbnail onClick={handleCreateCollection}>
        <ThumbnailContainer type={type}>
          <ThumbnailImage>
            <PlusCircleIcon size={48} />
          </ThumbnailImage>
        </ThumbnailContainer>
      </Thumbnail>
    </Container>
  );
};
export default CreateCollectionCard;
