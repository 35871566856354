import { SearchIcon, XIcon } from "@primer/octicons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import SearchDropdown from "./SearchDropdown";
const Search = styled.div`
  height: 48px;
  left: 0px;
  right: 0px;
  order: 1;
  flex: 1 1;
  max-width: 640px;
  display: flex;
  align-items: flex-start;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  border-radius: 24px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);
  &:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2), 0 4px 12px rgba(0, 0, 0, 0.05);
  }
`;
const Input = styled.input`
  background-color: transparent;
  border-color: transparent;
  border-radius: 8px 0px 0px 8px;
  flex: 1 2;
  align-self: stretch;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colorOnBackground};
  margin-left: 8px;
`;
const SearchButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 8px;
  color: ${({ theme }) => theme.colorOnSurface};
  background-color: ${({ theme }) => theme.colorPrimary};
  &:hover {
    color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorPrimaryVariant};
`;
const ClearButton = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  padding-left: 4px;
  background-color: transparent;
  height: 32px;
  width: 32px;
  border: none;
  color: ${({ theme }) => theme.colorOnSurface};
  background-color: ${({ theme }) => theme.colorSurface};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 4px;
  color: ${({ theme }) => theme.colorOnSurface};
  background-color: ${({ theme }) => theme.colorSurface};
  &:hover {
    color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const Vr = styled.span`
  background-color: ${({ theme }) => theme.colorSurface};
  box-sizing: box;
  flex-basis: 1px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  width: 1px;
`;
const SearchField = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [selectedCuisines, setSelectedCuisines] = useState();

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };
  const handleSearch = () => {
    navigate(
      `/search?q=${
        selectedCuisines.length > 0
          ? query + "&cuisine=" + selectedCuisines.toString()
          : query
      }`
    );
  };
  return (
    <Search>
      <SearchDropdown setItems={setSelectedCuisines} />
      <Vr />
      <Input
        id="search"
        name="search"
        value={query}
        placeholder="Search recipes, cooks, ingredients, etc."
        onChange={handleSearchChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
      ></Input>
      <ClearButton
        style={{ visibility: query ? "visible" : "hidden" }}
        onClick={(e) => setQuery("")}
      >
        <XIcon size={24} />
      </ClearButton>
      <SearchButton onClick={handleSearch}>
        <SearchIcon />
      </SearchButton>
    </Search>
  );
};
export default SearchField;
