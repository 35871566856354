import React, { memo } from "react";
import { styled } from "styled-components";
import RecipeStepImages from "./RecipeStepImages";
const Container = styled.div`
  display: block flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 16px;
  align-items: stretch;
  width: 100%;
  flex: 1;
  color: ${({ theme }) => theme.colorOnSurface};
`;
const Title = styled.h4`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 4px;
  margin-top: 0px;
  padding: 0px 12px;
  flex: 1;
`;
const Desc = styled.p`
  margin-bottom: 8px;
  margin-top: 0px;
  padding: 0px 12px;
  flex: 1;
`;
const TimeStamp = styled.button`
  background-color: ${({ theme }) => theme.colorSecondary};
  color: ${({ theme }) => theme.colorOnSecondary};
  border: none;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
`;
const RecipeStep = memo(function ({ title, desc, images, time }) {
  return (
    <Container>
      <Title>
        <TimeStamp>{time}</TimeStamp>
        {title}
      </Title>
      <Desc>{desc}</Desc>
      <RecipeStepImages images={images} />
    </Container>
  );
});
export default RecipeStep;
