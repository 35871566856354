export const cuisines = [
  { value: "European", label: "European" },
  { value: "French", label: "French" },
  { value: "Spanish", label: "Spanish" },
  { value: "German", label: "German" },
  { value: "Turkish", label: "Turkish" },
  { value: "Asian", label: "Asian" },
  { value: "Mexican", label: "Mexican" },
  { value: "African", label: "African" },
  { value: "Australian", label: "Australian" },
  { value: "Italian", label: "Italian" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Eastern Europe", label: "Eastern Europe" },
  { value: "Russian", label: "Russian" },
  { value: "Indian", label: "Indian" },
  { value: "Carribean", label: "Carribean" },
  { value: "Morrocan", label: "Morrocan" },
  { value: "Egyptian", label: "Egyptian" },
  { value: "Greek", label: "Greek" },
  { value: "British", label: "British" },
  { value: "Scandinavian", label: "Scandinavian" },
  { value: "Middle East", label: "Middle East" },
  { value: "American", label: "American" },
  { value: "Latin American", label: "Latin American" },
  { value: "Ethipoian", label: "Ethipoian" },
];

export default cuisines;
