import { ThreeBarsIcon } from "@primer/octicons-react";
import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import Boop from "../utils/Boop";
import Tomato from "../utils/img/Tomatoto.png";
import ProfileMenu from "./ProfileMenu";
import SearchField from "./SearchField";
import Sparkles from "./sparkle/Sparkles";
const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colorBackground};
  height: 80px;
  padding: 0px 20px 0px 20px;
  align-self: stretch;
  z-index: 1;
`;
const Wrapper = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  gap: 24px;
  height: 100%;
  position: relative;
`;
const MenuLogoWrapper = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  gap: 12px;
  position: relative;
`;
const MenuButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 8px;
  order: 1;
  &:hover {
    color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
  order: 1;
`;
const Logo = styled.div`
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Wordmarke = styled.p`
  margin: 0px;
  font-size: 20px;
  letter-spacing: 1.7px;
  font-family: "Climate Crisis", sans-serif;
  color: ${({ theme }) => theme.colorOnTertiaryContainer};
`;
const Img = styled.img`
  height: 32px;
`;
const Navbar = ({ menuOpen, setMenuOpen }) => {
  console.log("Navbar.js");
  return (
    <>
      <Container>
        <Wrapper>
          <MenuLogoWrapper>
            <MenuButton onClick={() => setMenuOpen(!menuOpen)}>
              <ThreeBarsIcon />
            </MenuButton>
            <Link to="/" style={{ textDecoration: "none", order: 2 }}>
              <Sparkles
                children={
                  <Logo>
                    <Boop
                      children={<Img src={Tomato} />}
                      boopConfig={{ x: 20, y: 20, rotation: 10 }}
                    />
                    <Wordmarke>CookCook</Wordmarke>
                  </Logo>
                }
              />
            </Link>
          </MenuLogoWrapper>
          <SearchField />
          <ProfileMenu />
        </Wrapper>
      </Container>
    </>
  );
};
export default Navbar;
