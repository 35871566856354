import React from "react";
import { RotatingLines } from "react-loader-spinner";

function Loader() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <RotatingLines
        height="60"
        width="60"
        color="#fbc02d"
        strokeColor="#fbc02d"
        strokeWidth="5"
        animationDuration="0.75"
        visible={true}
      />
    </div>
  );
}

export default Loader;
