import React from "react";
import { useDispatch } from "react-redux";
import { styled } from "styled-components";
import { openModal } from "../../redux/modalSlice";
const Button = styled.button`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  height: fit-content;
  justify-content: center;
  min-height: 36px;
  padding: 4px 14px 4px 14px;
  //Flex
  flex-shrink: 0;
  //Typography
  font-size: 16px;
  line-height: 24px;
  font-weight: 570;
  white-space: pre-line;
  word-break: break-word;
  //Apprearance
  color: ${({ theme }) => theme.colorOnPrimary};
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  background-color: ${({ theme }) => theme.colorPrimary};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0.3259259164px 0.7333333492px 0 rgba(0, 0, 0, 0.12),
      0 1.5407407284px 2.8666665554px 0 rgba(0, 0, 0, 0.07),
      0 4px 9px 0 rgba(0, 0, 0, 0.05);
  }
`;
const SignUpButton = () => {
  const dispatch = useDispatch();
  const handleOpenSignUpModal = (e) => {
    e.preventDefault();
    dispatch(openModal("SignUp"));
  };
  return (
    <>
      <Button onClick={handleOpenSignUpModal}>Sign up free</Button>
    </>
  );
};
export default SignUpButton;
