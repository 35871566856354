import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentVideo: null,
  loading: false,
  error: false,
};

export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.loading = true;
    },
    fetchSuccess: (state, action) => {
      console.log("video success");
      console.log(action.payload);
      state.loading = false;
      state.currentVideo = { ...action.payload };
    },
    fetchFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    resetCurrentVideo: (state) => {
      state.currentVideo = null;
      console.log("video reset");
    },
    like: (state, action) => {
      if (!state.currentVideo.likes.includes(action.payload)) {
        state.currentVideo.likes.push(action.payload);
        state.currentVideo.dislikes.splice(
          state.currentVideo.dislikes.findIndex(
            (userId) => userId === action.payload
          ),
          1
        );
      }
    },
    unlike: (state, action) => {
      if (state.currentVideo.likes.includes(action.payload)) {
        state.currentVideo.likes.splice(
          state.currentVideo.likes.findIndex(
            (userId) => userId === action.payload
          ),
          1
        );
      }
    },
    dislike: (state, action) => {
      if (!state.currentVideo.dislikes.includes(action.payload)) {
        state.currentVideo.dislikes.push(action.payload);
        state.currentVideo.likes.splice(
          state.currentVideo.likes.findIndex(
            (userId) => userId === action.payload
          ),
          1
        );
      }
    },
    undislike: (state, action) => {
      if (state.currentVideo.dislikes.includes(action.payload)) {
        state.currentVideo.dislikes.splice(
          state.currentVideo.dislikes.findIndex(
            (userId) => userId === action.payload
          ),
          1
        );
      }
    },
  },
});

export const {
  fetchStart,
  fetchFailure,
  fetchSuccess,
  resetCurrentVideo,
  like,
  dislike,
  unlike,
  undislike,
} = videoSlice.actions;
export default videoSlice.reducer;
