import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUnitSystem: null,
  loading: false,
  error: false,
};

export const unitSystemSlice = createSlice({
  name: "unitSystem",
  initialState,
  reducers: {
    unitSystemStart: (state) => {
      state.loading = true;
    },
    unitSystemSuccess: (state, action) => {
      state.loading = false;
      state.currentUnitSystem = action.payload;
    },
    unitSystemFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { unitSystemStart, unitSystemFailure, unitSystemSuccess } =
  unitSystemSlice.actions;
export default unitSystemSlice.reducer;
