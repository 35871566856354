import axios from "axios";
import { React, useEffect, useState, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { openModal } from "../redux/modalSlice";
import { subscription } from "../redux/userSlice";
import {
  BookmarkIcon,
  BookmarkSlashIcon,
  PencilIcon,
} from "@primer/octicons-react";
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ChannelInfo = styled.span`
  display: flex;
  gap: 20px;
`;
const ChannelAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
const ChannelDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const ChannelName = styled.span``;
const ChannelCounter = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-top: 4px;
`;
const ChannelDescription = styled.p`
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 12px;
`;
const Subscribe = styled.button`
  background-color: red;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  height: max-content;
  padding: 4px 8px;
  cursor: pointer;
`;
const Buttons = styled.div`
  display: flex;
  gap: 20px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Button = styled.button`
  display: flex;
  gap: 20px;
  gap: 5px;
  cursor: pointer;
  border-radius: 8px;
  height: max-content;
  padding: 4px 8px;
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorSurface};
  border: none;
  &:hover {
    color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const RecipeChannelBar = memo(function ({ currentVideo }) {
  const path = useLocation().pathname.split("/")[2];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [channel, setChannel] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  //USEEFFECTS
  const loadRecipeChannel = useCallback(async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/find/${currentVideo?.userId}`,
          { withCredentials: true }
        )
        .then((channelRes) => {
          if (channelRes.status === 200) {
            setChannel(channelRes.data);
          }
        });
    } catch (err) {
      console.log("Error fetching channel: ", err);
    }
  }, [currentVideo]);
  useEffect(() => {
    loadRecipeChannel();
  }, [path, loadRecipeChannel]);
  //HANDLES
  const handleSub = async () => {
    currentUser?.subscribedUsers.includes(channel._id)
      ? await axios.delete(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/sub/${channel._id}`,
          null,
          {
            withCredentials: true,
          }
        )
      : await axios.post(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/sub/${channel._id}`,
          { withCredentials: true }
        );
    dispatch(subscription(channel._id));
  };
  const handleBookmark = async () => {
    handleOpenAddToCollectionModal();
    const videoId = currentVideo._id;
    console.log(document.cookie);
    if (bookmarks.length > 0) {
      await axios.delete(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/bookmarks/delete/${bookmarks[0]._id}`,
        {
          withCredentials: true,
        }
      );
      const fetchBookmarks = async () => {
        if (currentVideo) {
          try {
            const res = await axios.get(
              `${process.env.REACT_APP_RECIPE_API_BASE_URL}/bookmarks/${currentVideo._id}`,
              { withCredentials: true }
            );
            setBookmarks(res.data);
          } catch (err) {}
        }
      };
      fetchBookmarks();
    } else {
      await axios.post(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/bookmarks`,
        { videoId },
        {
          withCredentials: true,
        }
      );
      const fetchBookmarks = async () => {
        if (currentVideo) {
          try {
            const res = await axios.get(
              `${process.env.REACT_APP_RECIPE_API_BASE_URL}/bookmarks/${currentVideo._id}`,
              { withCredentials: true }
            );
            setBookmarks(res.data);
          } catch (err) {}
        }
      };
      fetchBookmarks();
    }
  };
  const handleOpenAddToCollectionModal = () => {
    dispatch(openModal("AddToCollection"));
  };
  const handleSignUp = (e) => {
    navigate("/signin");
  };
  return (
    <Container>
      <ChannelInfo>
        <Link
          to={`/profile/${channel._id}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ChannelAvatar src={channel.img} />
        </Link>
        <ChannelDetails>
          <Link
            to={`/profile/${channel._id}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ChannelName>{channel?.name}</ChannelName>
          </Link>
          <ChannelCounter>{channel?.subscribers} Subscribers</ChannelCounter>
          <ChannelDescription>{channel?.desc}</ChannelDescription>
        </ChannelDetails>
        {currentUser ? (
          currentUser?._id === channel._id ? (
            <Link
              to={`/edit/${currentVideo?._id || "Loading..."}`}
              style={{ textDecoration: "none" }}
            >
              <Button>
                <PencilIcon />
                Edit
              </Button>
            </Link>
          ) : (
            <Subscribe
              onClick={handleSub}
              style={
                currentUser.subscribedUsers !== null && {
                  backgroundColor: currentUser.subscribedUsers?.includes(
                    channel._id
                  )
                    ? "black"
                    : "red",
                }
              }
            >
              {currentUser.subscribedUsers?.includes(channel._id)
                ? "Subscribed"
                : "Subscribe"}
            </Subscribe>
          )
        ) : (
          <Link
            to={`/signin`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Subscribe onClick={handleSignUp}>Subscribe</Subscribe>
          </Link>
        )}
      </ChannelInfo>
      <Buttons>
        {currentUser === null ? (
          <Button onClick={handleSignUp}>
            <BookmarkIcon size={16} />
          </Button>
        ) : (
          <Button onClick={handleBookmark}>
            {bookmarks.length > 0 ? (
              <BookmarkSlashIcon />
            ) : (
              <BookmarkIcon size={16} />
            )}
          </Button>
        )}
      </Buttons>
    </Container>
  );
});
export default RecipeChannelBar;
