import { useCallback, useEffect } from "react";
function useDidMount(callback) {
  // Memoize the callback function
  const memoizedCallback = useCallback(callback, [callback]);
  // Replaces componentDidMount
  useEffect(() => {
    memoizedCallback();
  }, [memoizedCallback]); // Add memoizedCallback to the dependency array
}
export default useDidMount;
