import { PlusIcon, TrashIcon } from "@primer/octicons-react";
import React, { useState } from "react";
import { styled } from "styled-components";
import units from "../utils/lists/units.ts";
//Hooks
import useDidMount from "../utils/hooks/useDidMount";
const Container = styled.ul`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  gap: 20px;
  margin: 0px;
  padding-inline-start: 0px;
`;
const Button = styled.button`
  padding: 5px;
  background-color: ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  &:hover {
    background-color: ${({ theme }) => theme.colorSurface};
  }
`;
const Select = styled.select`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const LabelInputGroup = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
`;
const Label = styled.label`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colorOnSurfaceVariant};
  margin-left: 9px;
`;
const Input = styled.input`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 8px;
  padding: 8px;
  background-color: transparent;
  &:focus {
    border: 2px solid #6094e2;
  }
`;
const IngredientListItem = styled.li`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  align-content: flex-start;
`;
const IngredientList = ({ setIngredients, ingredients, unitSystem }) => {
  //LOCAL STATES
  const [ingredientId, setIngredientId] = useState(0);
  //HOOKS
  useDidMount(() => {
    //if ingredients is empty, execute setIngredients
    if (ingredients !== undefined && ingredients.length > 0) {
      handleLoadIngredientsList(ingredients);
    } else {
      console.log("no ingredients");
      setIngredients([
        { id: ingredientId, quantity: 0, unit: "", ingredient: "" },
      ]);
      setIngredientId(1);
    }
  });
  //HANDLES
  const handleLoadIngredientsList = (ing) => {
    const transferIngredients = ing.map(
      ({ quantity, unit, ingredient }, index) => ({
        id: index,
        quantity,
        unit,
        ingredient,
      })
    );
    setIngredients(transferIngredients);
    setIngredientId(transferIngredients.length);
  };
  const handleAddIngredient = () => {
    setIngredients((prev) => {
      return [
        ...prev,
        { id: ingredientId, quantity: 0, unit: "", ingredient: "" },
      ];
    });
    setIngredientId(ingredientId + 1);
  };
  const handleDeleteIngredient = (index) => {
    const newIngredients = ingredients.filter(
      (ingredient) => ingredient.id !== index
    );
    setIngredients(newIngredients);
  };
  const handleChangeIngredient = (e, id) => {
    const updatedIngredients = ingredients.map((item) => {
      if (item.id === id) {
        const updatedItem = { ...item, [e.target.name]: e.target.value };
        return updatedItem;
      } else {
        return item;
      }
    });
    setIngredients(updatedIngredients);
  };
  return (
    <Container>
      {ingredients.map((ingredient) => (
        <IngredientListItem key={ingredient.id}>
          <LabelInputGroup>
            {ingredient.id === 0 && <Label>Quantity</Label>}
            <Input
              key={`quantity-${ingredient.id}`}
              type="number"
              min="0"
              placeholder="Quantity"
              name={`quantity`}
              id={`quantity-${ingredient.id}`}
              onChange={(e) => handleChangeIngredient(e, ingredient.id)}
              defaultValue={ingredient.quantity}
            />
          </LabelInputGroup>
          <LabelInputGroup>
            {ingredient.id === 0 && <Label>Unit</Label>}
            <Select
              key={`unit-${ingredient.id}`}
              name={`unit`}
              id={`unit-${ingredient.id}`}
              onChange={(e) => handleChangeIngredient(e, ingredient.id)}
              defaultValue={ingredient.unit}
            >
              <option value="" disabled selected>
                Unit
              </option>
              {/*pull units from list  */}
              <optgroup label="Thumb Units">
                {units
                  .filter((unit) => unit.system === "thumb")
                  .map((unit) => (
                    <option key={unit.value} value={unit.value}>
                      {unit.label}
                    </option>
                  ))}
              </optgroup>
              {
                /*pull units from list  */
                unitSystem === "metric" ? (
                  <optgroup label="Metric Units">
                    {units
                      .filter((unit) => unit.system === "metric")
                      .map((unit) => (
                        <option key={unit.value} value={unit.value}>
                          {unit.label}
                        </option>
                      ))}
                  </optgroup>
                ) : (
                  <optgroup label="Imperial Units">
                    {units
                      .filter((unit) => unit.system === "imperial")
                      .map((unit) => (
                        <option key={unit.value} value={unit.value}>
                          {unit.label}
                        </option>
                      ))}
                  </optgroup>
                )
              }
            </Select>
          </LabelInputGroup>
          <LabelInputGroup>
            {ingredient.id === 0 && <Label>Ingredient</Label>}
            <Input
              key={`ingredient-${ingredient.id}`}
              type="text"
              placeholder="Ingredient"
              name={`ingredient`}
              id={`ingredient-${ingredient.id}`}
              onChange={(e) => handleChangeIngredient(e, ingredient.id)}
              defaultValue={ingredient.ingredient}
              value={ingredient.ingredient}
            />
          </LabelInputGroup>
          {ingredient.id > 0 && (
            <Button onClick={() => handleDeleteIngredient(ingredient.id)}>
              <TrashIcon />
            </Button>
          )}
        </IngredientListItem>
      ))}
      <Button onClick={handleAddIngredient}>
        <PlusIcon />
      </Button>
    </Container>
  );
};
export default IngredientList;
