import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, updateContrastColor } from "../redux/userSlice";
import { contrastColors } from "../utils/lists/contrastColors.ts";
import { styled } from "styled-components";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh-56px);
  width: 100%;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: -24px;
`;
const Cover = styled.div`
  //make img
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(16.1290322581vw - 1px);
  background-color: red;
`;
const Avatar = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPrimary};
`;
const FormContainer = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 600px;
  min-width 240px;
  position: relative;
  //Flex
  order: 1;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Form = styled.form`
  //Layout
  align.items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  //Flex
  order: 1;
  gap: 12px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Overline = styled.p`
  margin: 0px;
  padding: 0px;
  flex: 0;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-radius: 8px;
  max-width: 440px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  font-size: 14px;
  font-weight: 400;
`;
const Button = styled.button`
  margin-top: 8px;
  padding: 5px;
  background-color: green;
  padding: 8px 12px;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid green;
  width: fit-content;
  &:hover {
    color: green;
    background-color: lightgreen;
  }
`;
// a styled component for a reusable tile of a color palette picker, which can be used in the ColorPalettePicker component
const Tile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  border: ${(props) => props.isSelected && "2px"}; solid
    ${(props) => props.isSelected && props.theme.colorBackground};
`;
// a styled component for a reusable color palette picker, which can be used in the Settings component
const ColorPalettePicker = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colorPrimary};
`;
const EditProfile = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [desc, setDesc] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser) {
      setName(currentUser.name);
      setUsername(currentUser.nickname);
      setDesc(currentUser.desc);
    }
  }, [currentUser]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "username":
        setUsername(value);
        break;
      case "desc":
        setDesc(value);
        break;
      default:
        break;
    }
  };
  const handleSubmitAccount = (e) => {
    e.preventDefault();
    const updateUser = async () => {
      const res = await axios.put(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/${currentUser._id}`,
        {
          name: name,
          nickname: username,
          desc: desc,
        }
      );
      console.log(res);
      dispatch(loginSuccess(res.data));
    };
    updateUser();
  };
  const handleContrastColor = async (color) => {
    console.log("contrastColor: " + color);
    await axios
      .put(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/users/${currentUser._id}`,
        {
          contrastColor: color,
        },
        {
          withCredentials: true,
        }
      )
      .then(dispatch(updateContrastColor(color)));
  };
  return (
    <Container>
      <Cover style={{ backgroundColor: currentUser.contrastColor }} />
      <Head>
        <Avatar src={currentUser.img} />
      </Head>
      <FormContainer>
        <h2>Account</h2>
        <section>
          <Form>
            <Overline>Name</Overline>
            <Input
              name="name"
              placeholder="Name"
              onChange={handleInputChange}
              defaultValue={name}
            />
            <Overline>Username</Overline>
            <Input
              name="username"
              placeholder="Username"
              onChange={handleInputChange}
              defaultValue={username}
            />
            <Overline>Description</Overline>
            <Input
              name="desc"
              placeholder="Description"
              onChange={handleInputChange}
              defaultValue={desc}
            />
            <Button type="submit" onClick={handleSubmitAccount}>
              Save changes
            </Button>
          </Form>
        </section>
        <h2
          //set color style to currentUser.contrastColor
          style={{ color: currentUser.contrastColor }}
        >
          Color Theme {currentUser.contrastColor}
        </h2>
        <section>
          <ColorPalettePicker>
            {contrastColors.map((contrastColor) => (
              //consolelog contrastColor.value
              <Tile
                key={contrastColor.label}
                color={contrastColor.value}
                value={contrastColor.value}
                isSelected={false}
                onClick={() => {
                  handleContrastColor(contrastColor.value);
                  console.log(contrastColor.value);
                }}
              />
            ))}
          </ColorPalettePicker>
        </section>
      </FormContainer>
    </Container>
  );
};
export default EditProfile;
