export const units = [
  { value: "l", label: "Liter", system: "metric" },
  { value: "ml", label: "Mililiter", system: "metric" },
  { value: "dl", label: "Deciliter", system: "metric" },
  { value: "cl", label: "Centiliter", system: "metric" },
  { value: "g", label: "Gramm", system: "metric" },
  { value: "kg", label: "Kilo Gramm", system: "metric" },

  { value: "pin", label: "Pinch", system: "thumb" },
  { value: "pie", label: "Piece", system: "thumb" },
  { value: "sli", label: "Slice", system: "thumb" },
  { value: "clo", label: "Clove", system: "thumb" },

  { value: "oz", label: "Ounce", system: "imperial" },
  { value: "lb", label: "Pound", system: "imperial" },
  { value: "fl-oz", label: "Fluid Ounce", system: "imperial" },
  { value: "cup", label: "Cup", system: "imperial" },
  { value: "pnt", label: "Pint", system: "imperial" },
  { value: "qt", label: "Quart", system: "imperial" },
  { value: "gal", label: "Gallon", system: "imperial" },
  { value: "tsp", label: "Teaspoon", system: "imperial" },
  { value: "Tbs", label: "Tablespoon", system: "imperial" },
];

export default units;
