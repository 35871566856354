import { XIcon } from "@primer/octicons-react";
import React from "react";
import { styled } from "styled-components";
const Container = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
const Wrapper = styled.div`
  //Layout
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  //Appearance
  min-width: 600px;
  max-width: 80vw;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 12px;
`;
const BackDrop = styled.div`
  //Layout
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0;
  margin: 0px;
  padding: 0px;
  z-index: 0;
  position: fixed;
  //Appearance
  background-color: #000000a7;
  color: ${({ theme }) => theme.colorOnBackground};
`;
const ModalHead = styled.div`
  //Layout
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colorSurface};
  cursor: default;
`;
const ModalBody = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 600px;
  overflow: auto;
`;
const ModalFoot = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.colorSurface};
`;
const CloseButton = styled.button`
  flex: none;
  height: 32px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.colorOnBackground};
  border-radius: 24px;
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  order: 1;
  &:hover {
    background-color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;
const CancelButton = styled.button`
  margin-top: 8px;
  padding: 5px;
  color: ${({ theme }) => theme.colorOnSurface};
  background-color: ${({ theme }) => theme.colorSurface};
  border: 1px solid ${({ theme }) => theme.colorOnSurface};
  padding: 8px 12px;
  color: ${({ theme }) => theme.colorOnSurface};
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0.3259259164px 0.7333333492px 0 rgba(0, 0, 0, 0.12),
      0 1.5407407284px 2.8666665554px 0 rgba(0, 0, 0, 0.07),
      0 4px 9px 0 rgba(0, 0, 0, 0.05);
  }
`;
const Button = styled.button`
  margin-top: 8px;
  padding: 5px;
  color: ${({ theme }) => theme.colorOnError};
  background-color: ${({ theme }) => theme.colorError};
  border: none;
  padding: 8px 12px;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0.3259259164px 0.7333333492px 0 rgba(0, 0, 0, 0.12),
      0 1.5407407284px 2.8666665554px 0 rgba(0, 0, 0, 0.07),
      0 4px 9px 0 rgba(0, 0, 0, 0.05);
  }
`;
const BodyContainer = styled.div`
  //Layout
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};
  border: 2px solid background-color: ${({ theme }) => theme.colorBackground};
  border-radius: 12px;
  cursor: default;
`;
const BodyWrapper = styled.div`
  //Layout
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 24px;
  padding-bottom: 48px;
  gap: 18px;
  //Typography
  font-size: 15px;
  line-height: 20px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
`;
const H3 = styled.h3`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const DeleteCollectionModal = ({
  closeModal,
  headline,
  body,
  action,
  callToAction,
}) => {
  const closeDeltetModal = (e) => {
    e.preventDefault();
    closeModal();
  };
  return (
    <Container>
      <BackDrop onClick={closeDeltetModal} />
      <Wrapper>
        <ModalHead>
          <CloseButton onClick={closeDeltetModal}>
            <XIcon size={20} />
          </CloseButton>
          <H3>{headline}</H3>
        </ModalHead>
        <ModalBody>
          <BodyContainer>
            <BodyWrapper>{body}</BodyWrapper>
          </BodyContainer>
        </ModalBody>
        <ModalFoot>
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <Button onClick={action}>{callToAction}</Button>
        </ModalFoot>
      </Wrapper>
    </Container>
  );
};
export default DeleteCollectionModal;
