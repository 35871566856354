import axios from "axios";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import Comment from "./Comment";

const Container = styled.div``;
const NewComment = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPrimary};
`;
const Input = styled.input`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colorOnSurfaceVariant};
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: transparent;
  outline: none;
  padding: 5px;
  width: 100%;
`;
const Button = styled.button`
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  font-weight: 500px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colorSurface};
  color: ${({ theme }) => theme.colorOnBackground};
  &:hover {
    color: ${({ theme }) => theme.colorOnSurfaceVariant};
    background-color: ${({ theme }) => theme.colorSurfaceVariant};
  }
`;

const Comments = memo(function ({ currentVideo }) {
  const path = useLocation().pathname.split("/")[2];

  const currentUser = useSelector((state) => state.user.currentUser);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const loadComments = useCallback(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/comments/video/${path}`
      );
      setComments(res.data);
    } catch (err) {}
  }, [path]);
  useEffect(() => {
    loadComments();
  }, [path, loadComments]);

  const handleComment = async (e) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_RECIPE_API_BASE_URL}/comments`,
        {
          desc: newComment,
          videoId: currentVideo._id,
        },
        { withCredentials: true }
      );
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_RECIPE_API_BASE_URL}/comments/video/${path}`
        );
        setComments(res.data);
      } catch (err) {}
      setNewComment("");
    } catch (err) {
      if (!err.response) {
        console.log("Error: Network Error");
      } else {
        console.log(err.response.data.message);
      }
    }
  };

  return (
    <Container>
      <NewComment>
        <Avatar src={currentUser?.img} />
        <Input
          placeholder="Write a comment..."
          onChange={(e) => setNewComment(e.target.value)}
          value={newComment}
        />
        {currentUser ? (
          <Button onClick={handleComment}>Comment</Button>
        ) : (
          <Link
            to={`/signin`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button>Comment</Button>
          </Link>
        )}
      </NewComment>
      {comments.map((comment) => (
        <Comment key={comment._id} comment={comment} />
      ))}
    </Container>
  );
});
export default Comments;
