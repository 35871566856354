import React from "react";
import { styled } from "styled-components";
import SignInButton from "../components/auth/SignInButton";
import SignUpButton from "../components/auth/SignUpButton";
import RecipeIngredientsImage from "../utils/img/feature-ingredients.png";
import RecipeInstructionsImage from "../utils/img/feature-instructions-sidebar.png";
import HeroImage from "../utils/img/rolling-cook.gif";
import VideoRecipeImage from "../utils/img/video-recipe.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  padding-left: 125px;
  padding-right: 125px;
  width: 100%;
`;
const Hero = styled.section`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  margin: 95px 0px 0px 0px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const HeroText = styled.header`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 600px;
  padding-bottom: 30px;
  width: 400px;
  //Flex
  order: 1;
  gap: 12px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const HeroPicture = styled.section`
  //Layout
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  flex-basis: 56%;
  flex-shrink: 1;
  max-width: 600px;
  padding-bottom: 30px;
  width: 560px
  z-index: 10;
  //Flex
  order: 2;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const VideoWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  width: 526px;
  height: 296px;
  border-radius: 12px;
  box-shadow: 0 0.3259259164px 0.7333333492px 0 rgba(0, 0, 0, 0.12),
    0 1.5407407284px 2.8666665554px 0 rgba(0, 0, 0, 0.07),
    0 4px 9px 0 rgba(0, 0, 0, 0.05);
`;
const IFRAME = styled.iframe`
  border-radius: 12px;
  width: 100%;
  height: inherit;
`;
const Convert = styled.section`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  margin: 95px 0px 0px 0px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const ConvertText = styled.header`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 800px;
  padding-bottom: 130px;
  width: 540px;
  //Flex
  order: 1;
  gap: 12px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const ConvertPicture = styled.picture`
  //Layout
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  flex-basis: 56%;
  flex-shrink: 1;
  max-width: 600px;
  padding-bottom: 130px;
  width: 560px
  z-index: 10;
  //Flex
  order: 2;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Img = styled.img``;
const H1 = styled.h1`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 61px;
  font-weight: 700;
  letter-spacing: -1.9px;
  line-height: 65px;
  text-align: left;
  tex-size-adjust: 100%;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const H2 = styled.h2`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 49px;
  text-align: center;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const H3 = styled.h3`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const C2 = styled.h2`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const C3 = styled.h2`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Overline = styled.p`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 24px;
  text-align: center;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorPrimary};
  cursor: default;
`;
const SubMenuItem = styled.button`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  height: auto;
  width: fit-content;
  justify-content: center;
  min-height: 36px;
  padding: 4px 14px 4px 14px;
  //Flex
  //Typography
  font-size: 16px;
  line-height: 24px;
  font-weight: 570;
  white-space: pre-line;
  word-break: break-word;
  //Apprearance
  color: ${({ theme }) => theme.colorOnBackground};
  border: none;
  background: none;
  border-radius: 12px;
  cursor: not-allowed;
  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
`;
const Badge = styled.sup`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  height: fit-content;
  width: fit-content;
  justify-content: center;
  min-height: 9px;
  padding: 2px 4px 2px 4px;
  margin-left: 4px;
  //Flex
  //Typography
  font-size: 7px;
  line-height: 8px;
  font-weight: 570;
  white-space: pre-line;
  word-break: break-word;
  //Apprearance
  color: ${({ theme }) => theme.colorOnPrimary};
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  background-color: ${({ theme }) => theme.colorPrimary};
  border-radius: 12px;
`;
const Buttons = styled.div`
  display: flex;
  align-items: stretch;
  gap: 12px;
  margin-top: 8px;
`;
const SubNav = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  height: fit-content;
  width: 100%;
  justify-content: flex-end;
  min-height: 24px;
  padding: 4px 14px 4px 14px;
  //Flex
  //Typography
  font-size: 12px;
  line-height: 16px;
  font-weight: 570;
  white-space: pre-line;
  word-break: break-word;
  //Apprearance
  cursor: default;
`;
const ComingSoon = styled.div`
  //Layout
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  height: fit-content;
  width: fit-content;
  justify-content: center;
  min-height: 24px;
  padding: 4px 14px 4px 14px;
  //Flex
  //Typography
  font-size: 12px;
  line-height: 16px;
  font-weight: 570;
  white-space: pre-line;
  word-break: break-word;
  //Apprearance
  color: ${({ theme }) => theme.colorOnPrimary};
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  background-color: ${({ theme }) => theme.colorPrimary};
  border-radius: 12px;
  cursor: default;
`;
const Features = styled.section`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin: 95px 0px 0px 0px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const Feature = styled.div`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const FeatureHat = styled.div`
  //Layout
  box-sizing: border-box;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  //Typography
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const FeatureText = styled.div`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const FeatureBlocks = styled.div`
  //Layout
  box-sizing: border-box;
  display: grid;
  margin-bottom: 24px;
  width: 100%;
  //Grid
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-rows: min-content;
  column-gap: 24px;
  //Typography
  font-size: 16px;
  line-height: 24px;
  cursor: default;
`;
const FeatureBlock = styled.div`
  //Layout
  box-sizing: border-box;
  display: block;
  margin-bottom: 24px;
  position: relative;
  //Grid
  grid-column-end: auto;
  grid-column-start: span 6;
  //Typography
  font-size: 16px;
  line-height: 24px;
  cursor: default;
`;
const FeatureBlockContent = styled.div`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 32px 0px 32px;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  //Appearance
  border-radius: 12px;
  color: ${({ theme }) => theme.colorOnBackground};
  background-color: ${({ theme }) => theme.colorBackground};
  border: 2px solid ${({ theme }) => theme.colorOutlineVariant};
  cursor: default;
`;
const FeatureBlockHeader = styled.header`
  //Layout
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 512px;
  margin-bottom: 30px;
  //Appearance
  cursor: default;
`;
const FeatureBlockImg = styled.img``;
const FeatureIcon = styled.p`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 36px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnBackground};
  cursor: default;
`;
const About = () => {
  const { currentUser } = useSelector((state) => state.user);
  return (
    <Container>
      <SubNav>
        <SubMenuItem>
          For Creators<Badge>SOON</Badge>
        </SubMenuItem>
      </SubNav>
      <Hero>
        <HeroText>
          <H1>The global Video Recipe Social Network</H1>
          <C2>
            Think of a YouTube for recipes. <br />
            Video. Ingredients. Instructions.
          </C2>
          {!currentUser && (
            <Buttons>
              <SignUpButton>Sing Up for Free</SignUpButton>
              <SignInButton>Sign In</SignInButton>
            </Buttons>
          )}
        </HeroText>
        <HeroPicture>
          <VideoWrapper>
            <IFRAME
              id="recipe-video"
              src="https://firebasestorage.googleapis.com/v0/b/cookcook-c8846.appspot.com/o/1695043764134How%20To%20Make%20Authentic%20Spaghetti%20Aglio%20e%20Olio.mp4?alt=media&token=2874baf4-7dc7-484d-874e-fbc7372dca60"
              title="CookCook video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></IFRAME>
          </VideoWrapper>
        </HeroPicture>
      </Hero>
      <Features>
        <Feature>
          <FeatureText>
            <Overline>Video Recipes</Overline>
            <H2>
              Engaging Video Recipes.
              <br /> Plus clear ingredients & instructions.
            </H2>
          </FeatureText>
          <FeatureBlocks>
            <FeatureBlock style={{ gridColumnStart: "span 12" }}>
              <FeatureBlockContent>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>📹</FeatureIcon>
                  </FeatureHat>
                  <H3>Video First</H3>
                  <C3>
                    We believe being able to experience your favorite foody
                    creator in video recipes are more fun and engaging. We don't
                    want you to have to read the whole history of the potate
                    when you are just looking for a gratin recipe.
                  </C3>
                </FeatureBlockHeader>
                <FeatureBlockImg src={VideoRecipeImage} />
              </FeatureBlockContent>
            </FeatureBlock>
            <FeatureBlock>
              <FeatureBlockContent>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>📋</FeatureIcon>
                  </FeatureHat>
                  <H3>Instructions</H3>
                  <C3>
                    Follow the instruction with the video. No more scrolling up
                    and down to follow.
                  </C3>
                </FeatureBlockHeader>
                <FeatureBlockImg
                  src={RecipeInstructionsImage}
                  style={{ marginLeft: -32 }}
                />
              </FeatureBlockContent>
            </FeatureBlock>
            <FeatureBlock>
              <FeatureBlockContent>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>🧺</FeatureIcon>
                  </FeatureHat>
                  <H3>Ingredients</H3>
                  <C3>
                    All the ingredients from the video recipe directly
                    available. Need to feed more people? No problem you can
                    adust the yield. Want to cook in metric? No problem, you can
                    change the units.
                  </C3>
                </FeatureBlockHeader>
                <FeatureBlockImg
                  src={RecipeIngredientsImage}
                  style={{ marginRight: -32 }}
                />
              </FeatureBlockContent>
            </FeatureBlock>
          </FeatureBlocks>
        </Feature>
        <Feature>
          <FeatureText>
            <Overline>Global Recipe Search</Overline>
            <H2>
              Search for local recipes. <br /> Globally. Anywhere in the world.
            </H2>
          </FeatureText>
          <FeatureBlocks>
            <FeatureBlock style={{ gridColumnStart: "span 6" }}>
              <FeatureBlockContent style={{ paddingBottom: "24" }}>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>👨‍🍳</FeatureIcon>
                  </FeatureHat>
                  <H3>Cuisine</H3>
                  <C3>Any favorite cuisine? We have it.</C3>
                </FeatureBlockHeader>
              </FeatureBlockContent>
            </FeatureBlock>
            <FeatureBlock style={{ gridColumnStart: "span 6" }}>
              <FeatureBlockContent style={{ paddingBottom: "24" }}>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>🌏</FeatureIcon>
                    <ComingSoon>Coming Soon</ComingSoon>
                  </FeatureHat>
                  <H3>Origin</H3>
                  <C3>
                    Cooking is all about sharing and exploring cultures. Imagine
                    getting to learn how to make Sri Racha from someone from
                    Thailand. Or Ceviche directly from Peru.
                  </C3>
                </FeatureBlockHeader>
              </FeatureBlockContent>
            </FeatureBlock>
            <FeatureBlock style={{ gridColumnStart: "span 6" }}>
              <FeatureBlockContent style={{ paddingBottom: "24" }}>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>💪</FeatureIcon>
                    <ComingSoon>Coming Soon</ComingSoon>
                  </FeatureHat>
                  <H3>Difficulty</H3>
                  <C3>
                    Find recipes for any level. Looking for something quick and
                    easy? Or do you wanna level up your skill level?
                  </C3>
                </FeatureBlockHeader>
              </FeatureBlockContent>
            </FeatureBlock>
            <FeatureBlock style={{ gridColumnStart: "span 6" }}>
              <FeatureBlockContent style={{ paddingBottom: "24" }}>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>🔍</FeatureIcon>
                  </FeatureHat>
                  <H3>Much more</H3>
                  <C3>
                    Diet, time, ingredients, and, and and. There are many ways
                    to look for recipes.
                  </C3>
                </FeatureBlockHeader>
              </FeatureBlockContent>
            </FeatureBlock>
          </FeatureBlocks>
        </Feature>
        <Feature>
          <FeatureText>
            <Overline>Recipe Collections</Overline>
            <H2>
              Search for local recipes. <br /> Globally. Anywhere in the world.
            </H2>
          </FeatureText>
          <FeatureBlocks>
            <FeatureBlock style={{ gridColumnStart: "span 4" }}>
              <FeatureBlockContent>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>👌</FeatureIcon>
                    <ComingSoon>Coming Soon</ComingSoon>
                  </FeatureHat>
                  <H3>Collect</H3>
                  <C3>
                    Save recipes that you like. Collect your favorites into
                    collections that also work as playlists.
                  </C3>
                </FeatureBlockHeader>
              </FeatureBlockContent>
            </FeatureBlock>
            <FeatureBlock style={{ gridColumnStart: "span 4" }}>
              <FeatureBlockContent>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>🤲</FeatureIcon>
                    <ComingSoon>Coming Soon</ComingSoon>
                  </FeatureHat>
                  <H3>Share</H3>
                  <C3>
                    Sharing is caring. Your collections can be privat or public.
                    And you can send them to your friends and family.
                  </C3>
                </FeatureBlockHeader>
              </FeatureBlockContent>
            </FeatureBlock>
            <FeatureBlock style={{ gridColumnStart: "span 4" }}>
              <FeatureBlockContent>
                <FeatureBlockHeader>
                  <FeatureHat>
                    <FeatureIcon>🤝</FeatureIcon>
                    <ComingSoon>Coming Soon</ComingSoon>
                  </FeatureHat>
                  <H3>Collaborate</H3>
                  <C3>
                    Plan a dinner party with some friends? Collect ideas
                    together. The more the merrier.
                  </C3>
                </FeatureBlockHeader>
              </FeatureBlockContent>
            </FeatureBlock>
          </FeatureBlocks>
        </Feature>
      </Features>
      <Convert>
        <ConvertText>
          <H2 style={{ textAlign: "left" }}>
            The global Video Recipe Social Network
          </H2>
          <C2>
            Think of a YouTube for recipes. <br />
            Video. Ingredients. Instructions.
          </C2>
          {!currentUser && (
            <Link to="/signin" style={{ textDecoration: "none" }}>
              <SignUpButton>Sing Up for Free</SignUpButton>
            </Link>
          )}
        </ConvertText>
        <ConvertPicture>
          <Img src={HeroImage} />
        </ConvertPicture>
      </Convert>
    </Container>
  );
};
export default About;
