import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colorPrimary};
  height: 40px;
  padding: 0px 20px 0px 20px;
  align-self: stretch;
  z-index: 1;
`;
const Wrapper = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  height: 100%;
  position: relative;
`;
const Button = styled.button`
  padding: 5px;
  background-color: ${({ theme }) => theme.colorOnPrimary};
  padding: 4px 8px;
  color: ${({ theme }) => theme.colorPrimary};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorOnPrimary};
  &:hover {
    background-color: black;
  }
`;
const OutlineButton = styled.button`
  padding: 5px;
  background-color: ${({ theme }) => theme.colorPrimary};
  padding: 4px 8px;
  color: ${({ theme }) => theme.colorOnPrimary};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorOnPrimary};
  &:hover {
    background-color: black;
    color: ${({ theme }) => theme.colorPrimary};
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.colorSurface};
`;
const Copy = styled.p`
  //Layout
  box-sizing: border-box;
  display: block;
  margin: 0px;
  //Typography
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.13px;
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
  word-break: break-word;
  //Appearance
  color: ${({ theme }) => theme.colorOnPrimary};
  cursor: default;
`;
const VerifyEmailHat = () => {
  const [showVerifyEmailHat, setShowVerifyEmailHat] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState(null); // State to hold the current Firebase authenticated user

  const auth = getAuth();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is authenticated", user);
        setFirebaseUser(user); // Set the authenticated user
      } else {
        console.log("No user is authenticated");
        setFirebaseUser(null); // Clear the user state
      }
      //if user email is verified, set showVerifyEmailHat to false, other wise set it to true
      if (user && !user.emailVerified) {
        setShowVerifyEmailHat(true);
      } else {
        setShowVerifyEmailHat(false);
      }
    });
    // Cleanup the subscription on component unmount
    return () => unsubscribe();
  }, [auth]);
  const handleResend = () => {
    sendEmailVerification(auth.currentUser).then(() => {
      // Email verification sent!
      console.log("Email verification sent!");
      // ...
    });
  };
  const handleCheckInbox = () => {
    if (auth.currentUser.email.contains("@gmail.com", "@google.com")) {
      window.open("https://mail.google.com/mail/u/0/#inbox");
    } else if (
      auth.currentUser.email.contains("@outlook.com", "@hotmail.com")
    ) {
      window.open("https://outlook.live.com/mail/0/inbox");
    } else if (auth.currentUser.email.contains("@yahoo.com")) {
      window.open("https://mail.yahoo.com/d/folders/1");
    } else if (auth.currentUser.email.contains("@aol.com")) {
      window.open("https://mail.aol.com/webmail-std/en-us/suite");
    } else if (auth.currentUser.email.contains("@icloud.com")) {
      window.open("https://www.icloud.com/mail");
    }
  };
  return (
    showVerifyEmailHat && (
      <Container>
        {firebaseUser && <></>}

        <Wrapper>
          <Copy>
            You have not verified your email yet. We sent you an activation
            link. Please to be sure also check your Spam folder.
          </Copy>
          <Buttons>
            <Button onClick={handleCheckInbox}>Check your Inbox</Button>
            <OutlineButton onClick={handleResend}>Resend</OutlineButton>
          </Buttons>
        </Wrapper>
      </Container>
    )
  );
};
export default VerifyEmailHat;
